/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

class GslbServiceMemberListController {
    constructor(
        Auth,
        GSLBPoolMemberCollection,
    ) {
        this.Auth = Auth;
        this.GSLBPoolMemberCollection = GSLBPoolMemberCollection;
    }

    $onInit() {
        this.collection_ =
            new this.GSLBPoolMemberCollection({ gslbServiceId: this.gslbService_.getIdFromData() });

        this.collReload_ = this.collection_.load.bind(this.collection_);
        this.gslbService_.bind('itemSaveSuccess', this.collReload_);
        this.setGridConfig_();
    }

    /**
     * Creates gridConfig.
     * @protected
     */
    setGridConfig_() {
        const fields = [{
            name: 'data.config.gslbPoolName',
            title: 'GSLB Pool Name',
        }, {
            name: 'data.config.gslbPoolPriority',
            title: 'GSLB Pool Priority',
        }, {
            require: 'config',
            name: 'name',
            title: 'Member Name',
            template: '{{row.getName()}}',
        }, {
            name: 'data.config.ip.addr',
            title: 'IP address',
        }, {
            name: 'publicIp',
            template: '{{row.getConfig().public_ip || \'N/A\'}}',
            title: 'Public IP address',
        }];

        //add oper_status column for each GslbSite
        const siteStatusColumns = [];

        if (this.Auth.isAllowed('gslb')) {
            this.gslbService_.getGSLB().getConfig()['sites']
                .forEach(({ dns_vses: dnsVSes, name, cluster_uuid: clusterId }) => {
                    if (dnsVSes && dnsVSes.length) {
                        const location =
                            'row.data.runtime.gslbSiteOperStatuses[field.clusterId].location';

                        siteStatusColumns.push({
                            name: `site_status_${clusterId}`,
                            clusterId,
                            title: `DNS Site "${name}" Status`,
                            template:
                                `<avi-healthscore
                                item="row"
                                type="gslbsite"
                                title="Location: {{ ::${location} | geoLocation }}"
                                get-runtime-params="field.clusterId"/>`,
                        });
                    }
                });
        }

        //last column
        const overallStatusColumn = {
            name: 'status',
            title: 'Overall Member Status',
            template: '<avi-healthscore item="row" type="gslbsite"></avi-healthscore>',
        };

        siteStatusColumns.push(overallStatusColumn);
        Array.prototype.push.apply(fields, siteStatusColumns);

        this.gslbServiceMembersGridConfig = {
            fields,
            collection: this.collection_,
            id: componentTag,
            multipleactions: [],
            singleactions: [],
            layout: {
                hideSearch: true,
                hideEditColumns: true,
            },
        };
    }

    $onDestroy() {
        if (this.collection_) {
            this.collection_.destroy();
        }

        this.gslbService_.unbind('itemSaveSuccess', this.collReload_);
    }
}

GslbServiceMemberListController.$inject = [
    'Auth',
    'GSLBPoolMemberCollection',
];

const componentTag = 'gslb-service-detail-member';
const templateUrl =
    'src/components/pages/application/gslb/gslb-service-detail/' +
    `${componentTag}/${componentTag}.component.html`;

/**
 * @ngdoc component
 * @name  gslbServiceMemberList
 * @param {Object} gslbService - gslbService object
 * @author Alex Malitsky, Akul Aggarwal
 * @description
 *
 *     Component for GSLB Pool grid.
 */
angular.module('avi/gslb').component('gslbServiceMemberList', {
    bindings: {
        gslbService_: '<resolveGslbService',
    },
    controller: GslbServiceMemberListController,
    templateUrl,
});
