/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    isEmpty,
    isUndefined,
    max,
} from 'underscore';
import { copy } from 'angular';
import { IMessageItem, MessageItem } from './message-item.factory';
import { Constructor } from '../../../declarations/globals.d';
import { IMessageBaseArgs } from './message-base.factory';

/**
 * Returns undefined if the array is empty, otherwise returns the array.
 */
const removeEmptyRepeated = (configs: object[] = []): object[] | undefined => {
    return configs.length ? configs : undefined;
};

interface IRepeatedMessageItemArgs<T> {
    /**
     * Constructor function of the MessageItem this RepeatedMessageItem is associated with.
     */
    MessageItemConstructor: Constructor<T>;

    /**
     * Arguments to call the MessageItemConstructor with.
     */
    messageItemArgs: IMessageBaseArgs;

    /**
     * Config data to be set on data.config. Array of objects to be applied to child MessageItems.
     */
    config?: MessageItem[] | object[];
}

/**
 * @description
 *     This is a unique type of MessageItem that simply acts as a wrapper around repeated
 *     MessageItem children. This is just responsible for managing the array of MessageItem
 *     children, such as adding or removing.
 *
 *     The RepeatedMessageItem instance gets instantiated in every MessageItem config even if its
 *     data doesn't exist. This is so that when adding child MessageItems we don't have to check for
 *     existence of an array first.
 *
 *     Before the save request is made, if the config array is empty, the returned value is
 *     undefined so that the parent config field will not be set to an empty array in the save
 *     request payload.
 * @author alextsg
 */
export class RepeatedMessageItem<T extends MessageItem> implements IMessageItem {
    /**
     * Data object consisting of the defaultConfig and the config.
     */
    public data: {
        config: T[];
    };

    /**
     * Constructor function of the MessageItem this RepeatedMessageItem is associated with.
     */
    private readonly MessageItemConstructor: Constructor<T>;

    /**
     * Arguments to call the MessageItemConstructor with.
     */
    private readonly messageItemArgs: IMessageBaseArgs;

    constructor(args: IRepeatedMessageItemArgs<T>) {
        const {
            MessageItemConstructor,
            messageItemArgs,
            config,
        } = args;

        this.MessageItemConstructor = MessageItemConstructor;
        this.messageItemArgs = messageItemArgs;

        this.data = {
            config: [],
        };

        this.updateConfig(config, messageItemArgs.isClone);
    }

    /**
     * Getter function for the config data.
     */
    public get config(): this['data']['config'] {
        return this.data.config;
    }

    /**
     * Returns the config object.
     */
    public getConfig(): this['data']['config'] {
        return this.config;
    }

    /**
     * Creates a new ConfigItem with the same config data. Used when making a save request.
     */
    public clone(): RepeatedMessageItem<T> {
        const config = copy(this.flattenConfig(true));

        // eslint-disable-next-line no-extra-parens
        return new (this.constructor as Constructor<RepeatedMessageItem<T>>)({
            MessageItemConstructor: this.MessageItemConstructor,
            messageItemArgs: {
                // TODO: deep copy arguments (AV-98015)
                ...this.messageItemArgs,
                isClone: true,
            },
            config,
        });
    }

    /**
     * Updates the RepeatedMessageItem's MessageItems with new config.
     */
    public updateConfig(newConfigs: object[] = [], skipDataTransformation = false): void {
        this.data.config = newConfigs.map(config => {
            const newConfigItem = new this.MessageItemConstructor({
                // TODO: deep copy arguments (AV-98015)
                ...this.messageItemArgs,
                config,
                isClone: skipDataTransformation,
            });

            // If getIndex doesn't return anything then we just use the newly created MessageItem.
            const newIndex = newConfigItem.getIndex();
            const hasIndex = !isUndefined(newIndex);

            if (hasIndex) {
                const existingConfigItem = this.config.find(configItem => {
                    return configItem.getIndex() === newIndex;
                });

                if (existingConfigItem) {
                    existingConfigItem.updateConfig(config, skipDataTransformation);

                    return existingConfigItem;
                }
            }

            return newConfigItem;
        });
    }

    /**
     * Calls and returns the result of flattenConfig on every child MessageItem. Set bypassCheck to
     * true to avoid the canFlatten check.
     */
    public flattenConfig(bypassCheck = false): object[] | undefined {
        return removeEmptyRepeated(this.config.map(configItem => {
            return configItem.flattenConfig(bypassCheck);
        }));
    }

    /**
     * Calls and returns the result of getDataToSave on every child MessageItem.
     */
    public getDataToSave(): object[] {
        return removeEmptyRepeated(this.config.map(configItem => configItem.getDataToSave()));
    }

    /**
     * Adds an entry to the config.
     */
    public add(config?: T | object): void {
        const configItem = config instanceof MessageItem ?
            config :
            new this.MessageItemConstructor({
                // TODO: deep copy arguments (AV-98015)
                ...this.messageItemArgs,
                config,
            });

        this.config.push(configItem);
    }

    /**
     * Removes an entry from the config.
     */
    public remove(index = 0): void {
        this.config.splice(index, 1);
    }

    /**
     * Removes an entry by MessageItem.
     */
    public removeByMessageItem(messageItem: T): void {
        const index = this.config.indexOf(messageItem);

        if (index === -1) {
            throw new Error('MessageItem does not exist');
        }

        this.remove(index);
    }

    /**
     * Clears all entries from the config.
     */
    public removeAll(): void {
        this.config.length = 0;
    }

    /**
     * Returns the MessageItem at the index.
     */
    public at(index = 0): T {
        return this.config[index];
    }

    /**
     * Returns the array index based on the 'index' property.
     */
    public getArrayIndexWithIndexField(index: number): number {
        const arrayIndex = this.config.findIndex(configItems => configItems.getIndex() === index);

        return arrayIndex > -1 ? arrayIndex : NaN;
    }

    /**
     * Returns the number of MessageItems in the config.
     */
    public get count(): number {
        return this.config.length;
    }

    /**
     * Returns true if the config does not contain any MessageItems.
     */
    public isEmpty(): boolean {
        return this.count === 0;
    }

    /**
     * Returns the highest existing 'index' property number, or NaN if none.
     */
    public getMaxIndex(): number {
        const maxIndexConfigItem = max(this.config, configItem => configItem.getIndex()) as T;

        return !isEmpty(maxIndexConfigItem) ? maxIndexConfigItem.getIndex() : NaN;
    }

    /**
     * Calls a method on each of the child MessageItems.
     */
    public recursiveConfigItemCall(methodName: string): void {
        this.config.forEach(configItem => configItem.recursiveConfigItemCall(methodName));
    }

    /**
     * Moves item to a new index. All items in-between need to have their indices shifted.
     * @param item - Item to be moved.
     * @param newIndex - destination Index of item to be moved.
     */
    public moveItem(item: T, newIndex: number): void {
        const currentIndex = this.config.indexOf(item);

        this.moveByIndex(currentIndex, newIndex);
    }

    /**
     * Swaps position of two items.
     * @param firstItem - First item to be swapped.
     * @param secondItem - Item to be swapped with First item.
     */
    public swapItem(firstItem: T, secondItem: T): void {
        const firstIndex = this.config.indexOf(firstItem);
        const secondIndex = this.config.indexOf(secondItem);

        this.swapByIndex(firstIndex, secondIndex);
    }

    /**
     * Moves item to a new index. All items in-between need to have their indices shifted.
     */
    public moveByIndex(oldIndex: number, newIndex: number): void {
        let newIndexCounter = newIndex;

        // newIndex moves towards the direction of oldIndex
        const increment = oldIndex < newIndex ? -1 : 1;

        while (oldIndex !== newIndexCounter) {
            this.swapByIndex(oldIndex, newIndexCounter);
            newIndexCounter += increment;
        }
    }

    /**
     * Given two indices of items, swaps positions in the config along with the index property
     * in the item.
     */
    public swapByIndex(oldIndex: number, newIndex: number): void {
        const { config } = this;

        const oldItem = this.at(oldIndex);
        const newItem = this.at(newIndex);

        config[oldIndex] = newItem;
        config[newIndex] = oldItem;

        /**
         * Actual 'index' property of the item.
         */
        const oldIndexValue = oldItem.getIndex();
        const newIndexValue = newItem.getIndex();

        oldItem.setIndex(newIndexValue);
        newItem.setIndex(oldIndexValue);
    }

    /**
     * Calls the destroy method on each MessageItem in the config.
     */
    public destroy(): void {
        this.recursiveConfigItemCall('destroy');
    }
}
