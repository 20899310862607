/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

function certificateCollectionFactory(Collection, Certificate) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.Certificate Certificates}.
     */
    class CertificateCollection extends Collection {
        constructor(oArgs) {
            super(oArgs);
            this.filters = oArgs && oArgs.filters || angular.copy(this.filters);
        }

        /** @override */
        create(windowElement, params) {
            const certTypeCAEnum = 'SSL_CERTIFICATE_TYPE_CA';
            const newItem = this.createNewItem();

            if (newItem.getConfig().type === certTypeCAEnum) {
                windowElement = 'ssl-certificate-create-root';
            }

            return newItem.edit(windowElement, params);
        }
    }

    const serverDefaultsOverride = {
        certificate: {
            self_signed: true,
        },
    };

    const allDataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    Object.assign(CertificateCollection.prototype, {
        objectName_: 'sslkeyandcertificate',
        itemClass_: Certificate,
        windowElement_: 'ssl-certificate-create-application-system',
        serverDefaultsOverride_: serverDefaultsOverride,
        defaultDataSources_: 'list',
        allDataSources_: allDataSources,
    });

    return CertificateCollection;
}

certificateCollectionFactory.$inject = [
    'Collection',
    'Certificate',
];

angular.module('avi/app')
    .factory('CertificateCollection', certificateCollectionFactory);
