/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-rule';
const prefix = `${moduleName}.${componentName}`;

export const ruleWithCustomModeAndExceptionsConfiguredMessage = `${prefix}.ruleWithCustomModeAndExceptionsConfiguredMessage`;
export const ruleWithExceptionsConfiguredMessage = `${prefix}.ruleWithExceptionsConfiguredMessage`;
export const ruleWithCustomModeConfiguredMessage = `${prefix}.ruleWithCustomModeConfiguredMessage`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const removeRuleButtonLabel = `${prefix}.removeRuleButtonLabel`;
export const showRuleButtonLabel = `${prefix}.showRuleButtonLabel`;
export const ruleInputLabel = `${prefix}.ruleInputLabel`;
export const ruleInputPlaceholder = `${prefix}.ruleInputPlaceholder`;
export const hideRuleButtonLabel = `${prefix}.hideRuleButtonLabel`;

export const ENGLISH = {
    [ruleWithCustomModeAndExceptionsConfiguredMessage]: 'This rule has a custom mode and exceptions configured.',
    [ruleWithExceptionsConfiguredMessage]: 'This rule has exceptions configured.',
    [ruleWithCustomModeConfiguredMessage]: 'This rule has a custom mode configured.',
    [nameInputLabel]: 'Rule Name',
    [emptyDataLabel]: 'N/A',
    [nameInputPlaceholder]: 'Enter Rule Name',
    [removeRuleButtonLabel]: 'Remove Rule',
    [showRuleButtonLabel]: 'Show Rule',
    [ruleInputLabel]: 'Rule',
    [ruleInputPlaceholder]: 'Enter rule',
    [hideRuleButtonLabel]: 'Hide Rule',
};
