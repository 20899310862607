/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name  objectTypeMatch
 * @module match/objectTypeMatch
 * @description Wrapper component for configuring a match config item.
 * @param {ConfigItem} matchFieldConfigItem - A config item instance associated with a match type.
 * @author alextsg
 */
angular.module('avi/policies').component('objectTypeMatch', {
    bindings: {
        matchFieldConfigItem: '<',
        preventEdit: '<',
    },
    templateUrl: 'src/components/forms/match-config/object-type-match/' +
        'object-type-match.component.html',
});
