/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './waf-exclude-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name wafExcludeList
 * @description
 *     Displays and configures entries on a WAF exclude list.
 * @param {ConfigItem} configItem - ConfigItem containing handleAddExcludeListEntry and
 *     handleRemoveExcludeListEntry methods.
 * @param {boolean} editMode - True when exclude list is being edited.
 */
class WafExcludeListController {
    constructor(Regex, l10nService) {
        this.Regex = Regex;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for clicking "Add exclude list entry" button. Adds a new exclude list entry on
     * the item.
     */
    handleAddExcludeListEntry() {
        this.item.addExcludeListEntry();
    }

    /**
     * Handler for clicking the remove entry button. Removes an entry based on the index.
     * @param {index} index
     */
    handleRemoveExcludeListEntry(index) {
        this.item.removeExcludeListEntry(index);
    }
}

WafExcludeListController.$inject = [
    'Regex',
    'l10nService',
];

angular.module('aviApp').component('wafExcludeList', {
    controller: WafExcludeListController,
    bindings: {
        item: '<configItem',
        editMode: '<',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-exclude-list/' +
            'waf-exclude-list.html',
});
