/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    AppLearningParamsConfigItem,
    LatestWafCrsVersionService,
    wafApplicationLearningOptions,
    WafApplicationLearningService,
    WafApplicationSignatureProvider,
    WafApplicationSignatureProviderCollection,
    WafApplicationSignaturesConfigItem,
    wafLearningDataModalOptions,
    WafPolicy,
    WafPolicyAllowlistRuleConfigItem,
    WafPolicyPsmGroup,
    WafPolicyPsmGroupCollection,
    WafPolicyPsmGroupMetric,
    WafPositiveSecurityModelConfigItem,
    WafPSMLocationConfigItem,
    WafPSMLocationMatchConfigItem,
    WafPSMMatchElementConfigItem,
    WafPSMRuleConfigItem,
} from '.';

const wafModule = angular.module('avi/waf');

const components = [
    {
        name: 'wafApplicationLearning',
        options: wafApplicationLearningOptions,
    },
    {
        name: 'wafLearningDataModal',
        options: wafLearningDataModalOptions,
    },
];

const factories = [
    {
        dependency: AppLearningParamsConfigItem,
        name: 'AppLearningParamsConfigItem',
    },
    {
        dependency: WafPolicy,
        name: 'WafPolicy',
    },
    {
        dependency: WafPolicyPsmGroup,
        name: 'WafPolicyPsmGroup',
    },
    {
        dependency: WafPSMLocationConfigItem,
        name: 'WafPSMLocationConfigItem',
    },
    {
        dependency: WafPSMLocationMatchConfigItem,
        name: 'WafPSMLocationMatchConfigItem',
    },
    {
        dependency: WafPSMMatchElementConfigItem,
        name: 'WafPSMMatchElementConfigItem',
    },
    {
        dependency: WafPSMRuleConfigItem,
        name: 'WafPSMRuleConfigItem',
    },
    {
        dependency: WafPolicyPsmGroupCollection,
        name: 'WafPolicyPsmGroupCollection',
    },
    {
        dependency: WafPositiveSecurityModelConfigItem,
        name: 'WafPositiveSecurityModelConfigItem',
    },
    {
        dependency: WafApplicationSignaturesConfigItem,
        name: 'WafApplicationSignaturesConfigItem',
    },
    {
        dependency: WafPolicyPsmGroupMetric,
        name: 'WafPolicyPsmGroupMetric',
    },
    {
        dependency: WafPolicyAllowlistRuleConfigItem,
        name: 'WafPolicyAllowlistRuleConfigItem',
    },
    {
        dependency: WafApplicationSignatureProviderCollection,
        name: 'WafApplicationSignatureProviderCollection',
    },
    {
        dependency: WafApplicationSignatureProvider,
        name: 'WafApplicationSignatureProvider',
    },
];

const services = [
    {
        dependency: WafApplicationLearningService,
        name: 'wafApplicationLearningService',
    },
    {
        dependency: LatestWafCrsVersionService,
        name: 'latestWafCrsVersionService',
    },
];

components.forEach(({ name, options }) => wafModule.component(name, options));
services.forEach(({ name, dependency }) => wafModule.service(name, dependency));

factories.forEach(({ name, dependency }) => {
    initAjsDependency(wafModule, 'factory', name, dependency);
});
