/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './nsm-log.less';
import '../../waf-rules-log.less';
import '../../waf-log-entry.less';
import '../../waf-log-match-element.less';

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.nsmLogComponentBindings
 * @see {@link module:avi/logs.nsmLogComponent}
 */
class NsmLogController {
    constructor(aviAlertService, AviConfirmService, Auth) {
        this.aviAlertService_ = aviAlertService;
        this.AviConfirmService_ = AviConfirmService;
        this.Auth_ = Auth;

        /**
         * Used to show the Add Rule exception button.
         * @type {boolean}
         */
        this.hoverRule = false;
        this.expanderActions = [];
    }

    /**
     * @override
     */
    $onInit() {
        if (this.showAddException()) {
            this.expanderActions = [
                {
                    iconClassName: 'sl-icon-plus',
                    title: 'Add Group Exceptions',
                    onClick: () => this.addGroupException(),
                    hidden: () => !this.showAddException(),
                },
            ];
        }
    }

    /**
     * Handler for the mouse hover on the rule.
     * @param {boolean} [hover=false] - True if the mouse is hovering over the rule. False
     *     otherwise.
     */
    handleRuleHover(hover = false) {
        this.hoverRule = hover;
    }

    /**
     * Determines if the buttons to add exceptions should be shown.
     * @returns {boolean}
     */
    showAddException() {
        return this.Auth_.isAllowed('wafpolicy', 'w') &&
            !_.isUndefined(this.wafPolicy) &&
            !this.wafPolicy.busy &&
            this.getGroupName() !== 'N/A';
    }

    /**
     * Opens the exceptions dialog to add group exceptions.
     */
    addGroupException() {
        this.openExceptionsDialog_(this.rules);
    }

    /**
     * Opens the exceptions dialog to add a rule exception.
     * @param {angular.$event} $event
     * @param {WafRule} rule
     * @param {string} ruleId - WAF Rule ID.
     */
    addRuleException($event, rule, ruleId) {
        $event.stopPropagation();

        this.openExceptionsDialog_([rule], ruleId);
    }

    /**
     * Opens the exceptions dialog to add group or rule exceptions.
     * @param {WafRules[]} wafLogRules
     * @param {string} ruleId - WAF Rule ID.
     * @protected
     */
    openExceptionsDialog_(wafLogRules, ruleId) {
        if (this.wafPolicy) {
            const wafGroup = this.wafPolicy.getGroupByGroupName(this.groupName);

            if (!wafGroup) {
                return this.aviAlertService_.throw(`Group ${this.groupName} no longer exists.`);
            }

            let wafRule;

            if (ruleId) {
                wafRule = this.wafPolicy.getRuleByRuleId(ruleId);

                if (!wafRule) {
                    return this.aviAlertService_.throw(`Rule ${ruleId} no longer exists.`);
                }
            }

            this.AviConfirmService_.prompt('waf-exceptions-modal', {
                wafGroup,
                wafRule,
                wafLogRules,
                uriPath: this.uriPath,
                saveWafPolicy: ['wafGroup', 'wafRule', this.saveWafPolicy],
            });
        }
    }

    /**
     * Replaces the wafGroup and wafRule in the WafPolicy and saves the WafPolicy.
     * @param {wafGroup} wafGroup
     * @param {wafRule} wafRule
     * @returns {ng.$q.promise}
     */
    saveWafPolicy = (wafGroup, wafRule) => {
        if (wafRule) {
            wafGroup.replaceRule(wafRule);
        }

        this.wafPolicy.saveGroup(wafGroup);

        return this.wafPolicy.save();
    }

    /**
     * Some WAF groups don't have group names, and this.groupName will be in the format of
     * '__N/A:<number>' (where the number is an index) so that groups remain unique in the parent
     * hash. For those groups 'N/A' will be shown as the group name.
     * @see {@link module:avi/logs.WafLogsService.ruleGroupReducer_}
     * @returns {string}
     */
    getGroupName() {
        return this.groupName.indexOf('__N/A:') === 0 ? 'N/A' : this.groupName;
    }
}

NsmLogController.$inject = [
    'aviAlertService',
    'AviConfirmService',
    'Auth',
];

/**
 * @name nsmLogsListComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.NsmLogController} controller
 * @property {module:avi/logs.nsmLogComponentBindings} bindings
 * @description Component for an NSM Log.
 * @author alextsg
 */
angular.module('avi/logs').component('nsmLog', {
    /**
     * @mixin nsmLogComponentBindings
     * @memberOf module:avi/logs
     * @property {string} groupName - Name of the WAF Group.
     * @property {WafRule[]} rules - Rules belonging to the WAF Group.
     * @property {WafPolicy} [wafPolicy=] - Optional because the Virtual Service may no
     *      longer have the WafPolicy.
     * @property {string} clientIp - Client IP of the log.
     * @property {string} uriPath - URI Path of the log.
     * @property {boolean} expanded - Boolean to expand/collapse all.
     */
    bindings: {
        groupName: '@',
        rules: '<',
        wafPolicy: '<?',
        clientIp: '@',
        uriPath: '@',
        expanded: '<',
    },
    controller: NsmLogController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'nsm-logs-list/nsm-log/nsm-log.component.html',
});
