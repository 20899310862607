/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const wafExclusionTypeConfigItemFactory = MessageItem => {
    /**
     * @alias module:config-item/WafExclusionTypeConfigItem
     * @private
     */
    class WafExclusionTypeConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafExclusionType',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         */
        canFlatten() {
            return this.isValid();
        }

        /**
         * @override
         */
        isValid() {
            const {
                match_op: matchOp,
                match_case: matchCase,
            } = this.config;

            return Boolean(matchOp || matchCase);
        }
    }

    return WafExclusionTypeConfigItem;
};

wafExclusionTypeConfigItemFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  WafExclusionTypeConfigItem
 * @description  WafExclusionType ConfigItem class.
 * @module config-item/WafExclusionTypeConfigItem
 * @author alextsg
 */
angular
    .module('avi/waf')
    .factory('WafExclusionTypeConfigItem', wafExclusionTypeConfigItemFactory);
