/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  SSLProfile
 * @description
 *     SSLProfile item.
 */
const SSLProfileFactory = (
        Item,
        schemaService,
) => {
    class SSLProfile extends Item {
        /**
         * Map cipher enum data to cipher object list.
         * @return {Object[]}
         */
        static getCipherObjectList() {
            // Set of TLS 1.3 ciphers to be marked as PFS ciphers
            const TLS_1_3_CIPHERS = new Set([
                'TLS_AES_256_GCM_SHA384',
                'TLS_AES_128_GCM_SHA256',
                'TLS_CHACHA20_POLY1305_SHA256',
            ]);

            const cipherObjectList =
                schemaService.getEnumValues('AcceptedCipherEnums').map(val => {
                    return {
                        cipher: val.value,
                        description: val.description,
                        performance:
                        val.ssl_performance.enumeration('SSL_SCORE_'),
                        compatibility:
                        val.ssl_compatibility.enumeration('SSL_SCORE_'),
                        security:
                        val.ssl_security.enumeration('SSL_SCORE_'),
                        securityScore:
                        val.ssl_security_score,
                        enabled: false,
                        pfs: val.value.indexOf('ECDHE') > -1 || TLS_1_3_CIPHERS.has(val.value),
                    };
                });

            return cipherObjectList;
        }

        /**
         * Returns SSL Profile type.
         * @return {string} - enum: SSLProfileType
         */
        getType() {
            return this.getConfig().type;
        }

        /**
         * Returns enabled cipher list based on cipher enum cache.
         * @return {string[]} - enum: AcceptedCipherEnums
         * @protected
         */
        getEnabledCipherList_() {
            const cipherEnums = [],
                { cipher_enums_tmp: tmpCipherEnums } = this.getConfig();

            tmpCipherEnums.forEach(cipher => {
                if (cipher.enabled) {
                    cipherEnums.push(cipher.cipher);
                }
            });

            return cipherEnums;
        }

        /**
         * Resets enabled flag through all ciphers we have in the cipher cache list.
         * @param {boolean} init - True if called when cipher list is being initiated.
         * @protected
         */
        resetCipherList_(init) {
            const
                config = this.getConfig(),
                { cipher_enums: cipherEnums } = config;
            let editableCiphersHash = {};

            config.cipher_enums_tmp = SSLProfile.getCipherObjectList();

            const { cipher_enums: defaultCipherEnums } = this.getDefaultConfig();

            if (init && cipherEnums) {
            //create a dictionary of ciphers we have in editable
                editableCiphersHash = _.invert(cipherEnums);
            } else {
            //create a dictionary of ciphers by default values
                editableCiphersHash = _.invert(defaultCipherEnums);
            }

            config['cipher_enums_tmp'].forEach(cipher => {
                cipher.enabled = cipher.cipher in editableCiphersHash;
            });

            // Sort first based on if it's enabled or not, and if enabled, sort by index found
            // in the Hash. If not enabled, then sort by Security Score.
            config['cipher_enums_tmp'].sort((a, b) => {
                if (a.enabled < b.enabled) {
                    return 1;
                } else if (a.enabled > b.enabled) {
                    return -1;
                } else if (a.enabled && b.enabled) {
                    if (+editableCiphersHash[a.cipher] < +editableCiphersHash[b.cipher]) {
                        return -1;
                    } else {
                        return 1;
                    }
                }

                if (a.securityScore < b.securityScore) {
                    return 1;
                } else if (a.securityScore > b.securityScore) {
                    return -1;
                }

                return 0;
            });
        }

        /**
         * Restores default cipher string.
         * @protected
         */
        resetCipherString_() {
            const config = this.getConfig();

            config['accepted_ciphers'] = this.getDefaultConfig()['accepted_ciphers'];
        }

        /**
         * Make changes on ciphers based on cipher types.
         * @param {string} newType - Name of the new cipher type.
         */
        onCipherTypeChange(newType) {
            const config = this.getConfig();

            if (newType === 'list') {
                delete config.accepted_ciphers;
                delete config.tls13_ciphers;

                this.resetCipherList_();
            } else {
                delete config.cipher_enums;
                delete config.cipher_enums_tmp;

                this.resetCipherString_();
            }
        }

        /**
         * Handler for profile type change.
         * @param {string} type - selected SSL profile type
         */
        onProfileTypeChange(type) {
            const config = this.getConfig();

            config.type = type;
        }

        /**
         * Updates Config when SSL Session reuse checkbox is changed.
         * If checkbox is unchecked,
         * delete the SSL Session Timeout value.
         */
        onSslSessionReuseChange() {
            const config = this.getConfig();

            if (!config.enable_ssl_session_reuse) {
                delete config.ssl_session_timeout;
            }
        }

        /**
         * Updates the accepted Versions list.
         * @param {string[]} versions - List of selected accepted versions.
         */
        updateAcceptedVersions(versions) {
            const config = this.getConfig();

            config.accepted_versions = versions;
        }

        /**
         * Backend translates list of ciphers (enums) into string and provides both but want to
         * support only one type in time - either list or string.
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();
            const { accepted_versions: acceptedVersions } = config;

            if (!_.isUndefined(config.cipher_enums)) { //gonna be list tab
                delete config.accepted_ciphers;
                delete config.tls13_ciphers;
                // init cipher list
                this.resetCipherList_(true);
            }

            config.accepted_versions = _.pluck(acceptedVersions, 'type');

            return config;
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());
            const acceptedVersions = angular.copy(config.accepted_versions);

            if (_.isUndefined(config.accepted_ciphers)) {
                config.cipher_enums = this.getEnabledCipherList_();
            } else {
                delete config.cipher_enums;
            }

            config.accepted_versions = acceptedVersions.reduce((result, version) => {
                result.push({ type: version });

                return result;
            }, []);

            delete config.cipher_enums_tmp;

            return config;
        }
    }

    Object.assign(SSLProfile.prototype, {
        objectName: 'sslprofile',
        windowElement: 'ssl-tls-profile-modal',
    });

    return SSLProfile;
};

SSLProfileFactory.$inject = [
        'Item',
        'schemaService',
];

angular.module('aviApp').factory('SSLProfile', SSLProfileFactory);
