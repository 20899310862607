/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * Module for application profile.
 * @module avi/profiles/application
 */

/**
 * ApplicationProfileType enum values.
 * @typedef {(
 *     'APPLICATION_PROFILE_TYPE_L4'|
 *     'APPLICATION_PROFILE_TYPE_HTTP'|
 *     'APPLICATION_PROFILE_TYPE_SYSLOG'|
 *     'APPLICATION_PROFILE_TYPE_DNS'|
 *     'APPLICATION_PROFILE_TYPE_SSL' |
 *     'APPLICATION_PROFILE_TYPE_SIP'
 * )} AppProfileType
 * @memberOf module:avi/profiles/application
 */

/**
 * Short forms of ApplicationProfileType enum values.
 * @typedef {(
 *     'L4' |
 *     'HTTP' |
 *     'SYSLOG' |
 *     'DNS' |
 *     'SSL' |
 *     'SIP' |
 *     'l4' |
 *     'http' |
 *     'syslog' |
 *     'dns' |
 *     'ssl' |
 *     'sip'
 * )} ShortAppProfileType
 * @memberOf module:avi/profiles/application
 */

import { copy, merge } from 'angular';

import {
    each,
    filter,
    isArray,
    isObject,
} from 'underscore';

const APPLICATION_PROFILE_TYPE_DNS = 'APPLICATION_PROFILE_TYPE_DNS';
const APPLICATION_PROFILE_TYPE_HTTP = 'APPLICATION_PROFILE_TYPE_HTTP';
const APPLICATION_PROFILE_TYPE_L4 = 'APPLICATION_PROFILE_TYPE_L4';
const APPLICATION_PROFILE_TYPE_SIP = 'APPLICATION_PROFILE_TYPE_SIP';
const APPLICATION_PROFILE_TYPE_SSL = 'APPLICATION_PROFILE_TYPE_SSL';
const APPLICATION_PROFILE_TYPE_SYSLOG = 'APPLICATION_PROFILE_TYPE_SYSLOG';

const configNameToType = {
    http_profile: APPLICATION_PROFILE_TYPE_HTTP,
    dns_service_profile: APPLICATION_PROFILE_TYPE_DNS,
    sip_service_profile: APPLICATION_PROFILE_TYPE_SIP,
    tcp_app_profile: [
        APPLICATION_PROFILE_TYPE_L4,
        APPLICATION_PROFILE_TYPE_SSL,
    ],
};

/**
 * Hash of SSL Client certificate modes list.
 * Used to specify whether the client side verification is set to none, request or require
 * @type {Object}
 */
const sslClientCertificateModesHash = {
    SSL_CLIENT_CERTIFICATE_REQUEST: 'SSL_CLIENT_CERTIFICATE_REQUEST',
    SSL_CLIENT_CERTIFICATE_REQUIRE: 'SSL_CLIENT_CERTIFICATE_REQUIRE',
    SSL_CLIENT_CERTIFICATE_NONE: 'SSL_CLIENT_CERTIFICATE_NONE',
};

/**
 * Application profile serverDefaultsOverride config.
 */
const serverDefaultsOverride = {
    http_profile: {
        compression_profile: {
            filter: [],
        },
        cache_config: {
            uri_non_cacheable: undefined,
        },
    },
    dos_rl_profile: {
        rl_profile: {
            client_ip_connections_rate_limit: undefined,
            uri_failed_requests_rate_limit: undefined,
            client_ip_scanners_requests_rate_limit: undefined,
            client_ip_to_uri_failed_requests_rate_limit: undefined,
            client_ip_to_uri_requests_rate_limit: undefined,
            uri_requests_rate_limit: undefined,
            uri_scanners_requests_rate_limit: undefined,
            client_ip_requests_rate_limit: undefined,
            client_ip_failed_requests_rate_limit: undefined,
            custom_requests_rate_limit: undefined,
        },
    },
};

function applicationProfileFactory(
    Item,
    defaultValues,
    HTTPRedirectAction,
    NetworkProfile,
    systemInfoService,
) {
    /**
     * @constructor
     * @memberOf module:avi/profiles/application
     * @extends module:avi/dataModel.Item
     * @desc Application profile {@link module:avi/dataModel.Item Item} class implementation.
     * @author Alex Malitsky
     */
    class ApplicationProfile extends Item {
        /** @override */
        beforeEdit() {
            const defaultConfig = this.getDefaultConfig_();
            const config = this.getConfig();

            const { isEnterpriseLicense, isEssentialsLicense } = systemInfoService;

            if (!this.id && isEnterpriseLicense) {
                merge(config, serverDefaultsOverride);
            }

            // httpProfile is not allowed for essentials license tier only.
            // So for the other tiers, if its undefined set defaultValues.
            if (!isEssentialsLicense) {
                if (!config.http_profile) {
                    config.http_profile = defaultConfig.http_profile;
                }
            }

            // following fields are only allowed for enterprise license.
            // To make the modal work for edit case, we need the default values.
            if (isEnterpriseLicense) {
                const { http_profile: httpProfile } = config;

                if (httpProfile) {
                    const {
                        http_profile: {
                            cache_config: defaultCacheConfig,
                            compression_profile: defaultCompressionProfile,
                            ssl_client_certificate_action: defaultSslCertificateActions,
                        },
                    } = defaultConfig;

                    if (!httpProfile.cache_config) {
                        httpProfile.cache_config = defaultCacheConfig;

                        httpProfile.cache_config.enabled = false;
                    }

                    if (!httpProfile.compression_profile) {
                        httpProfile.compression_profile = defaultCompressionProfile;

                        httpProfile.compression_profile.compression = false;
                    }

                    if (!httpProfile.ssl_client_certificate_action) {
                        httpProfile.ssl_client_certificate_action = defaultSslCertificateActions;
                    }

                    httpProfile.cache_config.selectiveCaching =
                        !!httpProfile.cache_config.uri_non_cacheable;

                    httpProfile.ssl_client_certificate_action.headers = [{}];
                }

                //Untokenize the host and path fields for the redirect action
                if (config.dos_rl_profile && config.dos_rl_profile.rl_profile) {
                    each(config.dos_rl_profile.rl_profile, rateProf => {
                        if (isArray(rateProf)) {
                            rateProf.forEach(
                                rateProf => ApplicationProfile.beforeEditRateProfile_(rateProf),
                            );
                        } else {
                            ApplicationProfile.beforeEditRateProfile_(rateProf);
                        }
                    });
                } else {
                    //if rl_profile is not set, add empty rl_profile object.
                    config.dos_rl_profile = {
                        rl_profile: {},
                    };
                }

                if (!config.tcp_app_profile) {
                    config.tcp_app_profile = copy(defaultConfig.tcp_app_profile);
                }

                const type = this.getType();

                if (type === APPLICATION_PROFILE_TYPE_DNS) {
                    const { dns_service_profile: profileConfig } = config;
                    const { dns_zones: dnsZones } = profileConfig;

                    if (!dnsZones) {
                        profileConfig.dns_zones = [];
                    }
                }
            }
        }

        /**
         * Processes RateProfile before editing.
         * @param {Object} rateProf - RateProfile object.
         * @protected
         */
        static beforeEditRateProfile_(rateProf) {
            if (isObject(rateProf)) {
                if (rateProf.http_cookie) {
                    rateProf._http_rl_field = 'http_cookie';
                    rateProf._http_rl_value = rateProf.http_cookie;
                } else if (rateProf.http_header) {
                    rateProf._http_rl_field = 'http_header';
                    rateProf._http_rl_value = rateProf.http_header;
                }

                const { action } = rateProf;

                if (action && action.type === 'RL_ACTION_REDIRECT' && action.redirect) {
                    HTTPRedirectAction.beforeEdit(rateProf.action.redirect);
                }
            }
        }

        /**
         * Get HTTP Profile SSL everywhere enable value
         * @param {Object} httpProfile - http Profile object.
         * @returns {boolean}
         */
        static getHttpProfileSslEverywhereEnabledValue(profile) {
            return profile.http_to_https &&
                profile.server_side_redirect_to_https &&
                profile.secure_cookie_enabled &&
                profile.hsts_enabled &&
                profile.httponly_enabled &&
                profile.x_forwarded_proto_enabled || false;
        }

        /**
         * Processes RateProfile before saving.
         * @param {Object} rateProf - RateProfile object.
         * @protected
         */
        static beforeSaveRateProfile_(rateProf) {
            if (angular.isObject(rateProf)) {
                const { _http_rl_field: field } = rateProf;

                if (field) {
                    if (field === 'http_cookie') {
                        rateProf.http_header = undefined;
                    } else {
                        rateProf.http_cookie = undefined;
                    }

                    rateProf[field] = rateProf._http_rl_value;
                    rateProf._http_rl_field = undefined;
                    rateProf._http_rl_value = undefined;
                }

                if (rateProf.count === 0 && !rateProf.period) {
                    rateProf.period = undefined;
                }

                const { action } = rateProf;

                if (action && action.type === 'RL_ACTION_REDIRECT' && action.redirect) {
                    HTTPRedirectAction.beforeSave(action.redirect);
                }
            }
        }

        /** @override */
        dataToSave() {
            const config = copy(this.getConfig());
            const appType = this.getType();

            each(configNameToType, (type, name) => {
                if (name in config) {
                    const deleteOnSave =
                        Array.isArray(type) ? !_.contains(type, appType) : appType !== type;

                    if (deleteOnSave) {
                        delete config[name];
                    }
                }
            });

            if (config.http_profile) {
                const { http_profile: httpProfile } = config;

                const {
                    cache_config: cacheConfig,
                    ssl_client_certificate_action: sslClientCertificateAction,
                    compression_profile: compressionProfile,
                } = httpProfile;

                if (!httpProfile.hsts_enabled) {
                    delete httpProfile.hsts_max_age;
                }

                if (cacheConfig) {
                    if (!cacheConfig.enabled) {
                        delete httpProfile.cache_config;
                    } else {
                        if (!cacheConfig.selectiveCaching) {
                            delete cacheConfig.uri_non_cacheable;
                        }

                        delete cacheConfig.selectiveCaching;
                    }
                }

                if (compressionProfile && !compressionProfile.compression) {
                    delete httpProfile.compression_profile;
                }

                if (sslClientCertificateAction) {
                    if (sslClientCertificateAction.headers) {
                        sslClientCertificateAction.headers =
                            filter(sslClientCertificateAction.headers, function(hdr) {
                                return hdr.request_header && hdr.request_header_value;
                            });
                    } else {
                        sslClientCertificateAction.close_connection = false;
                    }
                }
            }

            //removes null value when input was not filled in
            if (config.dos_rl_profile && config.dos_rl_profile.rl_profile) {
                _.each(config.dos_rl_profile.rl_profile, rateProf => {
                    if (angular.isArray(rateProf)) {
                        rateProf.forEach(
                            rateProf => ApplicationProfile.beforeSaveRateProfile_(rateProf),
                        );
                    } else {
                        ApplicationProfile.beforeSaveRateProfile_(rateProf);
                    }
                });
            }

            return config;
        }

        /**
         * Return Application Profile type.
         * @return {AppProfileType}
         */
        getType() {
            return this.getConfig().type;
        }

        /**
         * Returs http_profile.compression_profile from config.
         * @return {Object}
         */
        getHttpCompressionProfile() {
            return this.getConfig().http_profile.compression_profile;
        }

        /**
         * Checks if Application Profile of specified type.
         * @param {AppProfileType} type
         * @return {boolean}
         */
        isType(type) {
            return type === this.getType();
        }

        /**
         * Returns a list of network profile types compatible with the passed application profile
         * type.
         * @param {ShortAppProfileType|AppProfileType=} appProfileType
         * @returns {string[]} - Network profile types, ProtocolType enum or it short lower-cased
         *     meaningful part.
         */
        static getAllowedNetProfileTypes(appProfileType) {
            const list = [];

            switch (appProfileType) {
                case APPLICATION_PROFILE_TYPE_HTTP:
                case 'http':
                case APPLICATION_PROFILE_TYPE_SSL:
                case 'ssl':
                    list.push('PROTOCOL_TYPE_TCP_PROXY');
                    break;

                case APPLICATION_PROFILE_TYPE_SYSLOG:
                case 'syslog':
                    list.push('PROTOCOL_TYPE_UDP_FAST_PATH');
                    break;

                //all allowed for DNS and L4
                default:
                    list.push(...NetworkProfile.types);
            }

            return list;
        }

        /**
         * Sets application profile type to the value passed and updates sub-configurations
         * accordingly.
         * @param {AppProfileType} type
         */
        //FIXME need to reset and delete irrelevant sub-configurations rather than carry them over
        setType(type) {
            const config = this.getConfig();
            const defaultAppProfile = this.getDefaultConfig();

            config.type = type;

            switch (type) {
                case APPLICATION_PROFILE_TYPE_HTTP:
                    if (!config.http_profile) {
                        config.http_profile = defaultAppProfile.http_profile;
                    }

                    if (!config.dos_rl_profile) {
                        config.dos_rl_profile = defaultAppProfile.dos_rl_profile;
                    }

                    break;

                case APPLICATION_PROFILE_TYPE_L4:
                case APPLICATION_PROFILE_TYPE_SSL:
                    if (!config.tcp_app_profile) {
                        config.tcp_app_profile = defaultAppProfile.tcp_app_profile;
                    }

                    if (!config.dos_rl_profile) {
                        config.dos_rl_profile = defaultAppProfile.dos_rl_profile;
                    }

                    break;

                case APPLICATION_PROFILE_TYPE_DNS: {
                    if (!config.dos_rl_profile) {
                        config.dos_rl_profile = defaultAppProfile.dos_rl_profile;
                    }

                    if (!config.dns_service_profile) {
                        config.dns_service_profile = defaultAppProfile.dns_service_profile;
                    }

                    const { dns_service_profile: profileConfig } = config;

                    profileConfig.dns_zones = [];

                    break;
                }

                default:
                    config.dos_rl_profile = undefined;
            }
        }

        /**
         * Getter for dns-zones.
         * @return {module:avi/profiles/application.DnsZone[]}
         */
        get dnsZones() {
            return this.getConfig().dns_service_profile.dns_zones;
        }

        /**
         * Removes the given entry from ssl_client_certificate_action.
         * It's applicable only for HTTP Type Application Profiles.
         * @param {Object} header - header.
         */
        removeSslClientCertificateActionHeader(header) {
            const { http_profile: httpProfile } = this.getConfig();
            const { ssl_client_certificate_action: sslClientCertificateAction } = httpProfile;

            sslClientCertificateAction.headers =
                _.reject(sslClientCertificateAction.headers, elm => elm === header);
        }

        /**
         * Adds ssl_client_certificate_action header for HTTP Type Application Profile.
         */
        addSslClientCertificateActionHeader() {
            const { http_profile: httpProfile } = this.getConfig();
            const { ssl_client_certificate_action: sslClientCertificateAction } = httpProfile;

            if (!sslClientCertificateAction.headers) {
                sslClientCertificateAction.headers = [];
            }

            sslClientCertificateAction.headers.push({});
        }

        /**
         * Updates ssl_client_certification_mode for HTTP / SSL type Application profile
         * and deletes pki_profile_ref based on the current mode.
         * @param {string} mode - selected SSL Client Certification Mode
         */
        setSslClientCertMode(mode) {
            const config = this.getConfig();
            const type = this.getType();
            let profile;

            switch (type) {
                case APPLICATION_PROFILE_TYPE_HTTP:
                    ({ http_profile: profile } = config);
                    break;

                case APPLICATION_PROFILE_TYPE_SSL:
                    ({ tcp_app_profile: profile } = config);
                    break;
            }

            profile.ssl_client_certificate_mode = mode;

            if (mode === sslClientCertificateModesHash.SSL_CLIENT_CERTIFICATE_NONE) {
                delete profile.pki_profile_ref;
            }
        }

        /**
         * Adds path match for selective caching and it's applicable only
         * for HTTP Type Application Profile.
         */
        addCacheConfigPathMatch() {
            const config = this.getConfig();
            const defaultConfig = this.getDefaultConfig();
            const { cache_config: cacheConfig } = config.http_profile;

            cacheConfig.uri_non_cacheable =
                defaultConfig.http_profile.cache_config.uri_non_cacheable;
        }

        /**
         * Deletes path match for selective caching.
         */
        deleteCacheConfigPathMatch() {
            const { cache_config: cacheConfig } = this.getConfig().http_profile;

            delete cacheConfig.uri_non_cacheable;
        }

        /**
         * Returns URL/ref of the default network profile ref compatible with a passed appProfile
         * type.
         * @param {ShortAppProfileType|AppProfileType} appProfileType
         * @returns {string} - Item ref
         */
        static getDefaultNetProfileRef(appProfileType) {
            let nwProfName;

            switch (appProfileType) {
                case APPLICATION_PROFILE_TYPE_SYSLOG:
                case 'syslog':
                    nwProfName = 'System-UDP-No-SNAT';
                    break;

                case APPLICATION_PROFILE_TYPE_DNS:
                case 'dns':
                    nwProfName = 'System-UDP-Per-Pkt';
                    break;

                default:
                    nwProfName = 'System-TCP-Proxy';
                    break;
            }

            return defaultValues.getSystemObjectRefByName('networkprofile', nwProfName);
        }

        /**
         * Returns default VS service port by application profile type.
         * @param {AppProfileType|ShortAppProfileType} appProfileType
         * @returns {number}
         */
        static getDefaultServicePort(appProfileType) {
            switch (appProfileType) {
                case APPLICATION_PROFILE_TYPE_SYSLOG:
                case 'syslog':
                    return 514;

                case APPLICATION_PROFILE_TYPE_DNS:
                case 'dns':
                    return 53;

                case APPLICATION_PROFILE_TYPE_SSL:
                case 'ssl':
                    return 443;

                case APPLICATION_PROFILE_TYPE_SIP:
                case 'sip':
                    return 5060;

                case 'http':
                case APPLICATION_PROFILE_TYPE_HTTP:
                case 'l4':
                case APPLICATION_PROFILE_TYPE_L4:
                default:
                    return 80;
            }
        }
    }

    Object.assign(ApplicationProfile.prototype, {
        objectName: 'applicationprofile',
        windowElement: 'application-profile-modal',
    });

    return ApplicationProfile;
}

applicationProfileFactory.$inject = [
    'Item',
    'defaultValues',
    'HTTPRedirectAction',
    'NetworkProfile',
    'systemInfoService',
];

angular.module('avi/profiles/application')
    .factory('ApplicationProfile', applicationProfileFactory);
