/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './waf-rule.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class WafRuleController {
    constructor(
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.expanded = false;

        if (this.editMode) {
            this.expanded = true;
        }
    }

    /**
     * Called when Show/Hide Rule is clicked.
     */
    handleExpand() {
        this.expanded = !this.expanded;
    }

    /**
     * Called when the trash icon is clicked to remove a rule.
     */
    handleRemoveRule() {
        this.onRemoveRule();
    }

    /**
     * Handler for clicking the rule checkbox within a group. Sets the group enable flag based
     * on its rules.
     */
    handleEnableRuleChange() {
        const { rule } = this;
        const enabled = rule.isEnabled();

        rule.setEnabledState(!enabled);

        if (this.onEnableRuleChange) {
            this.onEnableRuleChange();
        }
    }

    /**
     * Handler for clicking the rule textarea.
     * @param {jQuery} $event
     */
    handleFocusRule($event) {
        if (!this.editMode) {
            $event.target.select();
        }
    }

    /**
     * Returns the tooltip for the icon next to the rule name.
     * @return {string}
     */
    getIconTooltipText() {
        const hasExclude = this.rule.hasExcludeListEntries();
        const hasCustom = this.rule.hasCustomMode();
        const { l10nService_: l10nService } = this;

        if (hasExclude && hasCustom) {
            return l10nService.getMessage(
                l10nKeys.ruleWithCustomModeAndExceptionsConfiguredMessage,
            );
        }

        if (hasExclude) {
            return l10nService.getMessage(l10nKeys.ruleWithExceptionsConfiguredMessage);
        }

        if (hasCustom) {
            return l10nService.getMessage(l10nKeys.ruleWithCustomModeConfiguredMessage);
        }
    }

    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        if (changes.editMode) {
            const { currentValue: editMode } = changes.editMode;

            this.expanded = editMode;
        }
    }
}

WafRuleController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  wafRule
 * @description Component for configuring a WAF rule.
 * @param {WafRule} rule - WAF rule.
 * @param {boolean} editMode - True if editing.
 * @param {boolean} allowEdit - True to allow editing.
 * @param {Function} [onEnableRuleChange=] - Called when a rule is enabled.
 * @param {Function} onRemoveRule - Called to remove a rule.
 * @param {boolean} showGrayTriSwitch - True to show a grayed version of the toggle.
 * @param {boolean} modeDelegationAllowed - If true, allows for overriding the parent mode.
 * @param {string} mode - Parent mode.
 * @author alextsg
 */
angular.module('aviApp').component('wafRule', {
    controller: WafRuleController,
    bindings: {
        rule: '<',
        editMode: '<',
        allowEdit: '<',
        onEnableRuleChange: '&?',
        onRemoveRule: '&',
        showGrayTriSwitch: '<',
        modeDelegationAllowed: '<',
        mode: '@',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-rule/waf-rule.html',
});
