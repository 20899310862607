/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc component
 * @name wafExcludeListEntry
 * @description Displays an entry on a WAF exclude list.
 * @param {WafExcludeListEntryConfigItem} entry
 */
angular.module('aviApp').component('wafExcludeListEntry', {
    bindings: {
        entry: '<',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-exclude-list/' +
        'waf-exclude-list-entry/waf-exclude-list-entry.html',
});
