/**
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Inject,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    WafApplicationSignatureProvider,
    WafApplicationSignaturesConfigItem,
} from 'ajs/modules/waf';
import {
    DefaultValues,
    StringService,
} from 'ajs/modules/core';
import {
    IAviDataGridConfig, IAviDropdownOption,
} from 'ng/shared/components';
import { createDropdownOption } from 'ng/shared/utils';
import { IWafApplicationSignatureAppVersion } from 'generated-types';

import * as l10n from './waf-policy-application-rules.l10n';
import './waf-policy-application-rules.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TWafApplicationSignatureProvider = typeof WafApplicationSignatureProvider;

/**
 * @desc Component for Application rules in WafPolicy.
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'waf-policy-application-rules',
    templateUrl: './waf-policy-application-rules.component.html',
})
export class WafPolicyApplicationRulesComponent {
    /**
     * Application signature instance provided as Input by Parent.
     */
    @Input()
    public applicationSignatures: WafApplicationSignaturesConfigItem;

    /**
     * Set when component is fetching Application Signature Provider.
     */
    public isBusy = false;

    /**
     * WafApplicationSignatureProvider object.
     */
    public applicationSignatureProvider: WafApplicationSignatureProvider;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * To be used as ngModel on dropdown.
     */
    public applicationToAdd: string;

    /**
     * To store Available applications transformed as options used in dropdown.
     */
    public allApplicationOptions: IAviDropdownOption[] = [];

    /**
     * DataGrid config for the object list.
     */
    public listDataGridConfig: IAviDataGridConfig;

    /**
     * List containing all the applications with details.
     */
    public allApplications: IWafApplicationSignatureAppVersion[];

    /**
     * List containing selected Applications with details.
     */
    public applicationsGridList: IWafApplicationSignatureAppVersion[] = [];

    public constructor(
        // eslint-disable-next-line @typescript-eslint/indent
        @Inject(WafApplicationSignatureProvider)
        private WafApplicationSignatureProvider: TWafApplicationSignatureProvider,
        @Inject(DefaultValues)
        private readonly defaultValues: DefaultValues,
        @Inject(StringService)
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setupProviderAndApplicationsData();
        this.configureListDataGrid();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.applicationSignatureProvider.destroy();
    }

    /**
     * Called when selecting an Application from the Dropdown.
     * Adds the Application to the list of Applications
     * and resets the ngModel value.
     */
    public addApplication(selectedApplication: string): void {
        this.applicationSignatures.addApplication(selectedApplication);
        this.addApplicationToGridList(selectedApplication);
        setTimeout(() => {
            this.applicationToAdd = undefined;
        });
    }

    /**
     * Updates dropdown options and Grid list to be displayed.
     */
    private addApplicationToGridList(applicationName: string): void {
        const selectedApplicationWithDetails = this.allApplications.find(
            availableApplication => {
                return availableApplication.application === applicationName;
            },
        );

        this.applicationsGridList =
            this.applicationsGridList.concat(selectedApplicationWithDetails);
    }

    /**
     * Loads Application signature provider and prepares
     * other fields.
     */
    private async setupProviderAndApplicationsData(): Promise<void> {
        let providerUuid;

        if (this.applicationSignatures.providerRef) {
            providerUuid = this.stringService.slug(this.applicationSignatures.providerRef);
        } else {
            const defaultProviderRef = this.defaultValues.getSystemObjectRefByName(
                'wafapplicationsignatureprovider',
                'System-WafApplicationSignatures-Trustwave',
            );

            providerUuid = this.stringService.slug(defaultProviderRef);
        }

        this.applicationSignatureProvider = new this.WafApplicationSignatureProvider({
            id: providerUuid,
        });

        this.isBusy = true;
        await this.applicationSignatureProvider.load();
        this.isBusy = false;

        const { availableApplications } = this.applicationSignatureProvider;

        this.allApplications = availableApplications;
        this.allApplicationOptions = this.allApplications.map(
            availableApplication => {
                return createDropdownOption(availableApplication.application);
            },
        );

        if (this.applicationSignatures.applications.length) {
            this.applicationSignatures.applications.forEach(application => {
                this.addApplicationToGridList(application);
            });
        }
    }

    /**
     * Configures Datagrid.
     */
    private configureListDataGrid(): void {
        const { l10nService, l10nKeys } = this;

        this.listDataGridConfig = {
            getRowId: (index, app) => app.application,
            layout: {
                placeholderMessage: l10nService.getMessage(l10nKeys.emptyGridPlaceholder),
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.applicationNameLabel),
                    id: 'application-name',
                    transform(app: IWafApplicationSignatureAppVersion) {
                        return app.application;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.lastUpdateVersionLabel),
                    id: 'last-updated-version',
                    transform(app: IWafApplicationSignatureAppVersion) {
                        return app.last_changed_ruleset_version;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.numberOfRulesLabel),
                    id: 'number-of-rules',
                    transform(app: IWafApplicationSignatureAppVersion) {
                        return app.number_of_rules.toString();
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                    onClick: (applications: IWafApplicationSignatureAppVersion[]) =>
                        this.removeApplications(applications),
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                    shape: 'trash',
                    onClick: (app: IWafApplicationSignatureAppVersion) =>
                        this.removeApplication(app.application),
                },
            ],
        };
    }

    /**
     * Delete a group of Application entries from the list.
     */
    private removeApplications(applications: IWafApplicationSignatureAppVersion[]): void {
        applications.forEach(app => this.removeApplication(app.application));
    }

    /**
     * Delete a single Application entry from the list.
     */
    private removeApplication(
        applicationName: IWafApplicationSignatureAppVersion['application'],
    ): void {
        this.applicationSignatures.removeApplication(applicationName);
        this.applicationsGridList = this.applicationsGridList.filter(
            selectedApplication => {
                return selectedApplication.application !== applicationName;
            },
        );
    }
}
