/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-exclude-list';
const prefix = `${moduleName}.${componentName}`;

export const noExceptionsConfiguredLabel = `${prefix}.noExceptionsConfiguredLabel`;
export const exceptionsLabel = `${prefix}.exceptionsLabel`;
export const subnetInputPlaceholder = `${prefix}.subnetInputPlaceholder`;
export const andLabel = `${prefix}.andLabel`;
export const exceptionPathInputPlaceholder = `${prefix}.exceptionPathInputPlaceholder`;
export const caseSensitiveCheckboxLabel = `${prefix}.caseSensitiveCheckboxLabel`;
export const regexMatchCheckboxLabel = `${prefix}.regexMatchCheckboxLabel`;
export const matchElementInputPlaceholder = `${prefix}.matchElementInputPlaceholder`;
export const exceptionDescriptionInputPlaceholder = `${prefix}.exceptionDescriptionInputPlaceholder`;
export const addExceptionButtonLabel = `${prefix}.addExceptionButtonLabel`;

export const ENGLISH = {
    [noExceptionsConfiguredLabel]: 'No Exceptions Configured',
    [exceptionsLabel]: 'Exceptions',
    [subnetInputPlaceholder]: 'Any Subnet',
    [andLabel]: 'and',
    [exceptionPathInputPlaceholder]: 'Any Path',
    [caseSensitiveCheckboxLabel]: 'Case Sensitive',
    [regexMatchCheckboxLabel]: 'Regex Match',
    [matchElementInputPlaceholder]: 'Any Match Element',
    [exceptionDescriptionInputPlaceholder]: 'Description for exception',
    [addExceptionButtonLabel]: '+ Add Exception',
};
