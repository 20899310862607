/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './path-match-config.component.less';

/**
 * @alias module:match/pathMatchConfig
 * @private
 */
class PathMatchConfigComponent {
    constructor(dropDownUtils) {
        this.criteria = dropDownUtils.getEnumDropdownOptions('StringOperation');
    }
}

PathMatchConfigComponent.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name  pathMatchConfig
 * @module match/pathMatchConfig
 * @description Component for configuring a PathMatch Config Item.
 * @param {PathMatchConfigItem} pathMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('pathMatchConfig', {
    bindings: {
        pathMatch: '<',
        preventEdit: '<',
    },
    controller: PathMatchConfigComponent,
    templateUrl: 'src/components/forms/match-config/matches/path-match-config/' +
        'path-match-config.component.html',
});
