/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  WafCrs
 * @description  WafCrs item.
 */
const wafCrsFactory = (Item, Auth) => {
    class WafCrs extends Item {
        /**
         * Always set to true since dropping this item just means deleting a user's previously
         * uploaded file.
         */
        isDroppable() {
            return !!Auth.isAllowed('wafpolicy', 'w');
        }
    }

    angular.extend(WafCrs.prototype, {
        objectName: 'wafcrs',
    });

    return WafCrs;
};

wafCrsFactory.$inject = [
    'Item',
    'Auth',
];

angular.module('aviApp').factory('WafCrs', wafCrsFactory);
