/**
 * Logs module
 * @module LogsModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import angular from 'angular';

import {
    icapLogHeadersComponentOptions,
    icapLogListExpanderComponentOptions,
    icapLogsComponentOptions,
    icapLogViolationsComponentOptions,
    logTimeframesFactory,
} from '.';

const logsModule = angular.module('avi/logs');

const factories = [{
    factory: logTimeframesFactory,
    name: 'logTimeframes',
}];

const components = [{
    name: 'icapLogs',
    options: icapLogsComponentOptions,
}, {
    name: 'icapLogListExpander',
    options: icapLogListExpanderComponentOptions,
}, {
    name: 'icapLogHeaders',
    options: icapLogHeadersComponentOptions,
}, {
    name: 'icapLogViolation',
    options: icapLogViolationsComponentOptions,
}];

factories.forEach(({ name, factory }) => logsModule.factory(name, factory));

components.forEach(({ name, options }) => logsModule.component(name, options));
