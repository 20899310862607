const moduleName = 'ajs-legacy';
const componentName = 'health-monitor-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const editHealthMonitorHeader = `${prefix}.modalHeaderEdit`;
export const newHealthMonitorHeader = `${prefix}.newHealthMonitorHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const successfulChecksInputLabel = `${prefix}.successfulChecksInputLabel`;
export const failedChecksInputLabel = `${prefix}.failedChecksInputLabel`;
export const sendIntervalInputLabel = `${prefix}.sendIntervalInputLabel`;
export const secInnerText = `${prefix}.secInnerText`;
export const isFederatedInputLabel = `${prefix}.isFederatedInputLabel`;
export const receiveTimeoutInputLabel = `${prefix}.receiveTimeoutInputLabel`;
export const dnsRequestSettingsHeader = `${prefix}.dnsRequestSettingsHeader`;
export const queryNameInputLabel = `${prefix}.queryNameInputLabel`;
export const monitorPortInputLabel = `${prefix}.monitorPortInputLabel`;
export const dnsResponseSettingsHeader = `${prefix}.dnsResponseSettingsHeader`;
export const responseMatchesInputLabel = `${prefix}.responseMatchesInputLabel`;
export const responseCodeInputLabel = `${prefix}.responseCodeInputLabel`;
export const responseStringInputLabel = `${prefix}.responseStringInputLabel`;
export const recordTypeInputLabel = `${prefix}.recordTypeInputLabel`;
export const tcpSettingsHeader = `${prefix}.tcpSettingsHeader`;
export const healthMonitorPortInputLabel = `${prefix}.healthMonitorPortInputLabel`;
export const healthMonitorPortInputPlaceholder = `${prefix}.healthMonitorPortInputPlaceholder`;
export const requestDataInputLabel = `${prefix}.requestDataInputLabel`;
export const tcpHalfOpenInputLabel = `${prefix}.tcpHalfOpenInputLabel`;
export const serverResponseDataInputLabel = `${prefix}.serverResponseDataInputLabel`;
export const serverMaintenanceModeHeader = `${prefix}.serverMaintenanceModeHeader`;
export const maintenanceResponseInputLabel = `${prefix}.maintenanceResponseInputLabel`;
export const udpSettingsHeader = `${prefix}.udpSettingsHeader`;
export const httpSettingsHeader = `${prefix}.httpSettingsHeader`;
export const httpRequestHeaderInputLabel = `${prefix}.httpRequestHeaderInputLabel`;
export const httpRequestBodyInputLabel = `${prefix}.httpRequestBodyInputLabel`;
export const exactHttpRequestInputLabel = `${prefix}.exactHttpRequestInputLabel`;
export const maintenanceCodeInputLabel = `${prefix}.maintenanceCodeInputLabel`;
export const maintenanceCodeInputPlaceholder = `${prefix}.maintenanceCodeInputPlaceholder`;
export const httpsSettingsHeader = `${prefix}.httpsSettingsHeader`;
export const sslAttributesInputLabel = `${prefix}.sslAttributesInputLabel`;
export const serverNameInputLabel = `${prefix}.serverNameInputLabel`;
export const serverNameInputPlaceholder = `${prefix}.serverNameInputPlaceholder`;
export const sslProfileUuidInputLabel = `${prefix}.sslProfileUuidInputLabel`;
export const sslProfileUuidInputPlaceholder = `${prefix}.sslProfileUuidInputPlaceholder`;
export const pkiProfileUuidInputLabel = `${prefix}.pkiProfileUuidInputLabel`;
export const pkiProfileUuidInputPlaceholder = `${prefix}.pkiProfileUuidInputPlaceholder`;
export const sslKeyCertificateUuidInputLabel = `${prefix}.sslKeyCertificateUuidInputLabel`;
export const sslKeyCertificateUuidInputPlaceholder = `${prefix}.sslKeyCertificateUuidInputPlaceholder`;
export const externalSettingsHeader = `${prefix}.externalSettingsHeader`;
export const scriptCodeInputLabel = `${prefix}.scriptCodeInputLabel`;
export const pasteTextRadioOption = `${prefix}.pasteTextRadioOption`;
export const uploadFileRadioOption = `${prefix}.uploadFileRadioOption`;
export const scriptCodeInputPlaceholder = `${prefix}.scriptCodeInputPlaceholder`;
export const scriptParametersInputLabel = `${prefix}.scriptParametersInputLabel`;
export const scriptParametersInputPlaceholder = `${prefix}.scriptParametersInputPlaceholder`;
export const scriptVariablesInputLabel = `${prefix}.scriptVariablesInputLabel`;
export const scriptVariablesInputPlaceholder = `${prefix}.scriptVariablesInputPlaceholder`;
export const sipSettingsInputLabel = `${prefix}.sipSettingsInputLabel`;
export const sipRequestCodeInputLabel = `${prefix}.sipRequestCodeInputLabel`;
export const sipResponseInputLabel = `${prefix}.sipResponseInputLabel`;
export const sipMonitorTransportInputLabel = `${prefix}.sipMonitorTransportInputLabel`;
export const radiusSettingsHeader = `${prefix}.radiusSettingsHeader`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const sharedSecretInputLabel = `${prefix}.sharedSecretInputLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [editHealthMonitorHeader]: 'Edit Health Monitor: {0}',
    [newHealthMonitorHeader]: 'New Health Monitor: {0}',
    [nameInputLabel]: 'Name',
    [typeInputLabel]: 'Type',
    [descriptionInputLabel]: 'Description',
    [successfulChecksInputLabel]: 'Successful Checks',
    [failedChecksInputLabel]: 'Failed Checks',
    [sendIntervalInputLabel]: 'Send Interval',
    [secInnerText]: 'sec',
    [isFederatedInputLabel]: 'Is Federated',
    [receiveTimeoutInputLabel]: 'Receive Timeout',
    [dnsRequestSettingsHeader]: 'DNS Request Settings',
    [queryNameInputLabel]: 'Request Name',
    [monitorPortInputLabel]: 'Monitor Port',
    [dnsResponseSettingsHeader]: 'DNS Response Settings',
    [responseMatchesInputLabel]: 'Response Matches',
    [responseCodeInputLabel]: 'Response Code',
    [responseStringInputLabel]: 'Response String',
    [recordTypeInputLabel]: 'Record Type',
    [tcpSettingsHeader]: 'TCP Settings',
    [healthMonitorPortInputLabel]: 'Health Monitor Port',
    [healthMonitorPortInputPlaceholder]: 'Use Server Port',
    [requestDataInputLabel]: 'Client Request Data',
    [tcpHalfOpenInputLabel]: 'Half-Open (Close connection before completion)',
    [serverResponseDataInputLabel]: 'Server Response Data',
    [serverMaintenanceModeHeader]: 'Server Maintenance Mode',
    [maintenanceResponseInputLabel]: 'Maintenance Server Response Data',
    [udpSettingsHeader]: 'UDP Settings',
    [httpSettingsHeader]: 'HTTP Settings',
    [httpRequestHeaderInputLabel]: 'Client Request Header',
    [httpRequestBodyInputLabel]: 'Client Request Body',
    [exactHttpRequestInputLabel]: 'Use Exact Request',
    [maintenanceCodeInputLabel]: 'Maintenance Response Code',
    [maintenanceCodeInputPlaceholder]: '101, 102, etc.',
    [httpsSettingsHeader]: 'HTTPS Settings',
    [sslAttributesInputLabel]: 'SSL Attributes',
    [serverNameInputLabel]: 'TLS SNI Server Name',
    [serverNameInputPlaceholder]: 'Host Header',
    [sslProfileUuidInputLabel]: 'SSL Profile',
    [sslProfileUuidInputPlaceholder]: 'Select SSL Profile',
    [pkiProfileUuidInputLabel]: 'PKI Profile',
    [pkiProfileUuidInputPlaceholder]: 'Select PKI Profile',
    [sslKeyCertificateUuidInputLabel]: 'SSL Key and Certificate',
    [sslKeyCertificateUuidInputPlaceholder]: 'Select SSL Key and Certificate',
    [externalSettingsHeader]: 'External Settings',
    [scriptCodeInputLabel]: 'Script Code',
    [pasteTextRadioOption]: 'Paste text',
    [uploadFileRadioOption]: 'Upload File',
    [scriptCodeInputPlaceholder]: 'Paste script code here',
    [scriptParametersInputLabel]: 'Script Parameters',
    [scriptParametersInputPlaceholder]: 'Enter script parameters',
    [scriptVariablesInputLabel]: 'Script Variables',
    [scriptVariablesInputPlaceholder]: 'Enter script variables',
    [sipSettingsInputLabel]: 'SIP Settings',
    [sipRequestCodeInputLabel]: 'SIP Request Code',
    [sipResponseInputLabel]: 'SIP Response',
    [sipMonitorTransportInputLabel]: 'SIP Monitor Transport',
    [radiusSettingsHeader]: 'Radius Settings',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Passworde',
    [sharedSecretInputLabel]: 'Shared Secret',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
};
