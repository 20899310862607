/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import * as l10n from './NetworkProfileListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Network Profile List Controller function.
 */
function NetworkProfileListController(
    $scope,
    CRUDGridConfig,
    NetworkProfileCollection,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    $scope.gridConfig = new CRUDGridConfig();

    $scope.collection = new NetworkProfileCollection();

    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            name: 'name',
            template: '<span class="sel-name"> {{row.data.config.name}} </span>',
            sortBy: 'name',
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleType),
            name: 'type',
            template: `
            <span class="sel-type"
                ng-if="row.data.config.profile.type=='PROTOCOL_TYPE_TCP_PROXY'">
                ${l10nService.getMessage(l10nKeys.tcpProxyValue)}
            </span>
            <span class="sel-type"
                ng-if="row.data.config.profile.type=='PROTOCOL_TYPE_TCP_FAST_PATH'">
                ${l10nService.getMessage(l10nKeys.tcpFastPathValue)}
            </span>
            <span class="sel-type"
                ng-if="row.data.config.profile.type=='PROTOCOL_TYPE_UDP_FAST_PATH'">
                ${l10nService.getMessage(l10nKeys.udpFastPathValue)}
            </span>`,
        }, {
            name: 'auto',
            title: l10nService.getMessage(l10nKeys.columnTitleAutoLearn),
            template: `
            <span class="sel-automatic"
                ng-if="row.data.config.profile.type=='PROTOCOL_TYPE_TCP_PROXY'
                && row.data.config.profile.tcp_proxy_profile.automatic">
                ${l10nService.getMessage(l10nKeys.yesLabel)}
            </span>`,
        },
    ];

    const { objectName } = $scope.gridConfig.collection;

    $scope.gridConfig.id = `${objectName}-list-page`;

    $scope.gridConfig.singleactions = [{
        title: l10nService.getMessage(l10nKeys.actionBtnEdit),
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.$on('$destroy', () => $scope.collection.destroy());
}

NetworkProfileListController.$inject = [
    '$scope',
    'CRUDGridConfig',
    'NetworkProfileCollection',
    'l10nService',
];

/**
 * @ngdoc controller
 * @name  NetworkProfileListController
 * @description Controller for Network profile list.
 */
angular.module('aviApp').controller('NetworkProfileListController', NetworkProfileListController);

