/** @module GslbModule */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Item } from 'ajs/modules/data-model';
import { OperationalState } from 'generated-types';
import { isString, isUndefined } from 'underscore';

interface IGslbData {
    config: any;
    runtime: IGslbRuntime;
}

interface IGslbRuntime {
    gslbSiteOperStatuses: OperationalState[];
}

/**
 * @description
 *
 *     Item for GslbService GslbPool member taken from gslbservice-inventory API.
 *
 * @author Alex Malitsky, Ram Pal
 */
export class GSLBPoolMember extends Item {
    public data: IGslbData;

    public getRuntimeData(gslbSiteId: string): any {
        const runtime = this.data && this.data.runtime;

        if (runtime && isString(gslbSiteId) &&
            runtime.gslbSiteOperStatuses &&
            gslbSiteId in runtime.gslbSiteOperStatuses) {
            return runtime.gslbSiteOperStatuses[gslbSiteId];
        } else if (isUndefined(gslbSiteId)) {
            return super.getRuntimeData();
        } else { // placeholder when no data was returned by the backend
            return {
                oper_status: {
                    state: OperationalState.OPER_UNAVAIL,
                    reason: ['Object status is unavailable'],
                },
            };
        }
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected getIdFromData_(data: IGslbData): string | undefined {
        return data.config?.id || undefined;
    }
}

Object.assign(GSLBPoolMember.prototype, {
    objectName: 'GSLBPoolMember',
});
