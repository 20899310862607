/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'waf-rule-group';
const prefix = `${moduleName}.${componentName}`;

export const groupNameInputPlaceholder = `${prefix}.groupNameInputPlaceholder`;
export const removeGroupButtonLabel = `${prefix}.removeGroupButtonLabel`;
export const editGroupButtonLabel = `${prefix}.editGroupButtonLabel`;
export const addRuleButtonLabel = `${prefix}.addRuleButtonLabel`;
export const noRulesConfiguredLabel = `${prefix}.noRulesConfiguredLabel`;
export const createRuleButtonLabel = `${prefix}.createRuleButtonLabel`;

export const ENGLISH = {
    [groupNameInputPlaceholder]: 'Enter Group Name',
    [removeGroupButtonLabel]: 'Remove Group',
    [editGroupButtonLabel]: 'Edit Group',
    [addRuleButtonLabel]: 'Add Rule',
    [noRulesConfiguredLabel]: 'No rules configured',
    [createRuleButtonLabel]: 'Create Rule',
};
