/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const moduleName = 'ajs-legacy';
const componentName = 'application-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const discardCompressionFilterChangesPrompt = `${prefix}.discardCompressionFilterChangesPrompt`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const generalTabLabel = `${prefix}.generalTabLabel`;
export const securityTabLabel = `${prefix}.securityTabLabel`;
export const compressionTabLabel = `${prefix}.compressionTabLabel`;
export const cachingTabLabel = `${prefix}.cachingTabLabel`;
export const ddosTabLabel = `${prefix}.ddosTabLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const tcpSettingsHeader = `${prefix}.tcpSettingsHeader`;
export const enableProxyProtocolInputLabel = `${prefix}.enableProxyProtocolInputLabel`;
export const version1RadioOption = `${prefix}.version1RadioOption`;
export const version2RadioOption = `${prefix}.version2RadioOption`;
export const sipSettingsHeader = `${prefix}.sipSettingsHeader`;
export const transactionTimeoutInputLabel = `${prefix}.transactionTimeoutInputLabel`;
export const httpSettingsHeader = `${prefix}.httpSettingsHeader`;
export const connectionMultiplexInputLabel = `${prefix}.connectionMultiplexInputLabel`;
export const detectNTLMappInputLabel = `${prefix}.detectNTLMappInputLabel`;
export const xForwardedForInputLabel = `${prefix}.xForwardedForInputLabel`;
export const xffAlternateNameInputLabel = `${prefix}.xffAlternateNameInputLabel`;
export const enableChunkMergeInputLabel = `${prefix}.enableChunkMergeInputLabel`;
export const preserveClientIPAddressInputLabel = `${prefix}.preserveClientIPAddressInputLabel`;
export const websocketsInputLabel = `${prefix}.websocketsInputLabel`;
export const uncheckToAllowFullDuplexLabel = `${prefix}.uncheckToAllowFullDuplexLabel`;
export const proxyExpectHeaderInputLabel = `${prefix}.proxyExpectHeaderInputLabel`;
export const tcpConnectionRateLimiterSettingsHeader = `${prefix}.tcpConnectionRateLimiterSettingsHeader`;
export const advancedSettingsHeader = `${prefix}.advancedSettingsHeader`;
export const securityInformationHeader = `${prefix}.securityInformationHeader`;
export const resetConnectionOnRequestForSSLPortInputLabel = `${prefix}.resetConnectionOnRequestForSSLPortInputLabel`;
export const sslEverywhereInputLabel = `${prefix}.sslEverywhereInputLabel`;
export const httpToHttpsRedirectInputLabel = `${prefix}.httpToHttpsRedirectInputLabel`;
export const httpOnlyCookiesInputLabel = `${prefix}.httpOnlyCookiesInputLabel`;
export const secureCookiesInputLabel = `${prefix}.secureCookiesInputLabel`;
export const rewriteServerRedirectsToHttpsInputLabel = `${prefix}.rewriteServerRedirectsToHttpsInputLabel`;
export const httpStrictTransportSecurityInputLabel = `${prefix}.httpStrictTransportSecurityInputLabel`;
export const durationInputLabel = `${prefix}.durationInputLabel`;
export const daysInputLabel = `${prefix}.daysInputLabel`;
export const includeSubdomainsInputLabel = `${prefix}.includeSubdomainsInputLabel`;
export const xForwardedProtoInputLabel = `${prefix}.xForwardedProtoInputLabel`;
export const compressionHeader = `${prefix}.compressionHeader`;
export const enableCompressionInputLabel = `${prefix}.enableCompressionInputLabel`;
export const compressionModeInputLabel = `${prefix}.compressionModeInputLabel`;
export const autoButtonLabel = `${prefix}.autoButtonLabel`;
export const customButtonLabel = `${prefix}.customButtonLabel`;
export const compressionContentTypesInputLabel = `${prefix}.compressionContentTypesInputLabel`;
export const selectStringGroupPlaceholder = `${prefix}.selectStringGroupPlaceholder`;
export const createStringGroupLabel = `${prefix}.createStringGroupLabel`;
export const removeAcceptEncodingHeaderInputLabel = `${prefix}.removeAcceptEncodingHeaderInputLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveFilterButtonLabel = `${prefix}.saveFilterButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const cachingHeader = `${prefix}.cachingHeader`;
export const enableCachingInputLabel = `${prefix}.enableCachingInputLabel`;
export const xCacheInputLabel = `${prefix}.xCacheInputLabel`;
export const ageHeaderInputLabel = `${prefix}.ageHeaderInputLabel`;
export const dateHeaderInputLabel = `${prefix}.dateHeaderInputLabel`;
export const aggressiveInputLabel = `${prefix}.aggressiveInputLabel`;
export const minMaxSizeOfObjectToStoreInCacheLabel = `${prefix}.minMaxSizeOfObjectToStoreInCacheLabel`;
export const cacheableObjectSizeInputLabel = `${prefix}.cacheableObjectSizeInputLabel`;
export const minSizeInputLabel = `${prefix}.minSizeInputLabel`;
export const minBytesInnerText = `${prefix}.minBytesInnerText`;
export const maxSizeInputLabel = `${prefix}.maxSizeInputLabel`;
export const maxBytesInnerText = `${prefix}.maxBytesInnerText`;
export const cacheExpireTimeInputLabel = `${prefix}.cacheExpireTimeInputLabel`;
export const timeInputLabel = `${prefix}.timeInputLabel`;
export const secInnerText = `${prefix}.secInnerText`;
export const heuristicExpireInputLabel = `${prefix}.heuristicExpireInputLabel`;
export const cacheURIwithQueryArgumentsInputLabel = `${prefix}.cacheURIwithQueryArgumentsInputLabel`;
export const cacheableMIMETypesInputLabel = `${prefix}.cacheableMIMETypesInputLabel`;
export const nonCacheableMIMETypesInputLabel = `${prefix}.nonCacheableMIMETypesInputLabel`;
export const nonCacheableURIInputLabel = `${prefix}.nonCacheableURIInputLabel`;
export const dnsSettingsHeader = `${prefix}.dnsSettingsHeader`;
export const ipsReturnedByDNSInputLabel = `${prefix}.ipsReturnedByDNSInputLabel`;
export const ttlInputLabel = `${prefix}.ttlInputLabel`;
export const subnetPrefixLengthInputLabel = `${prefix}.subnetPrefixLengthInputLabel`;
export const processEDNSExtensionsInputLabel = `${prefix}.processEDNSExtensionsInputLabel`;
export const negativeTTLInputLabel = `${prefix}.negativeTTLInputLabel`;
export const invalidDNSQueryProcessingInputLabel = `${prefix}.invalidDNSQueryProcessingInputLabel`;
export const errorResponsePlaceholder = `${prefix}.errorResponsePlaceholder`;
export const httpHeaderValueLabel = `${prefix}.httpHeaderValueLabel`;
export const dnsRequestRateLimiterSettingsHeader = `${prefix}.dnsRequestRateLimiterSettingsHeader`;
export const httpLimitSettingsHeader = `${prefix}.httpLimitSettingsHeader`;
export const httpTimeoutSettingsHeader = `${prefix}.httpTimeoutSettingsHeader`;
export const clientHeaderInputLabel = `${prefix}.clientHeaderInputLabel`;
export const msInnerText = `${prefix}.msInnerText`;
export const clientBodyTimeoutInputLabel = `${prefix}.clientBodyTimeoutInputLabel`;
export const httpKeepAliveTimeoutInputLabel = `${prefix}.httpKeepAliveTimeoutInputLabel`;
export const postAcceptTimeoutInputLabel = `${prefix}.postAcceptTimeoutInputLabel`;
export const httpSizeSettingsHeader = `${prefix}.httpSizeSettingsHeader`;
export const clientMaxBodySizeInputLabel = `${prefix}.clientMaxBodySizeInputLabel`;
export const kbInnerText = `${prefix}.kbInnerText`;
export const clientMaxHeaderFieldSizeInputLabel = `${prefix}.clientMaxHeaderFieldSizeInputLabel`;
export const clientMaxCompleteHeaderSizeInputLabel = `${prefix}.clientMaxCompleteHeaderSizeInputLabel`;
export const sendKeepAliveHeaderInputLabel = `${prefix}.sendKeepAliveHeaderInputLabel`;
export const allowHeaderNamesWithDotInputLabel = `${prefix}.allowHeaderNamesWithDotInputLabel`;
export const useAppKeepAliveTimeoutInputLabel = `${prefix}.useAppKeepAliveTimeoutInputLabel`;
export const enableRequestBodyBufferingInputLabel = `${prefix}.enableRequestBodyBufferingInputLabel`;
export const rateLimitHttpTcpSettingsHeader = `${prefix}.rateLimitHttpTcpSettingsHeader`;
export const secondsInnerText = `${prefix}.secondsInnerText`;

export const ENGLISH = {
    [discardCompressionFilterChangesPrompt]: 'Discard Compression Filter changes ?',
    [modalHeaderEdit]: 'Edit Application Profile: {0}',
    [modalHeaderNew]: 'New Application Profile: {0}',
    [generalTabLabel]: 'General',
    [securityTabLabel]: 'Security',
    [compressionTabLabel]: 'Compression',
    [cachingTabLabel]: 'Caching',
    [ddosTabLabel]: 'DDoS',
    [nameInputLabel]: 'Name',
    [typeInputLabel]: 'Type',
    [descriptionInputLabel]: 'Description',
    [tcpSettingsHeader]: 'TCP Settings',
    [enableProxyProtocolInputLabel]: 'Enable PROXY Protocol',
    [version1RadioOption]: 'Version 1',
    [version2RadioOption]: 'Version 2',
    [sipSettingsHeader]: 'SIP Settings',
    [transactionTimeoutInputLabel]: 'Transaction Timeout',
    [httpSettingsHeader]: 'HTTP Settings',
    [connectionMultiplexInputLabel]: 'Connection Multiplex',
    [detectNTLMappInputLabel]: 'Detect NTLM App',
    [xForwardedForInputLabel]: 'X-Forwarded-For',
    [xffAlternateNameInputLabel]: 'XFF Alternate Name',
    [enableChunkMergeInputLabel]: 'Enable Chunk Merge',
    [preserveClientIPAddressInputLabel]: 'Preserve Client IP Address',
    [websocketsInputLabel]: 'WebSockets Proxy',
    [uncheckToAllowFullDuplexLabel]: 'Uncheck to allow full-duplex communication between client and server via the VS',
    [proxyExpectHeaderInputLabel]: 'Proxy Expect Header',
    [tcpConnectionRateLimiterSettingsHeader]: 'TCP Connection Rate Limiter Settings',
    [advancedSettingsHeader]: 'Advanced Settings',
    [securityInformationHeader]: 'Security Information',
    [resetConnectionOnRequestForSSLPortInputLabel]: 'Reset connection on HTTP request for SSL port',
    [sslEverywhereInputLabel]: 'SSL Everywhere',
    [httpToHttpsRedirectInputLabel]: 'HTTP-to-HTTPS Redirect',
    [httpOnlyCookiesInputLabel]: 'HTTP-only Cookies',
    [secureCookiesInputLabel]: 'Secure Cookies',
    [rewriteServerRedirectsToHttpsInputLabel]: 'Rewrite Server Redirects to HTTPS',
    [httpStrictTransportSecurityInputLabel]: 'HTTP Strict Transport Security (HSTS)',
    [durationInputLabel]: 'Duration',
    [daysInputLabel]: 'days',
    [includeSubdomainsInputLabel]: 'includeSubdomains',
    [xForwardedProtoInputLabel]: 'X-Forwarded-Proto',
    [compressionHeader]: 'Compression',
    [enableCompressionInputLabel]: 'Enable Compression',
    [compressionModeInputLabel]: 'Compression Mode',
    [autoButtonLabel]: 'Auto',
    [customButtonLabel]: 'Custom',
    [compressionContentTypesInputLabel]: 'Compressible Content Types',
    [selectStringGroupPlaceholder]: 'Select a string group',
    [createStringGroupLabel]: 'Create String Group',
    [removeAcceptEncodingHeaderInputLabel]: 'Remove Accept Encoding Header',
    [cancelButtonLabel]: 'Cancel',
    [saveFilterButtonLabel]: 'Save Filter',
    [saveButtonLabel]: 'Save',
    [cachingHeader]: 'Caching',
    [enableCachingInputLabel]: 'Enable Caching',
    [xCacheInputLabel]: 'X-Cache',
    [ageHeaderInputLabel]: 'Age Header',
    [dateHeaderInputLabel]: 'Date Header',
    [aggressiveInputLabel]: 'Aggressive',
    [minMaxSizeOfObjectToStoreInCacheLabel]: 'Minimum and maximum size of an object to store in the cache',
    [cacheableObjectSizeInputLabel]: 'Cacheable Object Size',
    [minSizeInputLabel]: 'Min Size',
    [minBytesInnerText]: 'Min. bytes',
    [maxSizeInputLabel]: 'Max Size',
    [maxBytesInnerText]: 'Max. bytes',
    [cacheExpireTimeInputLabel]: 'Cache Expire Time',
    [timeInputLabel]: 'Time',
    [secInnerText]: 'Sec',
    [heuristicExpireInputLabel]: 'Heuristic Expire',
    [cacheURIwithQueryArgumentsInputLabel]: 'Cache URI with Query Arguments',
    [cacheableMIMETypesInputLabel]: 'Cacheable MIME Types',
    [nonCacheableMIMETypesInputLabel]: 'Non-Cacheable MIME Typess',
    [nonCacheableURIInputLabel]: 'Non-Cacheable URI',
    [dnsSettingsHeader]: 'DNS Settings',
    [ipsReturnedByDNSInputLabel]: 'Number of IPs returned by DNS server',
    [ttlInputLabel]: 'TTL',
    [subnetPrefixLengthInputLabel]: 'Subnet prefix length',
    [processEDNSExtensionsInputLabel]: 'Process EDNS Extensions',
    [negativeTTLInputLabel]: 'Negative TTL',
    [invalidDNSQueryProcessingInputLabel]: '(Options for) Invalid DNS Query processing',
    [errorResponsePlaceholder]: 'Error Response',
    [httpHeaderValueLabel]: 'HTTP Header Value',
    [dnsRequestRateLimiterSettingsHeader]: 'DNS Request Rate Limiter Settings',
    [httpLimitSettingsHeader]: 'HTTP Limit Settings',
    [httpTimeoutSettingsHeader]: 'HTTP Timeout Settings',
    [clientHeaderInputLabel]: 'Client Header Timeout',
    [msInnerText]: 'ms',
    [clientBodyTimeoutInputLabel]: 'Client Body Timeout',
    [httpKeepAliveTimeoutInputLabel]: 'HTTP Keep-Alive Timeout',
    [postAcceptTimeoutInputLabel]: 'Post Accept Timeout',
    [httpSizeSettingsHeader]: 'HTTP Size Settings',
    [clientMaxBodySizeInputLabel]: 'Client Max Body Size',
    [kbInnerText]: 'KB',
    [clientMaxHeaderFieldSizeInputLabel]: 'Client Max Header Field Size',
    [clientMaxCompleteHeaderSizeInputLabel]: 'Client Max Complete Header Size',
    [sendKeepAliveHeaderInputLabel]: 'Send Keep-Alive header',
    [allowHeaderNamesWithDotInputLabel]: 'Allow Header Names with Dot/Period',
    [useAppKeepAliveTimeoutInputLabel]: 'Use App Keep-Alive Timeout',
    [enableRequestBodyBufferingInputLabel]: 'Enable Request Body Buffering',
    [rateLimitHttpTcpSettingsHeader]: 'Rate Limit HTTP and TCP Settings',
    [secondsInnerText]: 'Seconds',
};
