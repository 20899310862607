/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

const wafRuleGroupConfigItemFactory = (MessageItem, WafRuleConfigItem) => {
    /**
     * @alias module:config-item/WafRuleGroupConfigItem
     * @private
     */
    class WafRuleGroupConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafRuleGroup',
                ...args,
            };

            super(extendedArgs);

            this.newGroup = Boolean(args.newGroup);
        }

        /**
         * Returns true if the group being configured is a newly added group.
         * @return {boolean}
         */
        isNewGroup() {
            return Boolean(this.newGroup);
        }

        /**
         * Sets this.newGroup to false.
         */
        unsetNewGroup() {
            this.newGroup = false;
        }

        /**
         * Returns true if this group is enabled.
         * @return {boolean}
         */
        isEnabled() {
            return this.config.enable;
        }

        /**
         * Returns the index of this group.
         * @return {number}
         */
        getIndex() {
            return this.config.index;
        }

        /**
         * Returns the name of this group.
         * @return {string}
         */
        getName() {
            return this.config.name;
        }

        /**
         * Sets the index for this group.
         * @param {number} index
         */
        setIndex(index) {
            this.config.index = index;
        }

        /**
         * Returns true if the group contains rules.
         * @return {boolean}
         */
        hasRules() {
            return !this.config.rules.isEmpty();
        }

        /**
         * Returns true if all rules in this group are enabled.
         * @return {boolean}
         */
        hasAllRulesEnabled() {
            return _.every(this.config.rules.config, rule => rule.isEnabled());
        }

        /**
         * Returns true if any rule in this group is enabled.
         * @return {boolean}
         */
        hasAnyRuleEnabled() {
            return _.any(this.config.rules.config, rule => rule.isEnabled());
        }

        /**
         * Sets the enable flag of this group.
         * @param {boolean} [enabled=false]
         */
        setEnabledState(enabled = false) {
            this.config.enable = enabled;
        }

        /**
         * Sets the enable flag of all rules in this group.
         * @param {boolean=} enabled
         */
        setAllRulesEnabledState(enabled = false) {
            this.config.rules.config.forEach(rule => rule.setEnabledState(enabled));
        }

        /**
         * Replaces existing rule with new one.
         * @param {WafRuleConfig} newRule
         */
        replaceRule(newRule) {
            const { rules } = this.config;
            const newRuleIndex = newRule.getIndex();
            const index = _.findIndex(rules.config, rule => rule.getIndex() === newRuleIndex);

            if (index > -1) {
                rules.config.splice(index, 1, newRule);
            } else {
                throw new Error('Rule to be replaced not found.');
            }
        }

        /**
         * Adds a new rule to the group. Assigns an index based on the current max index of the
         * rules in this group.
         * @param {WafRuleConfig=} ruleToCreateAbove - If defined, create new rule above this rule.
         */
        addRule(ruleToCreateAbove) {
            const { rules } = this.config;
            const maxIndexRule = _.max(rules.config, rule => rule.getIndex());
            const newIndex = !_.isEmpty(maxIndexRule) ? maxIndexRule.getIndex() + 1 : 0;
            const newRule = this.createChildByField_('rules', { index: newIndex }, true);

            rules.add(newRule);

            if (ruleToCreateAbove instanceof WafRuleConfigItem) {
                const targetIndex = ruleToCreateAbove.getIndex();
                const targetArrayIndex = this.getArrayIndexFromRuleIndex(targetIndex);
                const currentArrayIndex = rules.config.length - 1;

                this.moveRule(currentArrayIndex, targetArrayIndex);
            }
        }

        /**
         * Removes a rule from config.rules.
         * @param {WafRuleConfig} rule
         */
        removeRule(rule) {
            const { rules } = this.config;
            const index = rules.config.indexOf(rule);

            if (index > -1) {
                rules.remove(index);
            }
        }

        /**
         * Returns the array position index from the rule.index property.
         * @param {number} ruleIndex - Index from rule.index.
         * @return {number}
         */
        getArrayIndexFromRuleIndex(ruleIndex) {
            return _.findIndex(this.config.rules.config, rule => rule.getIndex() === ruleIndex);
        }

        /**
         * Moves rule to a new index. All rules in-between need to have their indices shifted.
         * @param {number} oldIndex - Index of the original position of the rule.
         * @param {number} newIndex - Index of the new position.
         */
        moveRule(oldIndex, newIndex) {
            let newIndexCounter = newIndex;
            /**
             * newIndex moves towards the direction of oldIndex
             */
            const increment = oldIndex < newIndex ? -1 : 1;

            while (oldIndex !== newIndexCounter) {
                this.swapRule(oldIndex, newIndexCounter);
                newIndexCounter += increment;
            }
        }

        /**
         * Given two indices of rules, swaps positions in the rules array along with the index
         * property in the rule.
         * @param {number} oldIndex
         * @param {number} newIndex
         */
        swapRule(oldIndex, newIndex) {
            const { rules } = this.config;

            const oldRule = rules.at(oldIndex);
            const newRule = rules.at(newIndex);

            rules.config[oldIndex] = newRule;
            rules.config[newIndex] = oldRule;

            /**
             * Actual 'index' property of the rule.
             */
            const oldIndexValue = oldRule.getIndex();
            const newIndexValue = newRule.getIndex();

            oldRule.setIndex(newIndexValue);
            newRule.setIndex(oldIndexValue);
        }

        /**
         * Adds a WafExcludeListEntryConfigItem MessageItem to config.exclude_list.
         * @param {Object|WafExcludeListEntryConfigItem}
         */
        addExcludeListEntry(exception) {
            this.config.exclude_list.add(exception);
        }

        /**
         * Removes an entry from config.exclude_list.
         * @param {number} index
         */
        removeExcludeListEntry(index) {
            this.config.exclude_list.remove(index);
        }

        /**
         * Returns true if entries exist on config.exclude_list.
         * @return {boolean}
         */
        hasExcludeListEntries() {
            const { exclude_list: excludeList } = this.config;

            return excludeList && !excludeList.isEmpty();
        }

        /**
         * Returns true if any rules within the group contain exceptions.
         * @return {boolean}
         */
        hasRulesWithExcludeListEntries() {
            return _.any(this.config.rules.config, rule => rule.hasExcludeListEntries());
        }

        /**
         * Returns true if any entry has an invalid configuration.
         * @return {boolean}
         */
        hasInvalidExcludeListEntries() {
            return _.any(this.config.exclude_list.config, entry => !entry.isValid());
        }

        /**
         * Returns true if the group contains the exception.
         * @param {Object} exception - Exception containing subnet, path, and match element.
         * @return {boolean}
         */
        hasMatchingException(exception) {
            const { exclude_list: excludeList } = this.getConfig();

            return _.any(excludeList.config, entry => entry.hasMatchingException(exception));
        }

        /**
         * Returns a hash of all rule IDs to rules within the group. Used for lookup.
         * @return {Object.<string, WafRuleConfig>}
         */
        getRuleIdHash() {
            const { rules } = this.config;

            if (!rules || rules.isEmpty()) {
                return {};
            }

            return rules.config.reduce((hash, rule) => {
                hash[rule.getId()] = rule;

                return hash;
            }, {});
        }

        /**
         * Returns the fieldName of the group.
         * @return {string}
         */
        getFieldName() {
            return this.fieldName_;
        }
    }

    return WafRuleGroupConfigItem;
};

wafRuleGroupConfigItemFactory.$inject = [
    'MessageItem',
    'WafRuleConfigItem',
    'WafExcludeListEntryConfigItem',
];

/**
 * @ngdoc factory
 * @name  WafRuleGroupConfigItem
 * @description  WafRuleGroup MessageItem class.
 * @module config-item/WafRuleGroupConfigItem
 * @author alextsg
 */
angular.module('aviApp').factory('WafRuleGroupConfigItem', wafRuleGroupConfigItemFactory);
