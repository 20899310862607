/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { copy } from 'angular';
import {
    pluck,
    debounce,
    isEqual,
} from 'underscore';

import 'ajs/less/components/collection-grid.less';

import * as collectionGridTemplate
    from 'ajs/views/components/collection-grid.partial.html';

import {
    COLL_GRID_MIN_COLUMN_WIDTH,
    COLL_GRID_TABLE_HEADER_SELECTOR,
    COLL_GRID_CHECKBOX_SELECTOR,
    COLL_ROWACTION_SELECTOR,
    COLL_GRID_HEADER_SELECTOR,
} from './collection-grid.constants';

import * as l10n from './CollectionGrid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name collectionGrid
 * @memberOf module:avi/component-kit/grid
 * @restict E
 * @param {Object} config - See an example of gridConfig below.
 * @param {Function=} onSelectChange - Function called with the selected items. Called every time
 *     when the item selection is updated(either from user checkbox selection or from collection
 *     loading).
 * @param {*} ngDisabled - Evaluates to true or false and used to disable buttons and
 *     {@link cell | grid cells}.
 * @param {string} loadOnInit - Won't load collection on init when `false` string is passed to
 *     this attribute.
 * @param {Object} busyState - Contains an 'isBusy' flag and an optional 'message' to be displayed
 *     next to the spinner.
 *
 * @description
 * Abstract grid that works with collection as intermediary while talking to server
 * Collection abstracts server communication logic, providing standart API for the grid and
 * other components to list, create, remove and edit.
 * Collection grid utilizes mostly load feature of collection
 *
 *  Example of usage
 *  <code>
 *  $scope.serversGrid = {
 *       id: MyAccount.uiProperty.grid object is used to save the user's selection of
  *       visible columns. When not set config.collection.objectName is used as an Id.
 *       // An instance of collection
 *       collection: oCollectionInstance
 *       // Contains the list of fields
 *       fields: [{
 *           name:'enabled',
 *           title:'Status',
 *           fullTitle: 'Item status', //optional value for the TH title attribute
 *           label: text for {@link aviFormLabel} help icon tooltip following the column title.
 *           // Transform function helps to make correction to the value before showing it
 *           // in the view
 *           transform: function(row) {
 *               return row.enabled ? 'Enabled' : 'Disabled';
 *           },
 *           sortBy: 'enabled',
 *           // Field name to be required from the collection
 *           // typically will be passed to collection's load call asking it to make sure to have
 *           // that field in response
 *           require: 'the_collection_field',
 *           visibility: strings: mandatory(m)/default(d)/optional (default value when undefined)
 *               or function to be called passed scope as this. When grid has no mandatory fields
 *               all fields become mandatory regardless of 'visibility' property values.
 *
 *           which should return one of these strings.
 *       },{
 *           name:'hostname',
 *           title:'Host Name',
 *           // Template gives ability to customize cell layout
 *           // 2 way data binding works as well, so as an example, you can:
 *           // <input ng-model="row.field">
 *           // You can use row and field vars inside the template
 *           template: '<div ng-repeat>{{row.hostname}}</div>' or '{{any expression with row}}'
 *               which will be wrapped into <span> by {@link cell | cell directive}.
 *       },{
 *           name:'ip',
 *           title:'IP Address',
 *           transform: function(row) {
 *               return row.ip.addr;
 *           },
 *           sortBy: function(a, b){
 *             if (a.ip.addr > b.ip.addr) return 1;
 *             else if (a.ip.addr < b.ip.addr) return -1;
 *             else return 0;
 *           }
 *       },{
 *          name: 'cpu_usage',
 *          require: 'cpu_usage',
 *          metricChart: true to use cell-sparkline to render chart within cell
 *          disabled: function(row){ return true to replace cell with N/A placeholder instead of
  *          corresponding content. }
 *       ],
 *       // Multiple actions is a list of action functions and a title
 *       // `do` function is a function that perform action on selected items
 *       // `do` function should return true to clear selection after the action
 *       multipleactions: [{
 *           title: 'Remove',
 *           'do': function(items) {
 *               // "items" here will contain all selected item objects
 *               // so that you may do actions like delete or anything else
 *               // returning true means that action has been done successfully and the
 *               // checkboxes would be unchecked
 *               // if you want the checkboxes to remain checked just return false
 *               return true;
 *           }
 *       }],
 *       rowId: DEPRECATED,
 *       // Single action is just to display row buttons on the right, like delete button
 *       // don't forget to determine class (usually fontawesome or bootstrap class)
 *       // `do` function is doing all the stuff for you with the row passed to it
 *       singleactions: [{
 *           title: 'Delete',
 *           'class': 'icon-trash',
 *           //need this to enable expand button - skip closing expander on single action
 *           dontCloseExpander: false,
 *           'do': function(item) {
 *               // "item" here is the instance of item that is ready to take action
 *           }
 *       }],
 *       // Row class is just giving ability to put your custom class into the row
 *       rowClass: function(item) {
 *           // same here "item" is an instance of item
 *           return !item.data.config.enabled ? 'disabled' : '';
 *       }
 *       layout:{
 *          lengthAsTotal: true, //when don't use pagination items.length will be used like total
 *          hideDisplaying: true, //to hide number of entries
 *          hideSearch: true //to hide search panel
 *          hideEditColumns: true //to hide cog that opens up modal for Table Customization
 *          hideLoadingSpinner: true //to hide spinner when collection is "busy"
 *          includeCreateButton: true
 *          includeTimeframeSelector: false // Display timeframe selector above table when no items
 *              are selected
 *          includeMetricsValueSelector: false // Display metrics value selector above table when no
 *              items are selected,
 *          disabledCreateButtonLabel: 'label text' //if collection is not creatable and this
 *              property is set, disabled create button will be shown and title text will be shown
 *              on hover.
 *          hideTenantColumn: true. //for all tenants view we usually add a tenant column to the
 *              grid. This is not always needed.
 *       }
 *       // Callback function, is called before showing the expanded state of the row when that
 *       // row was clicked
 *       // with this = scope of grid instance
 *       // sometimes need this to update scope before showing directives
 *       executeBeforeContainerExpand: function(){},
 *       options: {Object} - anything that needs to be passed to the cell template.
 *   };
 *   </code>
 *
 *   To display filters (before Search Icon), we can use optional `filters` transclusion.
 *   <example>
 *      <collection-grid config="::$ctrl.gridConfig">
 *         <collection-grid_filters>
 *            ... transcluded markup ..
 *         </collection-grid_filters>
 *      </collection-grid>
 *   </example>
 */

//TODO calculate viewportSize and fire callback immediately
angular.module('avi/component-kit/grid').directive('collectionGrid', [
'$timeout', '$window', '$document', 'myAccount', '$compile',
'Auth', 'GridColumnSizeManager', 'l10nService', 'devLoggerService',
function(
    $timeout,
    $window,
    $document,
    myAccount,
    $compile,
    Auth,
    GridColumnSizeManager,
    l10nService,
    devLoggerService,
) {
    function collectionGridLink(scope, elm, attr, ctrls, transclude) {
        // to preserve top scroll on grid destroy event only when grid is
        let lastExpanderScope; //reference to the last opened expander's scope or undefined

        /*var debugTop = $('<div class="grid-debug top"/>').appendTo('body');
        var debugBottom = $('<div class="grid-debug bottom"/>').appendTo('body');*/

        scope.attr = attr;
        scope.config.showSelected = false;

        /**
         * Keeps selection state
         * Keeps key>value pairs where key is a unique id of the
         *     row (see rowId for details).
         * @type {Object}
         */
        scope.config.selected = {};

        /**
         * Select all checkbox model
         * @type {boolean}
         */
        scope.config.allSelected = false;

        l10nService.registerSourceBundles(dictionary);

        scope.l10nKeys = l10nKeys;

        scope.nothingIsSelectedMessageList =
            l10nService.getSplitedMessage(l10nKeys.nothingIsSelectedMessage);

        scope.config.layout = angular.extend({
            includeCreateButton: true,
        }, scope.config.layout);

        const storeColumnWidth = debounce(storeColumnWidth_, 300);

        const gridSettings = {
            minColWidth: COLL_GRID_MIN_COLUMN_WIDTH,
            columnNames: [], // we dont know the visible columns at this time.
            selectors: {
                columnHeaderSelector: COLL_GRID_TABLE_HEADER_SELECTOR,
                checkboxSelector: COLL_GRID_CHECKBOX_SELECTOR,
                rowActionsSelector: COLL_ROWACTION_SELECTOR,
                gridHeaderRowSelector: COLL_GRID_HEADER_SELECTOR,
            },
        };

        /**
         * To store column sizes of the grid and apply it on it load.
         * Will be null, when grid id is not passed.
         * @type {GridColumnSizeManager|null}
         */
        let gridColumnSizeManager = null;

        try {
            gridColumnSizeManager = new GridColumnSizeManager(getGridId(), elm, gridSettings);
        } catch (err) {
            devLoggerService.warn(err);
        }

        /**
         * If 'All Tenants' is selected, add a 'Tenant' column to the grid. It is visible by default
         * but can be hidden.
         */
        //TODO move out of directive
        if (Auth.allTenantsMode() && !scope.config.layout.hideTenantColumn &&
            !_.findWhere(scope.config.fields, { title: 'Tenant' })) {
            scope.config.fields.push({
                name: 'tenant',
                title: l10nService.getMessage(l10nKeys.columnTitleTenant),
                template: "{{ row.getTenantRef().name() || row.getConfig()['tenant'] }}",
                visibility: 'default',
            });
        }

        /**
         * If panel template was defined in config then need to define row click function
         * The function displays expandable panel, renders the template in it
         */
        if (!scope.config.expanderDisabled && scope.config.expandedContainerTemplate) {
            scope.config.expanderDisabled = function() {
                return false;
            };
        }

        if (!scope.config.onRowClick && scope.config.expandedContainerTemplate) {
            scope.config.onRowClick = function(row, event) {
                function onExpanderDestroy() {
                    if (typeof config.executeOnExpandDestroy === 'function') {
                        config.executeOnExpandDestroy.call(scope, row);
                    }

                    lastExpanderScope.$destroy();
                    lastExpanderScope = undefined;

                    targetRow.removeClass('expanded');
                }

                const { config } = scope;

                let
                    targetRow,
                    tableColumnsQuantity,
                    expander;

                if (!config.expanderDisabled(row) && event.target.tagName !== 'A') {
                    targetRow = $(event.target).closest('tr');
                    tableColumnsQuantity = config.displayFields.length +
                        (config.multipleactions && config.multipleactions.length ? 1 : 0) + 1;

                    if (!targetRow.hasClass('expanded')) {
                        removeExpander();

                        lastExpanderScope = scope.$new(false);
                        lastExpanderScope.row = row;

                        if (typeof config.executeBeforeContainerExpand === 'function') {
                            config.executeBeforeContainerExpand.call(scope, row);
                        }

                        expander = $compile(
                            `<tr class="details"><td colspan="${tableColumnsQuantity}">${
                                config.expandedContainerTemplate}</td></tr>`,
                        )(lastExpanderScope);

                        expander
                            .insertAfter(targetRow)
                            .on('$destroy', onExpanderDestroy);

                        targetRow
                            .addClass('expanded')
                            .on('$destroy', removeExpander);
                    } else {
                        removeExpander();
                    }
                }
            };
        }

        /**
         * Closes expanded panel if exists
         */
        function removeExpander() {
            elm.find('tr.details').remove();
        }

        /**
         * Returns list of column names
         * @param {Object[]} columns
         * @returns {string[]}
         */
        function getColumnNames(columns) {
            return pluck(columns, 'name');
        }

        const { collection } = scope.config;

        /**
         * Saves page size for current grid to sever.
         */
        function savePageSize() {
            const id = getGridId();
            const grid = myAccount.uiProperty.grid[id] || {};

            grid.pageSize = +scope.pageSize;
            myAccount.uiProperty.grid[id] = grid;
            myAccount.saveUIProperty();
        }

        /**
         * Returns saved page size.
         * @returns {number}
         */
        function getSavedPageSize() {
            const id = getGridId();
            const { grid } = myAccount.uiProperty;
            const gridById = grid[id];

            return gridById && gridById.pageSize || grid.pageSize || 20;
        }

        /**
         * Number of items per page.
         * String since it is set by dropdown.
         * @type {string|number}
         * @public
         **/
        scope.pageSize = collection.getLimit() || getSavedPageSize();

        /**
         * Selectable page sizes
         * @type {Array<number>}
         */
        scope.pageSizes = [10, 20, 30, 50];

        /**
         * Currently selected page. Starts at one.
         * @type {number}
         * @public
         **/
        scope.page = 1;

        /**
         * Returns current page offset.
         * @returns {number}
         */
        function getPageOffset() {
            return (scope.page - 1) * +scope.pageSize;
        }

        /**
         * Returns number of items loaded.
         * @returns {number|undefined|*}
         */
        scope.getTotalNumberOfItems = () => {
            return collection.getTotalNumberOfItems();
        };

        /**
         * Returns maximum number of pages.
         * @returns {number}
         */
        function getMaxPage() {
            return Math.ceil(scope.getTotalNumberOfItems() / +scope.pageSize);
        }

        /**
         * {@link getMaxPage}
         * @type {getMaxPage}
         */
        scope.getMaxPage = getMaxPage;

        scope.pages = [];

        /**
         * Sets the array of available pages on the scope.
         * @inner
         **/
        function setPagesList() {
            const
                length = getMaxPage(),
                pages = [];

            for (let i = 1; i <= length; i++) {
                pages.push({
                    label: i,
                    value: i,
                });
            }

            scope.pages = pages;

            if (scope.page > length) {
                scope.page = 1;
            }
        }

        scope.loadCurrentPage = () => {
            const offset = getPageOffset();

            collection.loadPage(offset, +scope.pageSize);
        };

        /**
         * Handles next page selection event.
         * @public
         */
        scope.onPageSelection = () => {
            scope.loadCurrentPage();
        };

        /**
         * Handles page size change.
         * @public
         */
        scope.pageSizeChange = () => {
            scope.page = 1;

            scope.loadCurrentPage();

            setTimeout(() => setPagesList(), 0);

            savePageSize();
        };

        /**
         * Handles Previous Page button click.
         */
        scope.prevPage = () => {
            if (scope.page > 1) {
                scope.page--;
                scope.loadCurrentPage();
            }
        };

        /**
         * Handles Next Page button click.
         */
        scope.nextPage = () => {
            const maxPage = getMaxPage();

            if (scope.page < maxPage) {
                scope.page++;
                scope.loadCurrentPage();
            }
        };

        /**
         * Returns label to display current range of items loaded vs. total number of items.
         * @returns {string}
         */
        scope.getItemRangeLabel = () => {
            const offset = getPageOffset();
            const total = scope.getTotalNumberOfItems();
            const min = offset + (total > 0 ? 1 : 0);
            let max = min + +scope.pageSize - 1;

            if (max > total) {
                max = total;
            }

            return `${min}-${max}`;
        };

        /**
         * Shows options panel
         */
        let elContainer;

        scope.showOptions = function(event) {
            if (!scope.ngDisabled) {
                elContainer = $(event.target);

                const elOptions = $(elContainer).parent().find('.selection-panel');
                const elOverlay = $(elContainer).parent().find('.drop-mask');

                elContainer.addClass('active');
                elOptions.show();
                elOverlay.show();

                const updatePanelSizeAndPosition = function() {
                    const offset = elContainer.offset();

                    offset.top += elContainer.height() - $($window).scrollTop();
                    elOptions.css(offset);
                };

                updatePanelSizeAndPosition();
                $($window).on('resize', updatePanelSizeAndPosition);
            }
        };

        /**
         * Filters rows according to it's selection status
         * @param row {object}
         * @return {boolean} true if row is selected
         */
        scope.selectionFilter = function(row) {
            return !scope.config.showSelected || scope.rowId(row) in scope.config.selected;
        };

        /**
         * Hides options panel
         */
        scope.hideOptions = function() {
            elm.find('.selection-panel').hide();
            elm.find('.drop-mask').hide();

            elContainer.removeClass('active');

            $($window).off('resize.collection-grid');
        };

        /**
         * Synchronizes config#allSelected with config#selected and provides ability to
         * explicitly set config#allSelected value.
         * @param {boolean=} allSelected - True to select all, false otherwise. If not passed
         *     current config#allSelected value will be used.
         * @param {boolean=} rowCheckboxClick - True when function called through ngClick of
         *     checkbox in a table row.
         * @public
         */
        scope.updateCheckboxesSelection = function(allSelected, rowCheckboxClick) {
            function selectAll() {
                _.each(config.collection.itemById, function(item, itemId) {
                    if (!checkboxDisabledCheck ||
                        !config.checkboxDisable(config.collection.getItemById(itemId))) {
                        config.selected[itemId] = true;
                    }
                });
            }

            const
                { config } = scope,
                checkboxDisabledCheck = typeof config.checkboxDisable === 'function',
                selectedToRemove = [];

            if (_.isUndefined(config.selected)) {
                config.selected = {};
            }

            const prevSelected = copy(config.selected);

            //want to select all / deselect all, ignores arguments passed through triggered events
            if (typeof allSelected === 'boolean') {
                config.allSelected = allSelected;

                if (!config.allSelected) {
                    config.selected = {};
                    scope.config.showSelected = false;
                } else {
                    selectAll();
                }
            //mark new rows as selected on collection load event
            } else if (config.allSelected && !rowCheckboxClick) {
                selectAll();
            //want to remove falsy values from current config#selected and calculate corresponding
            //allSelected value
            } else {
                _.each(config.selected, function(boolVal, itemId) {
                    const item = config.collection.getItemById(itemId);

                    if (!boolVal || !item ||
                        checkboxDisabledCheck && config.checkboxDisable(item)) {
                        selectedToRemove.push(itemId);
                    }
                });

                selectedToRemove.forEach(function(itemId) {
                    delete config.selected[itemId];
                });

                config.allSelected = !_.isEmpty(config.selected) &&
                    _.every(config.collection.itemById, function(item, itemId) {
                        return itemId in config.selected || checkboxDisabledCheck &&
                            config.checkboxDisable(config.collection.getItemById(itemId));
                    });
            }

            // If there's a callback bound for selection change, invoke it when:
            // 1. the change is triggered by single row selection checbox click
            // 2. the change is triggered by all selection checkbox clicks or collection re-load
            if (
                scope.onSelectChange &&
                (rowCheckboxClick || !isEqual(prevSelected, config.selected))
            ) {
                scope.onSelectChange({ items: scope.getSelected() });
            }
        };

        /**
         * Returns true if at least one row is selected
         * @returns {boolean}
         */
        scope.someSelected = function() {
            return _.any(scope.config.selected, function(boolVal) {
                return boolVal;
            });
        };

        /**
         * Selection is enabled when either there are multipleactions or onSelectChange is defined.
         * @returns {boolean}
         */
        scope.isSelectionEnabled = function() {
            const { multipleactions } = scope.config;

            return !!(multipleactions && multipleactions.length || scope.onSelectChange);
        };

        /**
         * Returns id of the row. Using config.rowId to determine unique string for the row.
         * @deprecated config.rowId is deprecated and not supported.
         * @param row {Item}
         * @returns {string} - Item unique id.
         * @inner
         */
        scope.rowId = function(row) {
            return row.getIdFromData();
        };

        /**
         * Returns selected Items.
         * @returns {Item[]}
         */
        scope.getSelected = function() {
            const { config } = scope;

            if (!config.selected) {
                config.selected = {};
            }

            return _.filter(config.collection.items, function(row) {
                return scope.rowId(row) in config.selected;
            });
        };

        scope.multipleActionDisabled = function(action) {
            if (!scope.someSelected() || scope.ngDisabled) {
                return true;
            }

            const { disabled } = action;

            if (angular.isFunction(disabled)) {
                try {
                    return disabled.call(scope, scope.getSelected());
                } catch (e) {
                    console.error('grid multiple action disabled failed', e, action);

                    return true;
                }
            }

            return !!disabled;
        };

        scope.singleActionDisabled = function(row, action) {
            if (scope.ngDisabled) {
                return true;
            }

            const { disabled } = action;

            if (angular.isFunction(disabled)) {
                try {
                    return disabled.call(scope, row);
                } catch (e) {
                    console.error('grid single action disabled failed', e, action);

                    return true;
                }
            }

            return !!disabled;
        };

        /**
         * Used in template - helps to hide single action.
         * @param {Item} row - row object
         * @param {GridAction} action - action object
         * @return {boolean}
         */
        scope.singleActionHidden = function(row, action) {
            if (scope.ngDisabled) {
                return true;
            }

            const { hidden } = action;

            if (angular.isFunction(hidden)) {
                try {
                    return hidden.call(scope, row);
                } catch (e) {
                    console.error('grid single action hidden failed', e, action);

                    return true;
                }
            }

            return !!hidden;
        };

        /**
         * Calls action on selected rows, after that clears selection if the action returned
         * true.
         * @param action {object} - The action object (defined in config)
         * @param event {event} - Event object, just in case we need it in the action
         * @param optional {*} - Optional parameter to pass into multipleaction function.
         */
        scope.doMultipleAction = function(action, event, optional) {
            removeExpander();

            if (action.do.call(scope, scope.getSelected(), event, this, optional)) {
                scope.config.selected = {};
                scope.updateCheckboxesSelection(false);
            }
        };

        /**
         * Calls single action (row button clicked)
         * @param row {object} - Row object
         * @param action {object} - The action object (defined in config)
         * @param index {number} - Id of row in a grid
         * @param {ng.$event} $event
         */
        scope.doSingleAction = function(row, action, index, $event) {
            $event.stopPropagation();

            if (!action.dontCloseExpander) {
                removeExpander();
            }

            if (scope.singleActionDisabled(row, action)) {
                return;
            }

            try {
                action.do.call(scope, row, index, $event);
            } catch (e) {
                console.error('grid single action failed', e, action);
            }
        };

        /**
         * Filters collection, debouncing multiple calls
         */
        let searchTimer = null;

        scope.search = function() {
            $timeout.cancel(searchTimer);

            searchTimer = $timeout(function() {
                scope.config.collection.search(scope.config.search);
            }, 500);
        };

        scope.sort = function(field) {
            if (field.sortBy) {
                const offset = getPageOffset();

                scope.config.collection.sortPage(field.sortBy, offset, +scope.pageSize);

                removeExpander();
            }
        };

        function getGridId() {
            return scope.config.id || scope.config.collection.objectName_;
        }

        function getFieldsMetrics(fields) {
            function push(fieldName) {
                if (!(fieldName in hash)) {
                    list.push({
                        id: fieldName,
                        subscriber: `grid-${getGridId()}`,
                    });

                    hash[fieldName] = true;
                }
            }

            const
                list = [],
                hash = {};

            _.each(fields, function(field) {
                if (typeof field.require === 'string' && field.require) {
                    if (field.require.indexOf(',') !== -1) {
                        _.each(field.require.split(','), function(fieldName) {
                            push(fieldName.trim());
                        });
                    } else {
                        push(field.require);
                    }
                }
            });

            return list;
        }

        function getHiddenFieldsMetrics() {
            return getFieldsMetrics(scope.config.hiddenFields);
        }

        function getShownFieldsMetrics() {
            return getFieldsMetrics(scope.config.displayFields);
        }

        /**
         * Checks whether we have at least one mandatory field in the list. When we have none,
         * all fields will become mandatory regardless of their 'visibility' property values.
         * @returns {boolean} True when we have at least one mandatory field.
         * @inner
         */
        function checkMandatoryFields() {
            return _.any(scope.config.fields, function(field) {
                let visTextValue = field.visibility;

                if (typeof field.visibility === 'function') {
                    visTextValue = field.visibility.call(scope);
                }

                return visTextValue === 'm' || visTextValue === 'mandatory';
            });
        }

        /**
         * ClassName generator for sel- classes.
         * @param {string} name - field#name
         * @returns {string}
         * @public
         */
        scope.getClassFromName = function(name) {
            return name ? name.replace(/\./g, '-') : '';
        };

        let mouseStartX = 0;
        let trigger = false;

        const documentMouseMoveHandler = function(event) {
            const mouseX = event.pageX;

            if (trigger) {
                const dist = mouseX - mouseStartX;

                mouseStartX = mouseX;

                const cell = $(trigger).parent().parent().parent();
                const sibling = cell.next();
                const cellWidth = cell.width();
                const siblingWidth = sibling.width();
                const sharedWidth = cellWidth + siblingWidth;
                const newCellWidth = cellWidth + dist;
                const newSiblingWidth = siblingWidth - dist;
                // To avoid column vanishing during resizing
                const minColWidth = COLL_GRID_MIN_COLUMN_WIDTH;

                // Allow resizing only when both the current and sibling cell has enough width
                // So checkbox/action icons will not be pushed away.
                if (newCellWidth > minColWidth && newCellWidth < sharedWidth &&
                    newSiblingWidth > minColWidth && newSiblingWidth < sharedWidth) {
                    cell.width(newCellWidth);
                    sibling.width(newSiblingWidth);
                }

                storeColumnWidth();

                syncColumnSizeAsync();
            }
        };

        /**
         * Invokes gridColumnSizeManager's storeColumnWidth method
         * on column size change.
         */
        function storeColumnWidth_() {
            if (gridColumnSizeManager) {
                gridColumnSizeManager.storeColumnWidth();
            }
        }

        /**
         * Invokes gridColumnSizeManager to apply stored widths to
         * columns.
         */
        function applyStoredColumnWidth() {
            if (gridColumnSizeManager) {
                gridColumnSizeManager.applyStoredColumnWidth();
            }
        }

        /**
         * To adjust width of existing columns when a column is added/removed.
         */
        function adjustColumnWidth() {
            if (gridColumnSizeManager) {
                gridColumnSizeManager.adjustColumnWidth(
                    getColumnNames(scope.config.displayFields),
                );
            }
        }

        /**
         * Updates gridColumnSizeManager with current visible columns, will be
         * called only during the initial load.
         */
        function updateColumnsInColumnSizeManager() {
            if (gridColumnSizeManager) {
                const visibleColumns = getColumnNames(scope.config.displayFields);

                gridColumnSizeManager.updateCurrentVisibleColumns(visibleColumns);
            }
        }

        const windowResizeHandler = function() {
            const columns = elm.find('.header-table-cell');
            const bodyCols = elm.find('.body-table-colgroup .body-table-col');

            _.each(columns, function(col, index) {
                const $col = $(col);

                $col.get(0).style.width = null;
                $(bodyCols[index]).get(0).style.width = `${$col.width()}px`;
            });

            applyStoredColumnWidth();
            syncColumnSizeAsync();
        };

        function mouseUpHandler() {
            if (trigger) {
                //need timeout so that sort won't be executed on the end of resize
                setTimeout(function() {
                    trigger = null;
                });
                syncColumnSizeAsync();
            }

            $document.off('mousemove', documentMouseMoveHandler);
        }

        function mouseDownHandler(event) {
            if (!$(event.currentTarget).parent().parent().parent()
                .next()) {
                // If next column is not available
                return;
            }

            event.stopPropagation();
            event.preventDefault();
            // Remember mouse start position and element that triggered resize
            trigger = event.currentTarget;
            mouseStartX = event.pageX;

            // Make sure all columns have pixes sizes, otherwise it's
            // gonna mess everything
            const columns = elm.find('.header-table-cell');

            _.each(columns, function(col) {
                $(col).width($(col).width());
            });
            $document.one('mouseup', mouseUpHandler);
            $document.on('mousemove', documentMouseMoveHandler);
        }

        function resizeClickHandler(event) {
            event.preventDefault();
            event.stopPropagation();
        }

        function initColumnResize() {
            elm.find('.resize')
                .on('click', resizeClickHandler)
                .on('mousedown', mouseDownHandler);
        }

        let columnSyncTimeout = 0;

        function syncColumnSizeAsync() {
            clearTimeout(columnSyncTimeout);
            columnSyncTimeout = setTimeout(syncColumnSize);
        }

        function syncColumnSize() {
            const columns = elm.find('.header-table-cell');
            const bodyCols = elm.find('.body-table-colgroup .body-table-col');
            const headerWidth = elm.find('.header-table-row').width();

            _.each(columns, function(col, index) {
                const $col = $(col);
                const headerColWidth = $col.width();
                const percWidth = `${headerColWidth / headerWidth * 100}%`;

                $(bodyCols[index]).css('width', percWidth);
                $col.css('width', percWidth);
            });
        }

        setTimeout(() => {
            initColumnResize();
            applyStoredColumnWidth();
        });

        syncColumnSizeAsync();

        /**
         * Updates single and multiple actions for the grid according to current context roles
         */
        const updateActionColumns = function() {
            if (!scope.config.collection) {
                return;
            }

            const { config } = scope;

            if (!Auth.isAllowed(config.permission ||
                    config.collection.objectName_.split('-')[0], 'w')) {
                if (config.multipleactions) {
                    config.multipleactions_backup = config.multipleactions;
                    config.multipleactions = config.multipleactions.filter(function(action) {
                        return !!action.bypassPermissionsCheck;
                    });
                }

                if (config.singleactions) {
                    config.singleactions_backup = config.singleactions;
                    config.singleactions = config.singleactions.filter(function(action) {
                        return !!action.bypassPermissionsCheck;
                    });
                }
            } else {
                if (config.multipleactions_backup) {
                    config.multipleactions = config.multipleactions_backup;
                }

                if (config.singleactions_backup) {
                    config.singleactions = config.singleactions_backup;
                }
            }
        };

        $timeout(updateActionColumns);

        function onCollectionLoadSuccess() {
            $timeout(() => {
                scope.updateCheckboxesSelection();
                setPagesList();
            }, 50);
        }

        scope.config.collection.bind('collectionLoadSuccess', onCollectionLoadSuccess);

        //instead of collection.items $watchCollection(collection.items)
        scope.config.collection.bind('collectionItemDropSuccess', scope.updateCheckboxesSelection);

        const onCollectionDataFlush = () => {
            scope.updateCheckboxesSelection(false);
            scope.page = 1;
        };

        scope.config.collection.bind('dataFlush', onCollectionDataFlush);

        $($window).on('resize.collection-grid', windowResizeHandler);

        scope.$on('$destroy', function() {
            const { collection } = scope.config;

            if (collection.async) {
                collection.async.stop(true);
            }

            $($window).off('resize.collection-grid');
            $document.off('mouseup', mouseUpHandler);
            $document.off('mousemove', documentMouseMoveHandler);

            collection.unbind('collectionLoadSuccess', onCollectionLoadSuccess);
            collection.unbind('collectionItemDropSuccess', scope.updateCheckboxesSelection);
            collection.unbind('dataFlush', onCollectionDataFlush);
            collection.unSubscribe(getShownFieldsMetrics());

            /*debugTop.remove(); debugBottom.remove();*/
        });

        elm.find('.floating').hide();

        /***
         * Sets two arrays of visible and hidden fields of grid. Only these are
         * being used to render the grid.
         */
        const fieldsOrder = {}; // stores the order of fields listed in gridConfig.

        const combineFields = function(dontLoadCollection) {
            const
                { config } = scope,
                { fields: fieldsList } = config;

            const
                fieldsDict = {}, //by name
                gridId = getGridId(),
                defaultFields = [],
                mandatoryFields = [];

            let displayFields; //arrays of names

            //if we don't have at least one mandatory field all fields become mandatory
            const haveMandatoryFields = checkMandatoryFields();

            _.each(fieldsList, (field, index) => {
                let visibility;

                fieldsDict[field.name] = field;
                fieldsOrder[field.name] = index;

                if (haveMandatoryFields) {
                    if (typeof field.visibility === 'function') {
                        visibility = field.visibility.call(scope);
                    } else if (typeof field.visibility === 'string') {
                        visibility = field.visibility;
                    }
                }

                if (!haveMandatoryFields || visibility === 'mandatory' || visibility === 'm') {
                    field.currentVisibility = 'mandatory';
                    mandatoryFields.push(field.name);
                    defaultFields.push(field.name);
                } else if (visibility === 'default' || visibility === 'd') {
                    field.currentVisibility = 'default';
                    defaultFields.push(field.name);
                } else {
                    field.currentVisibility = 'optional';
                }
            });

            const displayFieldsFromStorage = myAccount.uiProperty.grid[gridId] &&
                myAccount.uiProperty.grid[gridId].displayFields;

            if (displayFieldsFromStorage && displayFieldsFromStorage.length) {
                displayFields = _.union(displayFieldsFromStorage, mandatoryFields);
            } else if (defaultFields.length) {
                displayFields = defaultFields;
            } else {
                displayFields = _.keys(fieldsDict);
            }

            const hiddenFields = _.difference(_.keys(fieldsDict), displayFields);

            config.displayFields = _.values(_.pick(fieldsDict, displayFields));
            config.hiddenFields = _.values(_.pick(fieldsDict, hiddenFields));

            //assume that config.collection is working only with this grid
            config.collection.unSubscribe(getHiddenFieldsMetrics());
            config.collection.subscribe(getShownFieldsMetrics(), dontLoadCollection);
        };

        combineFields(true);//too early for collection load

        updateColumnsInColumnSizeManager();

        // Watch field definitions to keep displayedFields in sync
        scope.$watchCollection('config.fields', (newVal, oldVal) => {
            if (newVal !== oldVal) {
                combineFields();
            }
        });

        /**
         * Opens edit column sequence dialog
         */
        scope.editColumns = function() {
            if (_.find(scope.config.fields, function(f) {
                return !f.name;
            })) {
                console.warn('CollectionGrid: every field must have name option ' +
                    'defined, table customization impossible without names');

                return;
            }

            removeExpander();

            scope.columnConf = {
                displayFields: angular.copy(scope.config.displayFields),
                hiddenFields: angular.copy(scope.config.hiddenFields),
                currentHidden: 0,
                currentDisplayed: 0,
                panel: 'hidden',
            };

            elm.find('.avi-modal').off('keydown').on('keydown', function(event) {
                if (event.keyCode == 9) {
                    // Tab button pressed
                    scope.columnConf.panel = scope.columnConf.panel ==
                        'hidden' ? 'displayed' : 'hidden';
                    scope.$apply();
                } else if (event.keyCode == 38) {
                    // Up
                    if (scope.columnConf.panel == 'hidden' &&
                        scope.columnConf.currentHidden > 0) {
                        scope.columnConf.currentHidden--;
                        scope.$apply();
                    } else if (scope.columnConf.panel == 'displayed' &&
                        scope.columnConf.currentDisplayed > 0) {
                        scope.columnConf.currentDisplayed--;
                        scope.$apply();
                    }
                } else if (event.keyCode == 40) {
                    // Down
                    if (scope.columnConf.panel == 'hidden' &&
                        scope.columnConf.currentHidden <
                        scope.config.hiddenFields.length) {
                        scope.columnConf.currentHidden++;
                        scope.$apply();
                    } else if (scope.columnConf.panel == 'displayed' &&
                        scope.columnConf.currentDisplayed <
                        scope.config.displayFields.length) {
                        scope.columnConf.currentDisplayed++;
                        scope.$apply();
                    }
                } else if (event.keyCode == 39) {
                    // Add column
                    scope.addColumn();
                    scope.$apply();
                } else if (event.keyCode == 37) {
                    // Remove column
                    scope.removeColumn();
                    scope.$apply();
                }
            });
            elm.find('.avi-modal').aviModal();
        };

        /**
         * Closes grid settings dialog modal.
         */
        scope.closeGridSettingsModal = () => elm.find('.avi-modal').aviModal('hide');

        /**
         * Adds the column at the position, if position not passed will add to the end
         * @param position - The position where new field will be injected
         */
        scope.addColumn = function(position) {
            if (scope.columnConf.panel != 'hidden') {
                return;
            }

            const item = scope.columnConf.hiddenFields.splice(
                scope.columnConf.currentHidden, 1,
            )[0];

            if (item) {
                if (position !== undefined) {
                    scope.columnConf.displayFields.splice(position, 0, item);
                } else {
                    scope.columnConf.displayFields.push(item);
                }

                if (scope.columnConf.hiddenFields.length &&
                    scope.columnConf.currentHidden >=
                    scope.columnConf.hiddenFields.length) {
                    scope.columnConf.currentHidden =
                        scope.columnConf.hiddenFields.length - 1;
                }
            }
        };

        /**
         * Removes column from the position
         * @param position
         */
        scope.removeColumn = function(position) {
            if (scope.columnConf.panel != 'displayed' ||
                scope.columnConf.displayFields.length < 2) {
                return;
            }

            const item = scope.columnConf.displayFields.splice(
                scope.columnConf.currentDisplayed, 1,
            )[0];

            if (item) {
                if (position !== undefined) {
                    scope.columnConf.hiddenFields.splice(position, 0, item);
                } else {
                    scope.columnConf.hiddenFields.push(item);
                }

                if (scope.columnConf.displayFields.length &&
                    scope.columnConf.currentDisplayed >=
                    scope.columnConf.displayFields.length) {
                    scope.columnConf.currentDisplayed =
                        scope.columnConf.displayFields.length - 1;
                }
            }
        };

        /**
         * Moves up currently selected column
         */
        scope.moveColumnUp = function() {
            let item,
                prevItem;

            if (scope.columnConf.panel == 'displayed') {
                item = scope.columnConf.displayFields[scope.columnConf.currentDisplayed];
                prevItem = scope.columnConf.displayFields[
                    scope.columnConf.currentDisplayed - 1];

                if (item && prevItem) {
                    scope.columnConf.displayFields[
                        scope.columnConf.currentDisplayed - 1] = item;
                    scope.columnConf.displayFields[
                        scope.columnConf.currentDisplayed] = prevItem;
                    scope.columnConf.currentDisplayed--;
                }
            } else if (scope.columnConf.panel == 'hidden') {
                item = scope.columnConf.hiddenFields[scope.columnConf.currentHidden];
                prevItem = scope.columnConf.hiddenFields[
                    scope.columnConf.currentHidden - 1];

                if (item && prevItem) {
                    scope.columnConf.hiddenFields[
                        scope.columnConf.currentHidden - 1] = item;
                    scope.columnConf.hiddenFields[
                        scope.columnConf.currentHidden] = prevItem;
                    scope.columnConf.currentHidden--;
                }
            }
        };

        /**
         * Moves down currently selected column
         */
        scope.moveColumnDown = function() {
            let item,
                nextItem;

            if (scope.columnConf.panel == 'displayed') {
                item = scope.columnConf.displayFields[scope.columnConf.currentDisplayed];
                nextItem = scope.columnConf.displayFields[
                    scope.columnConf.currentDisplayed + 1];

                if (item && nextItem) {
                    scope.columnConf.displayFields[
                        scope.columnConf.currentDisplayed + 1] = item;
                    scope.columnConf.displayFields[
                        scope.columnConf.currentDisplayed] = nextItem;
                    scope.columnConf.currentDisplayed++;
                }
            } else if (scope.columnConf.panel == 'hidden') {
                item = scope.columnConf.hiddenFields[scope.columnConf.currentHidden];
                nextItem = scope.columnConf.hiddenFields[
                    scope.columnConf.currentHidden + 1];

                if (item && nextItem) {
                    scope.columnConf.hiddenFields[
                        scope.columnConf.currentHidden + 1] = item;
                    scope.columnConf.hiddenFields[
                        scope.columnConf.currentHidden] = nextItem;
                    scope.columnConf.currentHidden++;
                }
            }
        };

        function resetColumnWidths() {
            elm.find('.header-table-cell').width('');
            elm.find('.body-table-colgroup .body-table-col').width('');
        }

        /**
         * Resets the columns displayed/hidden in the collection grid.
         */
        scope.resetDefaultColumns = function() {
            function sortByName(a, b) {
                return fieldsOrder[a.name] > fieldsOrder[b.name];
            }

            const
                defaultVisibilityOptions = ['m', 'mandatory', 'd', 'default'],
                visibleFieldsHash = {};

            let displayFields,
                hiddenFields = [];

            const fields = scope.columnConf.displayFields.concat(scope.columnConf.hiddenFields);

            const haveMandatoryFields = checkMandatoryFields();

            if (haveMandatoryFields) {
                displayFields = fields.filter(function(field) {
                    const visibility = typeof field.visibility === 'function' ?
                        field.visibility.call(scope) : field.visibility;

                    const res = defaultVisibilityOptions.indexOf(visibility) !== -1;

                    if (res) {
                        visibleFieldsHash[field.name] = true;
                    }

                    return res;
                });

                hiddenFields = fields.filter(function(field) {
                    return !(field.name in visibleFieldsHash);
                });
            } else {
                displayFields = fields;
            }

            scope.columnConf.displayFields = displayFields.sort(sortByName);
            scope.columnConf.hiddenFields = hiddenFields.sort(sortByName);
        };

        /**
         * Saves column sequence
         */
        scope.saveColumns = function() {
            elm.find('.resize')
                .off('click', resizeClickHandler)
                .off('mousedown', mouseDownHandler);

            const gridId = getGridId();

            if (!(gridId in myAccount.uiProperty.grid)) {
                myAccount.uiProperty.grid[gridId] = {};
            }

            myAccount.uiProperty.grid[gridId].displayFields =
                _.pluck(scope.columnConf.displayFields, 'name');

            myAccount.saveUIProperty();
            combineFields();
            elm.find('.avi-modal').aviModal('hide');

            resetColumnWidths();
            setTimeout(() => {
                adjustColumnWidth();
                syncColumnSizeAsync();
                initColumnResize();
            }, 50);
        };

        scope.rowClass = function(row) {
            return typeof scope.config.rowClass === 'function' &&
                scope.config.rowClass.call(scope, row) || '';
        };

        /**
         * Returns true, if grid's transcluded filters slot has content.
         * @returns {boolean}
         */
        scope.hasTranscludedFilters = function() {
            return transclude.isSlotFilled('filters');
        };

        /**
         * Sets collection grid's initial sorting order with the default one.
         */
        function setInitialSorting() {
            const { defaultSorting } = scope.config;

            if (defaultSorting && defaultSorting !== collection.getSorting()) {
                // Sets defaultSorting in collection
                collection.setSorting(defaultSorting);
            }
        }

        setInitialSorting();

        if (scope.attr['loadOnInit'] !== 'false') {
            const offset = getPageOffset();
            const limit = +scope.pageSize;

            collection.loadPage(offset, limit);
        }
    }

    return {
        scope: {
            config: '<',
            onSelectChange: '&?',
            ngDisabled: '<',
            busyState: '<',
        },
        transclude: {
            filters: '?collectionGridFilters',
        },
        restrict: 'E',
        template: collectionGridTemplate,
        link: collectionGridLink,
    };
}]);
