/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './waf-rule-group.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class WafRuleGroupController {
    constructor(
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.expanded = false;
        this.editing = false;

        if (this.wafGroup.isNewGroup()) {
            this.expanded = true;
            this.editing = true;
            this.wafGroup.unsetNewGroup();
        }
    }

    /**
     * Adds a rule to the group.
     * @param {WafRuleConfig=} rule
     */
    handleAddRule(rule) {
        this.editing = true;
        this.wafGroup.addRule(rule);
    }

    /**
     * Called to remove a group.
     * @param {jQuery} $event
     */
    handleRemoveGroup($event) {
        $event.stopPropagation();
        this.onRemoveGroup();
    }

    /**
     * Handler for clicking the expander button.
     */
    handleExpand() {
        if (this.expanded) {
            this.editing = false;
        }

        this.expanded = !this.expanded;
    }

    /**
     * Handler for clicking the settings button.
     */
    toggleEditing() {
        this.editing = !this.editing;
    }

    /**
     * Returns a state for the group wwitch.
     * @return {number}
     */
    getEnableSwitchState() {
        const wafGroupEnabled = this.wafGroup.isEnabled();

        if (!this.wafGroup.hasRules()) {
            return wafGroupEnabled ? 1 : 0;
        }

        if (wafGroupEnabled && this.wafGroup.hasAllRulesEnabled()) {
            return 1;
        }

        if (wafGroupEnabled && this.wafGroup.hasAnyRuleEnabled()) {
            return 2;
        }

        return 0;
    }

    /**
     * Handler for clicking the group switch. Sets the enable flag of the group and its rules.
     */
    handleEnableSwitchClick() {
        const wafGroupEnabled = this.wafGroup.isEnabled();

        this.wafGroup.setEnabledState(!wafGroupEnabled);
    }

    /**
     * Moves rule to a new index. All rules in-between need to have their indices shifted.
     * @param {number} newIndex - Index of the new position.
     * @param {Object} rule - Object that was moved. dndList directive passes in the object data
     *     and not the instance.
     */
    handleDragAndDrop(newIndex, rule) {
        const ruleIndex = rule.data.config.index;
        const oldIndex = this.wafGroup.getArrayIndexFromRuleIndex(ruleIndex);

        if (oldIndex < newIndex) {
            newIndex--;
        }

        this.wafGroup.moveRule(oldIndex, newIndex);

        return true;
    }

    /**
     * Returns a string to be used as the title for the filter icon.
     * @return {string}
     */
    getFilterIconTitle() {
        const outputs = [];

        if (this.wafGroup.hasExcludeListEntries()) {
            outputs.push('exceptions');
        }

        if (this.wafGroup.hasRulesWithExcludeListEntries()) {
            outputs.push('rules with exceptions');
        }

        return `This group has ${outputs.join(' and ')} configured.`;
    }
}

WafRuleGroupController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  wafRuleGroup
 * @description Component displaying information about a Waf Policy group.
 * @param {WafRuleGroupConfig} config - WafRuleGroupConfig config item.
 * @param {Function} onRemoveGroup - Called to remove a group.
 * @param {boolean} allowEditing - If true, the group is editable.
 * @param {boolean} modeDelegationAllowed - If true, allows for overriding the parent mode.
 * @param {string} mode - Parent mode.
 * @author alextsg
 */
angular.module('aviApp').component('wafRuleGroup', {
    controller: WafRuleGroupController,
    bindings: {
        wafGroup: '<config',
        onRemoveGroup: '&',
        allowEditing: '<',
        modeDelegationAllowed: '<',
        mode: '@',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-rule-group/waf-rule-group.html',
});
