/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import * as l10n from './waf-rule-groups.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class WafRuleGroupsController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns true if the type of group is not 'crs_groups', where editing of the rules is
     * allowed.
     * @return {boolean}
     */
    allowEditing() {
        return this.type !== 'crs_groups';
    }

    /**
     * Called when the Create Group button is clicked.
     * @param {WafRuleGroupConfig=} group - If defined, create the group above this group.
     */
    handleAddGroup(group) {
        this.onAddGroup({ group });
    }

    /**
     * Called to remove a group from the WafPolicy.
     * @param {WafRuleGroupConfig} group.
     */
    handleRemoveGroup(group) {
        this.onRemoveGroup({ group });
    }

    /**
     * Handler for the drag and drop. Calls onDragAndDrop.
     * @param {number} index - Index of the new position in the list to move to.
     * @param {Object} group - Group data. dndList does not return the instance of the group
     *     class, only the data.
     * @return {boolean} dndList requires returning true to know to handle the data management
     *     manually.
     */
    handleDragAndDrop(index, group) {
        this.onDragAndDrop({
            index,
            group,
        });

        return true;
    }
}

WafRuleGroupsController.$inject = [
    'l10nService',
];

/**
 * @ngdoc component
 * @name  wafRuleGroups
 * @description Component displaying a list of WAF groups.
 * @param {WafRuleGroup[]} groups - List of WAF groups.
 * @param {Function} onAddGroup - Called to add a group.
 * @param {Function} onRemoveGroup - Called to remove a group.
 * @param {Function} onDragAndDrop - Called when a user drags and drops a group.
 * @param {string} type - Type of CRS Group.
 * @param {string} [placeholder=] - String to be displayed when no groups exist.
 * @param {boolean} modeDelegationAllowed - If true, allows for overriding the parent mode.
 * @param {string} mode - Parent mode.
 * @author alextsg
 */
angular.module('aviApp').component('wafRuleGroups', {
    controller: WafRuleGroupsController,
    bindings: {
        groups: '<',
        onAddGroup: '&',
        onRemoveGroup: '&',
        onDragAndDrop: '&',
        type: '@',
        placeholder: '@',
        modeDelegationAllowed: '<',
        mode: '@',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-rule-groups/waf-rule-groups.html',
});
