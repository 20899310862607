/**
 * Module containing WAF related components and services.
 * @preferred
 * @module WafModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import {
    ClrCheckboxModule,
    ClrInputModule,
    ClrSpinnerModule,
    ClrTextareaModule,
} from '@clr/angular';
import { DialogModule } from 'ng/modules/dialog/dialog.module';

import {
    WafApplicationRulesListComponent,
    WafApplicationRulesListHeaderComponent,
    WafLearningLinkComponent,
    WafPolicyAllowlistRuleComponent,
    WafPolicyAllowlistRuleExpanderContentComponent,
    WafPolicyAllowlistRuleExpanderContentService,
    WafPolicyAllowlistRulesListComponent,
    WafPolicyApplicationRulesComponent,
    WafPolicyConfirmChangeCrsVersionComponent,
    WafPolicyConfirmChangeCrsVersionCounterComponent,
    WafPolicyPsmGroupComponent,
    WafPolicyPsmGroupExpanderContentComponent,
    WafPolicyPsmGroupsConfigComponent,
    WafPolicyPsmGroupsListComponent,
    WafPolicyPsmLocationComponent,
    WafPolicyPsmLocationExpanderContentComponent,
    WafPolicyPsmLocationsConfigComponent,
    WafPolicyPsmLocationsListComponent,
    WafPolicyPsmRuleComponent,
    WafPolicyPsmRuleExpanderContentComponent,
    WafPolicyPsmRulesConfigComponent,
    WafPolicyPsmRulesListComponent,
} from '.';
import {
    wafApplicationSignatureProviderCollectionProvider,
    wafApplicationSignatureProviderProvider,
} from '../../ajs-upgraded-providers';

const wafComponents = [
    WafApplicationRulesListComponent,
    WafApplicationRulesListHeaderComponent,
    WafLearningLinkComponent,
    WafPolicyAllowlistRuleExpanderContentComponent,
    WafPolicyAllowlistRuleComponent,
    WafPolicyAllowlistRulesListComponent,
    WafPolicyApplicationRulesComponent,
    WafPolicyConfirmChangeCrsVersionComponent,
    WafPolicyConfirmChangeCrsVersionCounterComponent,
    WafPolicyPsmLocationsConfigComponent,
    WafPolicyPsmLocationsListComponent,
    WafPolicyPsmLocationComponent,
    WafPolicyPsmLocationExpanderContentComponent,
    WafPolicyPsmRulesConfigComponent,
    WafPolicyPsmRulesListComponent,
    WafPolicyPsmRuleComponent,
    WafPolicyPsmRuleExpanderContentComponent,
    WafPolicyPsmGroupsConfigComponent,
    WafPolicyPsmGroupsListComponent,
    WafPolicyPsmGroupComponent,
    WafPolicyPsmGroupExpanderContentComponent,
];

@NgModule({
    declarations: [
        ...wafComponents,
    ],
    exports: [
        WafLearningLinkComponent,
        WafPolicyApplicationRulesComponent,
        WafPolicyPsmGroupsConfigComponent,
        WafPolicyPsmLocationsConfigComponent,
        WafPolicyPsmRulesConfigComponent,
        WafPolicyAllowlistRulesListComponent,
    ],
    imports: [
        ClrInputModule,
        ClrSpinnerModule,
        ClrTextareaModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        SharedModule,
        DialogModule,
        ClrCheckboxModule,
    ],
    providers: [
        wafApplicationSignatureProviderProvider,
        wafApplicationSignatureProviderCollectionProvider,
        WafPolicyAllowlistRuleExpanderContentService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class WafModule { }
