/**
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { copy } from 'angular';
import { L10nService } from '@vmw/ngx-vip';

import {
    every,
    isObject,
    isUndefined,
    map,
    reduce,
} from 'underscore';

import { schema } from 'ajs/js/services/schema';
import { naturalSort } from '../../constants';
import { L10nKeysService } from '../i18n/l10n-keys.service';
import { StringService } from '../string-service/string.service';

import {
    IEnumValue,
    ISchema,
    ISchemaEnumValueOptions,
    ISchemaPbFieldObject,
    ISchemaPbMessage,
    TEnumValueHash,
    TEnumValueLabelsHash,
    TFieldSpecialValues,
    TPbEnumName,
    TPbEnumValueKey,
    TPbMessageFieldName,
    TPbMessageName,
    TPbSimpleValue,
    TPermissionName,
} from './schema.types';

interface ISchemaFieldObject {
    defaultValue?: any;
    description: string;
    maxRepeatedLength?: number;
    name: string;
    objectName: string;
}

interface IMergedFieldRange {
    merged: boolean;
    range: number[];
}

interface IFieldRange {
    range?: number[];
    rangeIncludesSpecialValues?: boolean;
}

interface IFieldRangeOptions {
    range?: number[];
    rangeIncludesSpecialValues?: boolean;
    type: string;
}

interface IFieldInputAttrsHash {
    max?: number;
    min?: number;
    step?: number;
}

const INT_TYPE = 'int';
const UINT_TYPE = 'uint';

type TMessageFields = Record<string, {
    objectType: string;
    isRepeated: boolean;
}>;

/**
 * @description
 *
 *   Service to work with data model exported from protobuf during the build.
 *   Stateless singleton (excluding cache for internal optimizations).
 */
export class SchemaService {
    /**
     * Map for Enum-value key and its Common Prefix.
     * Updated every time getEnumValues() or getEnumValue() is called.
     */
    private commonPrefixHash = new Map<TPbEnumValueKey, string>();

    /**
     * Data model exported from protobuf during the build.
     */
    private schema: ISchema = schema;

    constructor(
        private l10nService: L10nService,
        private l10nKeysService: L10nKeysService,
        private readonly stringService: StringService,
    ) { }

    /**
     * Returns a hash of schema labels to values.
     */
    public getEnumValuesHash(enumName: TPbEnumName): TEnumValueHash {
        const enumValues = this.enums[enumName];

        if (enumValues) {
            const enums = {};

            Object.keys(enumValues).forEach((key: TPbEnumValueKey) => {
                const enumObj = this.getEnumValue(enumName, key);

                enums[key] = enumObj;
            });

            return enums;
        }

        throw new Error(`"${enumName}" is not present in Schema.enums`);
    }

    /**
     * Returns formatted list of values found for enumName in Schema.
     */
    public getEnumValues = (enumName: TPbEnumName): IEnumValue[] => {
        if (!this.enums[enumName]) {
            console.error(`"${enumName}" enum not found in schema`);

            return [];
        }

        const enumsValuesHash = this.getEnumValuesHash(enumName);
        const enums = Object.values(enumsValuesHash);

        return enums.sort(this.formattedValueComparator);
    };

    /**
     * Returns list of propertise of enum.
     */
    public getEnumKeys(enumName: TPbEnumName): TPbEnumValueKey[] {
        if (enumName in this.enums) {
            const values = this.enums[enumName];

            return Object.keys(values);
        }

        throw new Error(`"${enumName}" is not present in Schema.enums`);
    }

    /**
     * Returns a hash of enum values to their labels.
     */
    public getEnumValueLabelsHash(enumName: TPbEnumName): TEnumValueLabelsHash {
        return this.getEnumValues(enumName).reduce((hash, { value, label }) => {
            hash[value] = label;

            return hash;
        }, {});
    }

    /**
     * Returns formatted value object found for this enum in Schema.
     */
    public getEnumValue(enumName: TPbEnumName, valueKey: TPbEnumValueKey): IEnumValue {
        if (!this.enums[enumName] || !this.enums[enumName][valueKey]) {
            console.error(`"${enumName}: ${valueKey}" not found in Schema.enums`);

            return {} as any as IEnumValue;
        }

        const values = this.enums[enumName];
        const enumVals = Object.keys(values);
        const enumValueObject = values[valueKey];

        const formattedEnumValueObject = {
            value: valueKey,
            description: '',
        } as any as IEnumValue;

        let commonPrefix: string;

        if (!this.getEnumValueText(enumValueObject)) {
            if (this.commonPrefixHash.has(valueKey)) {
                commonPrefix = this.commonPrefixHash.get(valueKey);
            } else {
                commonPrefix = this.getCommonPrefix(enumVals);

                this.commonPrefixHash.set(valueKey, commonPrefix);
            }

            formattedEnumValueObject.label = this.stringService.enumeration(valueKey, commonPrefix);
        } else {
            // Assemble localization key for the label.
            const labelKey = this.l10nKeysService.getEnumLabelKey(enumName, valueKey);

            // Translate or use default value.
            formattedEnumValueObject.label = this.translate(labelKey) ||
                this.getEnumValueText(enumValueObject);
        }

        // Assemble localization key for the description.
        const descriptionKey = this.l10nKeysService.getEnumDescriptionKey(enumName, valueKey);

        // Translate or use default value.
        formattedEnumValueObject.description = this.translate(descriptionKey) ||
            this.getEnumValueDescription_(enumValueObject);

        Object.keys(enumValueObject).forEach((key: string) => {
            // We have already added theses two properties.
            // so ignoring these two properties.
            const val = enumValueObject[key];

            if (key !== 'description' && key !== 'text') {
                formattedEnumValueObject[key] = val as string;
            }
        });

        return formattedEnumValueObject;
    }

    /**
     * Returns val for given Schema.enums[enum][enumvalueKey]['text'], if exists.
     */
    public getEnumValueText(enumObject: ISchemaEnumValueOptions): string {
        const { text } = enumObject;

        return text || '';
    }

    /**
     * Returns label for this enum found in schema.
     */
    public getEnumValueLabel(enumName: TPbEnumName, valueKey: TPbEnumValueKey): string {
        return this.getEnumValue(enumName, valueKey).label || '';
    }

    /**
     * Returns description for this enum found in schema.
     */
    public getEnumValueDescription(enumName: TPbEnumName, valueKey: TPbEnumValueKey): string {
        return this.getEnumValue(enumName, valueKey).description || '';
    }

    /**
     * Returns true if field definition object from Schema is passed.
     */
    public isFieldDefinitionObject(obj: ISchemaPbFieldObject | any): boolean {
        // One of them should be present
        const fieldObjectKeys = [
            'default',
            'description',
            'enumType',
            'mandatory',
            'messageType',
            'repeated',
            'specialValues',
            'type',
        ];

        /**
         * Returns true if passed key is one of the fieldObjectKeys.
         */
        const isFieldObjectKey = (key: string): boolean => fieldObjectKeys.includes(key);

        return isObject(obj) && Object.keys(obj).some(isFieldObjectKey);
    }

    /**
     * Returns an array of fields for an objectType.
     */
    public getObjectFieldNames(objectType: TPbMessageName): TPbMessageFieldName[] {
        return Object.keys(this.getObjectFields(objectType));
    }

    /**
     * Returns message fields for an objectType.
     */
    public getMessageFields(objectType: TPbMessageName): TMessageFields {
        const objectFields = this.getObjectFields(objectType);

        return reduce(objectFields, (messageMap, fieldProps, field) => {
            if (fieldProps.type === 'message') {
                const { messageType, repeated } = fieldProps;

                messageMap[field] = {
                    objectType: messageType,
                    isRepeated: !!repeated,
                };
            }

            return messageMap;
        }, {});
    }

    /**
     * Returns description of protobuf field, if exists, else empty string.
     */
    public getFieldDescription(
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): string {
        const fieldData = this.getFieldData(objectTypeOrFieldData, fieldName);

        const { description } = fieldData;

        if (!description) {
            return '';
        }

        if (!this.isFieldDefinitionObject(objectTypeOrFieldData)) {
            const descriptionKey =
                this.l10nKeysService.getPbDescriptionKey(
                    objectTypeOrFieldData.toString(), fieldName,
                );

            return this.translate(descriptionKey) || description;
        }

        return description;
    }

    /**
     * Returns default value of object field, if exists, else undefined.
     */
    public getFieldDefaultValue(
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): TPbSimpleValue {
        const fieldData = this.getFieldData(objectTypeOrFieldData, fieldName);

        return fieldData.default;
    }

    /**
     * Returns maxRepeatedLength value of object field, if exists, else undefined.
     * @throws if maxRepeatedLength option is not present for the field.
     */
    public getFieldMaxElements = (
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): number => {
        const fieldData = this.getFieldData(objectTypeOrFieldData, fieldName);

        const { maxRepeatedLength } = fieldData;

        if (isUndefined(maxRepeatedLength)) {
            throw new Error(`maxRepeatedLength does not exist for field "${fieldName}"`);
        }

        return maxRepeatedLength;
    };

    /**
     * Returns permission category for passed objectName
     */
    public getPermissionCategory(objectName: string): TPermissionName {
        const permissionName = this.permissions[objectName];

        if (!permissionName) {
            throw new Error(`"${objectName}" is not present schema.permission`);
        }

        return permissionName;
    }

    /**
     * Returns min and max value of repeated object field, if exists.
     */
    public getRepeatedFieldRangeAsTuple =
    (objectType: TPbMessageName, fieldName: TPbMessageFieldName): [number, number] => {
        const fieldData = this.fieldDefinitionObjLookup(objectType, fieldName);

        if (!fieldData.repeated) {
            throw new Error(`field "${fieldName}" of "${objectType}" is not repeated`);
        }

        const { minRepeatedLength, maxRepeatedLength } = fieldData;

        if (isUndefined(minRepeatedLength) && isUndefined(maxRepeatedLength)) {
            throw new Error(
                'minRepeatedLength and maxRepeatedLength do not exist for field ' +
                `"${fieldName}" of "${objectType}"`,
            );
        }

        const min: number = minRepeatedLength ?? 0;
        const max: number = maxRepeatedLength ?? Infinity;

        return [min, max];
    };

    /**
     * Returns field description, defaultValue, name and objectName of given object type field.
     */
    public getField(
        objectType: TPbMessageName,
        fieldName: TPbMessageFieldName,
    ): ISchemaFieldObject {
        this.fieldDefinitionObjLookup(objectType, fieldName);

        const fieldObject = {
            name: fieldName,
            objectName: objectType,
        } as any as ISchemaFieldObject;

        fieldObject.description = this.getFieldDescription(objectType, fieldName);
        fieldObject.defaultValue = this.getFieldDefaultValue(objectType, fieldName);

        try {
            fieldObject.maxRepeatedLength = this.getFieldMaxElements(objectType, fieldName);
        } catch (e) {
            // empty catch block
        }

        return fieldObject;
    }

    /**
     * Returns special values hash for the object field passed.
     */
    public getFieldSpecialValuesHash = (
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): TFieldSpecialValues => {
        const fieldData: ISchemaPbFieldObject =
            this.getFieldData(objectTypeOrFieldData, fieldName);

        const { specialValues } = fieldData;

        return specialValues || {};
    };

    /**
     * Returns object field special values in the text form.
     */
    public getFieldSpecialValuesAsText =
    (...args: [TPbMessageName | ISchemaPbFieldObject, TPbMessageFieldName]): string => {
        const specialValuesHash = this.getFieldSpecialValuesHash(...args);
        const records: string[] = map(specialValuesHash, (value, key) => `${key}: ${value}`);

        return records.join(', ');
    };

    /**
     * Returns object field values range as text.
     */
    public getFieldRangeAsText = (
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): string => {
        const fieldData = this.getFieldData(objectTypeOrFieldData, fieldName);

        const { min, max } = fieldData;

        if (isUndefined(min) && isUndefined(max)) {
            return '';
        }

        return `${min}-${max}`;
    };

    /**
     * Returns the hash of attributes to be set for a given fieldName.
     */
    public getFieldInputAttributes(
        objectType: TPbMessageName,
        fieldName: TPbMessageFieldName,
    ): IFieldInputAttrsHash {
        const {
            range,
            type,
            rangeIncludesSpecialValues,
        } = this.getFieldOptions(objectType, fieldName);

        const attrsHash = {} as any as IFieldInputAttrsHash;

        const [min, max] = range;

        const hasRange = range.length === 2;

        if (!hasRange) {
            if (type === 'uint') {
                attrsHash.min = 0;
                attrsHash.step = 1;
            } else {
                console.warn(`There is no range for ${fieldName} of ${objectType}`);
            }

            return attrsHash;
        }

        if (type === 'int' || type === 'uint') {
            attrsHash.step = 1;
        }

        const continuousRange = rangeIncludesSpecialValues !== false;

        if (!continuousRange) {
            throw new Error(`min and max cannot be set for ${fieldName} of ${objectType} ` +
                'as the field range & specialValues are not continuous');
        }

        attrsHash.min = min;
        attrsHash.max = max;

        return attrsHash;
    }

    /**
     * Returns field type of the given field.
     */
    public getEnumFieldType(
        objectType: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): string {
        const type = this.getFieldType(objectType, fieldName);

        if (type !== 'enum') {
            throw new Error(`field ${fieldName} of ${objectType} is not of enum type`);
        }

        return this.getFieldType(objectType, fieldName, true);
    }

    /**
     * Returns object field values range as a tuple of min and max number values.
     */
    public getFieldRangeAsTuple = (
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): [number, number] => {
        const fieldData = this.getFieldData(objectTypeOrFieldData, fieldName);

        const { min, max } = fieldData;

        if (isUndefined(min) && isUndefined(max)) {
            throw new Error(`Range does not exist for field "${fieldName}"`);
        }

        return [min, max];
    };

    /**
     * Translate the localiable strings in schema object.
     * @param key Composed key for different category.
     */
    private translate(key: string): string | undefined {
        if (!this.l10nService.isExistKey(key)) {
            return undefined;
        }

        return this.l10nService.getMessage(key);
    }

    /**
     * Merges range values with the special values if they are continuous
     * and precede/follow the regular values range or fall into the regular range.
     * If special values are not present, return range from the input field.
     * If special values fall into the regular range, return regular range.
     * If special values and regular range are mergable with special values,
     * then update min & max to include specialValues.
     *
     * If range & specialValues are not continuous 'merged' flag is set to false.
     *
     * @param range - List of min, max for the input field
     * @param specialValues - List of specialValues
     */
    private mergeFieldRangeWithSpecialValues(
        range: number[],
        specialValues: number[] = [],
    ): IMergedFieldRange {
        if (!range.length) {
            throw new Error('Can\'t merge an empty range with special values');
        }

        const { length: spValuesLength } = specialValues;

        if (!spValuesLength) {
            return {
                range,
            } as any as IMergedFieldRange;
        }

        const [min, max] = range;

        let spMin = Infinity;
        let spMax = -Infinity;

        let spValuesWithinRangeLength = 0;

        specialValues.forEach(val => {
            // special value falls into the regular range
            if (val >= min && val <= max) {
                spValuesWithinRangeLength++;

                return;
            }

            // here we care only about special values ouf ot the regular range
            if (val > spMax) {
                spMax = val;
            }

            if (val < spMin) {
                spMin = val;
            }
        });

        // if all special values are within the regular range
        if (spValuesWithinRangeLength === spValuesLength) {
            return {
                range,
                merged: true,
            };
        }

        // below we care only about special values ouf ot the regular range

        // continuous list if this is true (assuming all are integers and no repeats)
        if (spMax - spMin !== spValuesLength - spValuesWithinRangeLength - 1) {
            return {
                range,
                merged: false,
            };
        }

        // special values to the right from the range
        if (max + 1 === spMin) {
            return {
                range: [min, spMax],
                merged: true,
            };
        }

        // special values to the left from the range
        if (min - 1 === spMax) {
            return {
                range: [spMin, max],
                merged: true,
            };
        }

        return {
            range,
            merged: false,
        };
    }

    /**
     * Returns list of specialValues for a given fieldName.
     * @param objectTypeOrFieldData - objectType
     * @param fieldName - fieldName
     */
    private getSpecialValues(
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): number[] {
        const specialValuesHash = this.getFieldSpecialValuesHash(objectTypeOrFieldData, fieldName);

        return Object.keys(specialValuesHash).map(Number);
    }

    /**
     * Returns type of the field.
     * @param objectTypeOrFieldData - objectType
     * @param fieldName - fieldName
     * @param resolveSubType - Will return enum or message name instead
     *     of 'message' or 'enum'. Has no effect on simple types.
     */
    private getFieldType(
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
        resolveSubType = false,
    ): string {
        const fieldData = this.getFieldData(objectTypeOrFieldData, fieldName);

        const { type } = fieldData;

        if (resolveSubType && (type !== 'enum' && type !== 'message')) {
            throw new Error(`resolveSubType is true but field ${
                fieldName
            } of ${objectTypeOrFieldData} is not of enum or message type`);
        }

        switch (type) {
            case 'int32':
            case 'int64':
                return INT_TYPE;

            case 'uint32':
            case 'uint64':
                return UINT_TYPE;

            case 'enum':
                return resolveSubType ? fieldData.enumType : type;

            case 'message':
                return resolveSubType ? fieldData.messageType : type;

            default:
                return type;
        }
    }

    /**
     * Returns range, rangeIncludesSpecialValues flag for an input field.
     * If specialValues are not present, return min, max from the input field range.
     * If specialValues are present and ranges are mergable with specialValues, then
     * update min & max to include specialValues.
     *
     * If specialValues are present and ranges are not mergable with specialValues, then
     * return min & max from the input field range.
     *
     * @example
     *  range = [1, 50];
     *  specialValues = [-1, 0];
     *  getFieldRange(range, specialValues)
     *      then min is set to '-1' and max is set to '50'
     *
     * @example
     *  range = [10, 100];
     *  specialValues = [0];
     *  getFieldRange(range, specialValues)
     *      then min is set to '10', and max is set to '100' as range & specialValues
     *      are not continuous
     * @param objectTypeOrFieldData - object type which param:protobuf property
     * @param fieldName - field name
     */
    private getFieldRange(
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): IFieldRange {
        const rangesAsText = this.getFieldRangeAsText(objectTypeOrFieldData, fieldName);
        const range: number[] = [];

        if (!rangesAsText) {
            const type = this.getFieldType(objectTypeOrFieldData, fieldName);

            if (type === UINT_TYPE) {
                range.push(0);
            }

            return {
                range,
            };
        }

        const [min, max] = this.getFieldRangeAsTuple(objectTypeOrFieldData, fieldName);

        range.push(min, max);

        const specialValues = this.getSpecialValues(objectTypeOrFieldData, fieldName);

        if (!specialValues.length) {
            return {
                range,
            };
        }

        const {
            range: mergedRange,
            merged,
        } = this.mergeFieldRangeWithSpecialValues([min, max], specialValues);

        // for undefined and true
        if (merged !== false) {
            return {
                range: mergedRange,
                rangeIncludesSpecialValues: true,
            };
        }

        return {
            range,
            rangeIncludesSpecialValues: false,
        };
    }

    /**
     * Returns range, type, rangeIncludesSpecialValues for a given objectType & fieldname.
     */
    private getFieldOptions = (
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): IFieldRangeOptions => {
        const {
            range,
            rangeIncludesSpecialValues,
        } = this.getFieldRange(objectTypeOrFieldData, fieldName);

        const type = this.getFieldType(objectTypeOrFieldData, fieldName);

        return {
            range,
            rangeIncludesSpecialValues,
            type,
        };
    };

    /**
     * Comparator for sorting on values.
     */
    private formattedValueComparator = (objA: IEnumValue, objB: IEnumValue): number => {
        const x = objA.label || objA.value;
        const y = objB.label || objB.value;

        return naturalSort(x, y);
    };

    /**
     * Returns object field definition object by object type and field name.
     */
    private fieldDefinitionObjLookup(
        objectType: TPbMessageName,
        fieldName: TPbMessageFieldName,
    ): ISchemaPbFieldObject {
        const messageFields = this.getObjectFields(objectType);
        const fieldData = messageFields[fieldName];

        if (!fieldData) {
            throw new Error(`field "${fieldName}" of "${objectType}" is not found in Schema`);
        }

        return fieldData;
    }

    /**
     * Returns the fields of a given objectType.
     */
    private getObjectFields(
        objectType: TPbMessageName,
    ): Record<string, ISchemaPbFieldObject> {
        const objectTypeData: ISchemaPbMessage = this.pb[objectType];

        if (!objectTypeData) {
            throw new Error(`objectType "${objectType}" is not found in Schema.pb`);
        }

        return copy(objectTypeData.fields);
    }

    /**
     * Returns common prefix indicated by '_' separator, or empty string if none exists.
     */
    private getCommonPrefix(enumVals: TPbEnumValueKey[]): string {
        const enumsCopy = [...enumVals];
        const [comparator] = enumsCopy;
        const comparatorSplits = [];
        const chunks = comparator.split('_');

        let commonPrefix = '';
        let prefix = '';

        for (let i = 0; i < chunks.length - 1; i++) {
            prefix += `${chunks[i]}_`;

            comparatorSplits.push(prefix);
        }

        for (let i = comparatorSplits.length - 1; i >= 0; i--) {
            const split = comparatorSplits[i];

            const isCommon = every(enumsCopy, val => {
                return val.indexOf(split) === 0;
            });

            if (isCommon) {
                commonPrefix = split;
                break;
            }
        }

        return commonPrefix;
    }

    /**
     * Getter for schema.enums
     */
    private get enums(): ISchema['enums'] {
        return this.schema.enums;
    }

    /**
     * Getter for schema.pb
     */
    private get pb(): ISchema['pb'] {
        return this.schema.pb;
    }

    /**
     * Getter for schema.permission
     */
    private get permissions(): ISchema['permission'] {
        return this.schema.permission;
    }

    /**
     * Returns FieldData of given objectType and fieldName,
     * If fieldData is passed as first param, returns it directly.
     */
    private getFieldData(
        objectTypeOrFieldData: TPbMessageName | ISchemaPbFieldObject,
        fieldName: TPbMessageFieldName,
    ): ISchemaPbFieldObject {
        if (!this.isFieldDefinitionObject(objectTypeOrFieldData)) {
            return this.fieldDefinitionObjLookup(
                objectTypeOrFieldData as any as TPbMessageName,
                fieldName,
            );
        }

        return objectTypeOrFieldData as any as ISchemaPbFieldObject;
    }

    /**
     * Returns val for given Schema.enums[enum][enumvalueKey]['description'],
     * if exists, else empty string.
     */
    // eslint-disable-next-line no-underscore-dangle
    private getEnumValueDescription_(enumObject: ISchemaEnumValueOptions): string {
        const { description } = enumObject;

        return description || '';
    }
}

SchemaService.$inject = [
    'l10nService',
    'l10nKeysService',
    'stringService',
];
