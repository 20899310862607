/**
 * Module containing Common UI components and Core services.
 * @preferred
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrProgressBarModule,
    ClrSpinnerModule,
} from '@clr/angular';
import { VIPModule } from '@vmw/ngx-vip';

import {
    AsyncFileUploadComponent,
    AsyncFileUploadWithUploadServiceComponent,
    AviCardComponent,
    AviCollectionDropdownComponent,
    AviCollectionDropdownSelectedValuesContainerComponent,
    AviDataGridComponent,
    AviDatePipe,
    AviDropdownButtonComponent,
    AviDropdownComponent,
    AviDropdownMenuComponent,
    AviDropdownMultipleSelectedValueComponent,
    AviDropdownOptionComponent,
    AviDropdownOptionsComponent,
    AviDropdownSelectedValuesContainerComponent,
    AviDropdownSingleSelectedValueComponent,
    AviIconDirective,
    AviInputContainerComponent,
    AviLabelWithTooltipComponent,
    AviStatePageComponent,
    AviTextareaContainerComponent,
    AviTooltipContentComponent,
    AviTooltipDirective,
    AviTooltipIconComponent,
    AviTooltipIconWithContentWrapperComponent,
    BackendErrorMsgPipe,
    BooleanLabelPipe,
    ButtonLoaderComponent,
    ConfigFieldInputValidationDirective,
    CopyrightComponent,
    CustomParamsGridComponent,
    ExpanderActionConfirmationComponent,
    ExpanderActionRowComponent,
    ExpanderButtonComponent,
    ExpanderComponent,
    ExpanderWithActionsComponent,
    FileUploadComponent,
    FileUploadTextareaComponent,
    FullModalBreadcrumbComponent,
    FullModalBreadcrumbsComponent,
    FullModalComponent,
    FullModalConfigComponent,
    FullModalConfigConfirmationComponent,
    FullModalConfigErrorsComponent,
    FullModalConfigFooterComponent,
    FullModalConfigHeaderComponent,
    FullModalConfigHeaderTabsComponent,
    FullModalPreviewComponent,
    FullModalTabSectionComponent,
    GradientLoaderComponent,
    HelperTextTooltipIconComponent,
    InputFieldDirective,
    InvalidateFormDirective,
    ParseIpDirective,
    ParseIpListDirective,
    ParseIpPrefixDirective,
    PasswordInputDirective,
    PermissionCheckDirective,
    ProgressBarDirective,
    ProvideParentFormDirective,
    RbacKeyUniquenessValidationDirective,
    RbacLabelGridAjsWrapperComponent,
    RbacLabelGridComponent,
    RegexPatternDirective,
    TriSwitchComponent,
} from '.';

const sharedComponents = [
    AsyncFileUploadComponent,
    AsyncFileUploadWithUploadServiceComponent,
    AviCardComponent,
    AviCollectionDropdownComponent,
    AviCollectionDropdownSelectedValuesContainerComponent,
    AviDataGridComponent,
    AviDatePipe,
    AviDropdownButtonComponent,
    AviDropdownComponent,
    AviDropdownMenuComponent,
    AviDropdownMultipleSelectedValueComponent,
    AviDropdownOptionsComponent,
    AviDropdownOptionComponent,
    AviDropdownSelectedValuesContainerComponent,
    AviDropdownSingleSelectedValueComponent,
    AviInputContainerComponent,
    AviLabelWithTooltipComponent,
    AviStatePageComponent,
    AviTextareaContainerComponent,
    AviTooltipContentComponent,
    BackendErrorMsgPipe,
    BooleanLabelPipe,
    ButtonLoaderComponent,
    CopyrightComponent,
    CustomParamsGridComponent,
    ExpanderActionConfirmationComponent,
    ExpanderActionRowComponent,
    ExpanderButtonComponent,
    ExpanderComponent,
    ExpanderWithActionsComponent,
    FileUploadComponent,
    FileUploadTextareaComponent,
    FullModalBreadcrumbComponent,
    FullModalBreadcrumbsComponent,
    FullModalComponent,
    FullModalConfigComponent,
    FullModalConfigConfirmationComponent,
    FullModalConfigErrorsComponent,
    FullModalConfigFooterComponent,
    FullModalConfigHeaderComponent,
    FullModalConfigHeaderTabsComponent,
    FullModalPreviewComponent,
    FullModalTabSectionComponent,
    GradientLoaderComponent,
    HelperTextTooltipIconComponent,
    AviTooltipIconComponent,
    AviTooltipIconWithContentWrapperComponent,
    RbacLabelGridComponent,
    RbacLabelGridAjsWrapperComponent,
    TriSwitchComponent,
];

const sharedDirectives = [
    AviIconDirective,
    AviTooltipDirective,
    ConfigFieldInputValidationDirective,
    InputFieldDirective,
    InvalidateFormDirective,
    ParseIpDirective,
    ParseIpListDirective,
    ParseIpPrefixDirective,
    PasswordInputDirective,
    PermissionCheckDirective,
    ProgressBarDirective,
    ProvideParentFormDirective,
    RbacKeyUniquenessValidationDirective,
    RegexPatternDirective,
];

@NgModule({
    declarations: [
        ...sharedComponents,
        ...sharedDirectives,
    ],
    exports: [
        AsyncFileUploadComponent,
        AsyncFileUploadWithUploadServiceComponent,
        AviCardComponent,
        AviCollectionDropdownComponent,
        AviDataGridComponent,
        AviDatePipe,
        AviDropdownButtonComponent,
        AviDropdownComponent,
        AviIconDirective,
        AviInputContainerComponent,
        AviLabelWithTooltipComponent,
        AviStatePageComponent,
        AviTextareaContainerComponent,
        AviTooltipContentComponent,
        AviTooltipDirective,
        AviTooltipIconComponent,
        AviTooltipIconWithContentWrapperComponent,
        BooleanLabelPipe,
        ButtonLoaderComponent,
        ConfigFieldInputValidationDirective,
        CopyrightComponent,
        CustomParamsGridComponent,
        ExpanderComponent,
        ExpanderWithActionsComponent,
        FileUploadComponent,
        FileUploadTextareaComponent,
        FullModalComponent,
        FullModalConfigComponent,
        FullModalTabSectionComponent,
        GradientLoaderComponent,
        HelperTextTooltipIconComponent,
        RbacLabelGridComponent,
        RbacLabelGridAjsWrapperComponent,
        InputFieldDirective,
        InvalidateFormDirective,
        ParseIpDirective,
        ParseIpListDirective,
        ParseIpPrefixDirective,
        PasswordInputDirective,
        PermissionCheckDirective,
        ProgressBarDirective,
        ProvideParentFormDirective,
        RegexPatternDirective,
        TriSwitchComponent,
        VIPModule,
        BackendErrorMsgPipe,
    ],
    imports: [
        BrowserAnimationsModule,
        ClrDatagridModule,
        ClrDropdownModule,
        ClrIconModule,
        ClrInputModule,
        ClrSpinnerModule,
        ClrProgressBarModule,
        CommonModule,
        FormsModule,
        OverlayModule,
        ScrollingModule,
        VIPModule,
    ],
    providers: [
        AviDatePipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
