/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import '../../../less/pages/infrastructure/bgp-create.less';
import * as l10n from './BgpPeerCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('BgpPeerCreateController', [
'$scope', 'bgpPeeringService', 'AviModal', 'defaultValues', 'AviConfirmService',
'SubnetListNetworkCollection', 'getSubnetString', 'l10nService',
function($scope, bgpPeeringService, AviModal, defaultValues, AviConfirmService,
SubnetListNetworkCollection, getSubnetString, l10nService) {
    $scope.$parent.modalScope = $scope;
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.init = function() {
        bgpPeeringService.errors = '';
        $scope.bgpPeeringService = bgpPeeringService;
        $scope.bgpProfileExists = !!$scope.bgpPeeringService.getBgpProfile($scope.vrfName);

        $scope.bgpProfile = angular.copy(bgpPeeringService.createBgpProfile($scope.vrfName));

        if ($scope.allowPlacementNetwork()) {
            $scope.networks = new SubnetListNetworkCollection({
                params: {
                    cloud_uuid: $scope.Cloud.id,
                },
            });
        }
    };

    /**
     * Returns true if a Placement Network can be set on the BGP Peer.
     */
    $scope.allowPlacementNetwork = () => {
        const vtype = $scope.Cloud.getVtype();

        return vtype === 'CLOUD_VCENTER' || vtype === 'CLOUD_OPENSTACK';
    };

    /**
     * Closes the modal.
     */
    $scope.closeModal = function() {
        AviModal.destroy('infra-bgp-peer-create');
    };

    /**
     * Shows a confirmation dialog when the 'Disable BGP Peering' button is clicked.
     */
    $scope.removeBgpProfile = () => {
        AviConfirmService.confirm(l10nService.getMessage(l10nKeys.removeBgpPeeringConfirmMessage))
            .then(() => {
                bgpPeeringService.save(undefined, $scope.vrfName)
                    .then(() => $scope.closeModal());
            });
    };

    $scope.save = function() {
        bgpPeeringService.save($scope.bgpProfile, $scope.vrfName)
            .then(this.closeModal.bind(this));
    };

    $scope.removePeer = function(peer) {
        const { peers } = $scope.bgpProfile;

        if (peers) {
            const i = peers.indexOf(peer);

            if (i > -1) {
                peers.splice(i, 1);
            }
        }
    };

    $scope.addNewPeer = function() {
        const arr = $scope.bgpProfile.peers || [];
        const peer = defaultValues.getDefaultItemConfigByType('bgppeer');

        arr.push(peer);
        $scope.bgpProfile.peers = arr;

        return peer;
    };

    /**
     * Adds empty string value to community repeated field.
     */
    $scope.addCommunityString = function() {
        const community = $scope.bgpProfile.community || [];

        community.push('');
        $scope.bgpProfile.community = community;
    };

    /**
     * Removes community value at index.
     * @param {number} index
     */
    $scope.removeCommunityString = function(index) {
        if (angular.isArray($scope.bgpProfile.community)) {
            $scope.bgpProfile.community.splice(index, 1);
        }
    };

    /**
     * Called when new placement network has been selected.
     * @param  {Object} peer - BGP Peer object.
     * @param  {NetworkSubnetList} selected
     */
    $scope.handleNetworkChange = (peer, selected) => {
        if (!peer.network_ref) {
            peer.subnet = undefined;
            peer.subnet6 = undefined;
            peer.subnetworks = undefined;
            peer.subnetworks6 = undefined;
        } else {
            peer.subnet = undefined;
            peer.subnet6 = undefined;
            peer.subnetworks = [];
            peer.subnetworks6 = [];

            const networkConfig = selected.getConfig();

            if (angular.isArray(networkConfig.subnet)) {
                networkConfig.subnet.forEach(subnet => {
                    const { prefix } = subnet;
                    const { ip_addr: ipAddr } = prefix;
                    const value = angular.extend({}, subnet, {
                        value: getSubnetString(subnet.prefix),
                    });

                    if (ipAddr.type === 'V6') {
                        peer.subnetworks6.push(value);
                    } else if (ipAddr.type === 'V4') {
                        peer.subnetworks.push(value);
                    }
                });
            }
        }

        $scope.onIPChange(peer);
    };

    /**
     * Handles IP input changes.
     * @param {BgpPeer} peer
     */
    $scope.onIPChange = function(peer) {
        if (peer) {
            peer.md5_secret = '';
        }
    };

    $scope.$on('$destroy', function() {
        if ($scope.networks) {
            $scope.networks.destroy();
        }
    });
}]);
