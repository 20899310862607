const moduleName = 'service-engine-group';
const componentName = 'segroup-nsxt-placement-scope-config';
const prefix = `${moduleName}.${componentName}`;

export const vcenterLabel = `${prefix}.vcenterLabel`;
export const removeVcenterHint = `${prefix}.removeVcenterHint`;
export const vcenterFieldLabel = `${prefix}.vcenterFieldLabel`;
export const vcenterFieldPlaceholder = `${prefix}.vcenterFieldPlaceholder`;
export const serviceEngineFolderFieldLabel = `${prefix}.serviceEngineFolderFieldLabel`;
export const serviceEngineFolderFieldPlaceholder = `${prefix}.serviceEngineFolderFieldPlaceholder`;
export const hostScopeFieldLabel = `${prefix}.hostScopeFieldLabel`;
export const anyRadioOptionLabel = `${prefix}.anyRadioOptionLabel`;
export const hostRadioOptionLabel = `${prefix}.hostRadioOptionLabel`;
export const sharedRadioOptionLabel = `${prefix}.sharedRadioOptionLabel`;
export const hostFieldLabel = `${prefix}.hostFieldLabel`;
export const includeRadioOptionLabel = `${prefix}.includeRadioOptionLabel`;
export const excludeRadioOptionLabel = `${prefix}.excludeRadioOptionLabel`;
export const selectHostsFieldPlaceholder = `${prefix}.selectHostsFieldPlaceholder`;
export const dataScopeFieldLabel = `${prefix}.dataScopeFieldLabel`;
export const dataStoreFieldLabel = `${prefix}.dataStoreFieldLabel`;
export const selectDataStoresFieldPlaceholder = `${prefix}.selectDataStoresFieldPlaceholder`;
export const vcenterServerNotAvailable = `${prefix}.vcenterServerNotAvailable`;

export const ENGLISH = {
    [vcenterLabel]: 'vCenter',
    [removeVcenterHint]: 'Remove vCenter',
    [vcenterFieldLabel]: 'vCenter',
    [vcenterFieldPlaceholder]: 'Select vCenter Server',
    [serviceEngineFolderFieldLabel]: 'Service Engine Folder',
    [serviceEngineFolderFieldPlaceholder]: 'Select a Folder',
    [hostScopeFieldLabel]: 'Host Scope Service Engine within',
    [anyRadioOptionLabel]: 'Any',
    [hostRadioOptionLabel]: 'Host',
    [sharedRadioOptionLabel]: 'Shared',
    [hostFieldLabel]: 'Host',
    [includeRadioOptionLabel]: 'Include',
    [excludeRadioOptionLabel]: 'Exclude',
    [selectHostsFieldPlaceholder]: 'Select Hosts',
    [dataScopeFieldLabel]: 'Data Store Scope for Service Engine Virtual Machine',
    [dataStoreFieldLabel]: 'Data Store',
    [selectDataStoresFieldPlaceholder]: 'Select Data Stores',
    [vcenterServerNotAvailable]: 'N/A',
};
