/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @fileoverview It contain constants related to cases. We have severity and type
 * which we currently support in cases creation.
 */

const SEVERITY_1 = 'Severity 1';
const SEVERITY_2 = 'Severity 2';
const SEVERITY_3 = 'Severity 3';
const SEVERITY_4 = 'Severity 4';
const SEVERITY_5 = 'Severity 5';

const BUG = 'Bug';
const CONFIGURATION_HELP = 'Configuration Help';
const FEATURE_REQUEST = 'Feature Request';
const ISSUE = 'Issue';
const QUESTION = 'Question';

const APIC = 'APIC';
const AWS = 'AWS';
const AZURE = 'Azure';
const CSP = 'CSP';
const GCP = 'GCP';
const KUBERNETES = 'Kubernetes';
const LINUX_SERVER_CLOUD = 'Linux Server Cloud';
const OPENSTACK = 'OpenStack';
const VMWARE = 'VMware';

export const typeHash = {
    BUG,
    CONFIGURATION_HELP,
    FEATURE_REQUEST,
    ISSUE,
    QUESTION,
};

export const severityHash = {
    SEVERITY_1,
    SEVERITY_2,
    SEVERITY_3,
    SEVERITY_4,
    SEVERITY_5,
};

export const environmentHash = {
    APIC,
    AWS,
    AZURE,
    CSP,
    GCP,
    KUBERNETES,
    LINUX_SERVER_CLOUD,
    OPENSTACK,
    VMWARE,
};
