/**
 * @module UpdateModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrDatagridModule,
    ClrInputModule,
    ClrSpinnerModule,
    ClrTextareaModule,
} from '@clr/angular';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { VIPModule } from '@vmw/ngx-vip';
import { DialogModule } from '../dialog/dialog.module';

import {
    RecentUpdatesListComponent,
    UpdateCardAboutComponent,
    UpdateNodeStatusCardComponent,
    UpgradeTranscriptModalComponent,
} from './components';

const updateComponents = [
    RecentUpdatesListComponent,
    UpdateCardAboutComponent,
    UpdateNodeStatusCardComponent,
    UpgradeTranscriptModalComponent,
];

/**
 * Module containing all upgrade stuff (angular only).
 */
@NgModule({
    declarations: [
        ...updateComponents,
    ],
    exports: [
        RecentUpdatesListComponent,
        UpdateCardAboutComponent,
        UpgradeTranscriptModalComponent,
    ],
    imports: [
        ClrDatagridModule,
        ClrInputModule,
        ClrSpinnerModule,
        ClrTextareaModule,
        CommonModule,
        FormsModule,
        DialogModule,
        SharedModule,
        VIPModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class UpdateModule { }
