/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { severityHash, typeHash } from '../../constants/case.constant';

const { SEVERITY_4 } = severityHash;
const { BUG } = typeHash;

const caseCollectionFactory = (Collection, Case, initialDataService) => {
    /**
     * @class
     * @name module:collections/CaseCollection
     * @extends module:avi/dataModel.Collection
     * @desc collection of module:items/Case
     */
    class CaseCollection extends Collection {
        constructor(oArgs) {
            const newoArgs = {
                ...oArgs,
                permissionName: 'PERMISSION_CONTROLLER',
            };

            super(newoArgs);
        }
    }

    const serverDefaultsOverride = {
        version: initialDataService.controllerVersion,
        severity: SEVERITY_4,
        type: BUG,
    };

    Object.assign(CaseCollection.prototype, {
        objectName_: 'albservices/case',
        itemClass_: Case,
        defaultDataSources_: 'list',
        windowElement_: 'case-modal',
        serverDefaultsOverride_: serverDefaultsOverride,
        allDataSources_: {
            list: {
                source: 'ListCollDataSource',
                transformer: 'ListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
    });

    return CaseCollection;
};

caseCollectionFactory.$inject = [
    'Collection',
    'Case',
    'initialDataService',
];

/**
 * @ngdoc service
 * @alias CaseCollection
 * @author Ram Pal, Ashish Verma
 * @description  Collection of {Case} items.
 */
angular.module('avi/cportal')
    .factory('CaseCollection', caseCollectionFactory);
