/**
 * @module Upgrade
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/utils/ajsDependency';
import {
    dropdownMenuRollbackOptionOptions,
    Image,
    ImageCollection,
    segUpdateImageSelectionModalOptions,
    segUpdatePageGridExpanderOptions,
    segUpdatePageGridFieldStatusOptions,
    segUpdatePageOptions,
    selectedSegListOptions,
    softwarePageOptions,
    softwareUploadCancelConfirmationOptions,
    systemUpdateOptions,
    updateCardOptions,
    updateCardProgressOptions,
    updateModalOptions,
    UpgradeNode,
    UpgradeNodeCollection,
    UpgradeNodeCollectionDataSource,
    UpgradeService,
    upgradeTranscriptModalContainerComponentOptions,
    withUpgradeStatusMixin,
} from '.';

const upgradeModule = angular.module('avi/upgrade');

const services = [
    {
        service: UpgradeService,
        name: 'upgradeService',
    },
];

const components = [
    {
        name: 'segUpdatePage',
        options: segUpdatePageOptions,
    }, {
        name: 'segUpdateImageSelectionModal',
        options: segUpdateImageSelectionModalOptions,
    }, {
        name: 'selectedSegList',
        options: selectedSegListOptions,
    }, {
        name: 'segUpdatePageGridExpander',
        options: segUpdatePageGridExpanderOptions,
    }, {
        name: 'segUpdatePageGridFieldStatus',
        options: segUpdatePageGridFieldStatusOptions,
    }, {
        name: 'softwarePage',
        options: softwarePageOptions,
    }, {
        name: 'softwareUploadCancelConfirmation',
        options: softwareUploadCancelConfirmationOptions,
    }, {
        name: 'systemUpdate',
        options: systemUpdateOptions,
    }, {
        name: 'dropdownMenuRollbackOption',
        options: dropdownMenuRollbackOptionOptions,
    }, {
        name: 'updateCard',
        options: updateCardOptions,
    }, {
        name: 'updateCardProgress',
        options: updateCardProgressOptions,
    }, {
        name: 'updateModal',
        options: updateModalOptions,
    }, {
        name: 'upgradeTranscriptModalContainer',
        options: upgradeTranscriptModalContainerComponentOptions,
    },
];

const factories = [
    {
        factory: Image,
        name: 'Image',
    }, {
        factory: ImageCollection,
        name: 'ImageCollection',
    }, {
        factory: UpgradeNode,
        name: 'UpgradeNode',
    }, {
        factory: UpgradeNodeCollection,
        name: 'UpgradeNodeCollection',
    }, {
        factory: UpgradeNodeCollectionDataSource,
        name: 'UpgradeNodeCollectionDataSource',
    },
];

const mixins = [
    {
        mixin: withUpgradeStatusMixin,
        name: 'withUpgradeStatusMixin',
    },
];

services.forEach(({ name, service }) => {
    initAjsDependency(upgradeModule, 'service', name, service);
});

factories.forEach(({ name, factory }) => {
    initAjsDependency(upgradeModule, 'factory', name, factory);
});

mixins.forEach(({ name, mixin }) => {
    initAjsDependency(upgradeModule, 'factory', name, mixin);
});

components.forEach(({ name, options }) => upgradeModule.component(name, options));
