/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import './case-modal.component.less';

import {
    typeHash,
    severityHash,
    environmentHash,
} from '../../../../js/constants/case.constant';

import { MYVMWARE } from '../../../../modules/cportal/constants/cportal.constants';

const {
    SEVERITY_1,
    SEVERITY_2,
    SEVERITY_3,
    SEVERITY_4,
    SEVERITY_5,
} = severityHash;

const {
    CONFIGURATION_HELP,
    QUESTION,
    FEATURE_REQUEST,
} = typeHash;

/**
 * @typedef {Object} CaseComment
 * @memberOf module:avi/cportal
 * @property {boolean} support - true if comment is by support person
 * @property {Object} created_by - user info who have created case.
 * @property {string} created_date - comment created date
 * @property {string} comment_body - comment body
 */

/**
 * @typedef {Object} Case
 * @memberOf module:avi/cportal
 * @property {string} last_modified_date
 * @property {string} description
 * @property {string} severity
 * @property {string} case_number
 * @property {string} environment
 * @property {string} version
 * @property {string} created_date
 * @property {string} subject
 * @property {string} id
 */

/**
 * @constructor
 * @memberOf module:avi/cportal
 * @desc Modal component for creating/editing a Case
 * @mixes module:avi/cportal.caseModalBindings
 * @see {@link module:avi/cportal.caseModalComponent}
 */
class CaseModalController {
    /**
     * Severity list for case.
     * @type {DropDownOption[]}
     */
    static severitiesList = Object.values(severityHash).map(severity => {
        const value = severity;
        let label = '';

        switch (value) {
            case SEVERITY_1:
                label = `${value} (Critical)`;
                break;

            case SEVERITY_2:
                label = `${value} (High)`;
                break;

            case SEVERITY_3:
                label = `${value} (Medium)`;
                break;

            case SEVERITY_4:
                label = `${value} (Low)`;
                break;

            case SEVERITY_5:
                label = `${value} (Default)`;
                break;
        }

        return {
            value,
            label,
        };
    });

    /**
     * List of case type.
     * @type {string[]}
     */
    static typesList = Object.values(typeHash);

    /**
     * List of case environments.
     * @type {string[]}
     */
    static environments = Object.values(environmentHash);

    constructor(
        dropDownUtils,
        systemInfoService,
        CportalService,
    ) {
        /**
         * @type {DropDownOption[]}
         */
        this.severities = CaseModalController.severitiesList
            .map(({ value, label }) => dropDownUtils.createOption(
                value,
                label,
            ));

        this.systemInfoService_ = systemInfoService;
        this.cportalService_ = CportalService;
        this.dropDownUtils_ = dropDownUtils;

        /**
         * @type {DropDownOption[]}
         */
        this.types = CaseModalController.typesList.map(value => dropDownUtils.createOption(value));

        /**
         * @type {DropDownOption[]}
         */
        this.environments = CaseModalController.environments
            .map(value => dropDownUtils.createOption(value));

        /**
         * Flag to show and hide severity dropdown.
         * when selected type is Configuration Help, Question or Feature Request
         * we will hide severity dropdown.
         * @type {boolean}
         */
        this.displaySeverity = true;

        /**
         * Flag to show comment once they are loaded
         * @type {boolean}
         */
        this.loadingComments = false;

        /**
         * Comments List for the case.
         * @type {Array.<module:avi/cportal.CaseComment>}
         */
        this.comments = [];

        /**
         * Flag to show Disable contact dropdown until option value is created.
         * @type {boolean}
         */
        this.userDataLoading = false;

        /**
         * Portal User Options
         * @type {DropDownOption[]}
         */
        this.portalUserOptions = [];

        /**
         * Anchor link titile and class name object
         * @type {Array.<module:avi/app.AnchorLink>}
         */
        this.anchorLinks = [
            {
                title: 'Case',
                className: 'case-modal__anchor--content',
            },
        ];

        /**
          * Portal mode defines the type of portal to which controller is connected.
          * @type {string}
          */
        this.portalMode = '';

        /**
         * Create option function.
         * @returns {DropDownOption}
         */
        this.createPortalUserOption = ({ email, name }) => this.dropDownUtils_
            .createOption(email, `${name} (${email})`);
    }

    /**
     * @override
     */
    $onInit() {
        this.updateSeverity_();

        const config = this.editable.getConfig();

        if (config.id) {
            const { email, name } = config.contact_info;

            this.portalUserOptions.push(this.createPortalUserOption({
                email,
                name,
            }));

            //If case is open in edit mode then we have Activity section also
            this.anchorLinks.push({
                title: 'Activity',
                className: 'case-modal__anchor--case-activity',
            });

            this.getCaseComments_();
        } else {
            this.setDefaultContactEmail_();
            this.setPortalUsersList_();
        }
    }

    /**
     * Returns true if case is closed
     * @returns {boolean}
     */
    isClosed() {
        return this.editable.isClosed();
    }

    /**
     * Set Portal User List
     * @protected
     */
    setPortalUsersList_() {
        this.userDataLoading = true;

        return this.cportalService_.getUsersList()
            .then(portalUsers => {
                // creating user contact list.
                this.portalUserOptions = portalUsers.results
                    .filter(user => user.email !== undefined)
                    .map(this.createPortalUserOption);
            })
            .catch(error => {
                this.error = error;
            })
            .finally(() => {
                this.userDataLoading = false;
            });
    }

    /**
     * Set default contact email
     * @protected
     */
    setDefaultContactEmail_() {
        return this.cportalService_.getPortalInfo()
            .then(portalInfo => {
                const {
                    asset_contact: assetContact,
                    mode: portalMode,
                } = portalInfo;

                this.setPortalMode_(portalMode);

                //set default email for contact
                if (assetContact) {
                    this.editable.getConfig().email = assetContact.email;
                }
            })
            .catch(error => {
                this.error = error;
            });
    }

    /**
      * Set portal mode
      * @param {string} portalMode - Portal mode.
      * @protected
      */
    setPortalMode_(portalMode) {
        this.portalMode = portalMode;
    }

    /**
     * Check if the connected portal mode is MYVMWARE.
     * TODO: replace this with constant in jira integration changes (AV-94722);
     * @returns {boolean}
     */
    isPortalMyvmware() {
        return this.portalMode === MYVMWARE;
    }

    /**
     * Get Case comments
     * @protected
     */
    getCaseComments_() {
        this.loadingComments = true;

        this.editable.loadCaseComments()
            .then(data => {
                this.comments = data.results;
            })
            .catch(error => {
                this.error = error;
            })
            .finally(() => {
                this.loadingComments = false;
            });
    }

    /**
     * Handle type change.
     */
    handleTypeChange() {
        const config = this.editable.getConfig();

        delete config.fr_use_cases;
        delete config.fr_business_justification;
        delete config.fr_current_solution;

        this.updateSeverity_();
    }

    /**
     * Update the severity dropdown value.
     * @param {Object} Case Case Item instance.
     */
    updateSeverity_() {
        const config = this.editable.getConfig();
        const { type } = config;

        if (
            type === CONFIGURATION_HELP ||
            type === QUESTION ||
            type === FEATURE_REQUEST
        ) {
            this.displaySeverity = false;
            config.severity = SEVERITY_4;
        } else {
            this.displaySeverity = true;
        }
    }
}

CaseModalController.$inject = [
    'dropDownUtils',
    'systemInfoService',
    'CportalService',
];

/**
  * @mixin caseModalBindings
  * @memberOf module:avi/cportal
  * @property {module:avi/cportal.Case} editable
  */
const caseModalBindings = {
    editable: '<',
};

/**
 * @ngdoc component
 * @alias caseModalComponent
 * @property {module:avi/cportal.CaseModalController} controller
 * @property {module:avi/cportal.caseModalBindings} bindings
 * @author Ashish Verma
 */
angular.module('avi/cportal').component('caseModal', {
    bindings: caseModalBindings,
    controller: CaseModalController,
    templateUrl: 'src/components/modals/portal/case/case-modal.component.html',
});
