/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import './avi-dropdown-menu.component.less';

/**
 * Component for the AviDropdown menu. Contains an optional search input and transcluded dropdown
 * options.
 * @author alextsg
 */
@Component({
    selector: 'avi-dropdown-menu',
    templateUrl: './avi-dropdown-menu.component.html',
})
export class AviDropdownMenuComponent implements AfterViewInit {
    /**
     * If true, hides the search input.
     */
    @Input()
    public hideSearch = false;

    /**
     * Search input model.
     */
    @Input()
    public searchTerm = '';

    /**
     * Called when user types in the search input.
     */
    @Output()
    public onSearch = new EventEmitter();

    /**
     * Search input field.
     */
    @ViewChild('searchInput')
    private searchInputRef: ElementRef;

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        setTimeout(() => this.searchInputRef.nativeElement.focus());
    }

    /**
     * Handler called when the user types in the search input.
     */
    public handleSearch(event: Event): void {
        const { target } = event;
        const { value: searchTerm } = target as HTMLInputElement;

        this.onSearch.emit(searchTerm);
    }
}
