/**
 * @module SharedModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    AfterViewInit,
    Component,
    forwardRef,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    ControlValueAccessor,
    NgForm,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { pick } from 'underscore';

import { IAviDataGridConfig } from 'ng/shared/components';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './rbac-label-grid.component.l10n';

import './rbac-label-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export interface IKeyValues {
    key?: string;
    valuesString?: '';
}

/**
 * @description Generic grid component for displaying object RBAC labels.
 * @author Zhiqian Liu
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RbacLabelGridComponent),
        },
    ],
    selector: 'rbac-label-grid',
    templateUrl: './rbac-label-grid.component.html',
})
export class RbacLabelGridComponent implements AfterViewInit, ControlValueAccessor {
    /**
     * Datagrid template for input field of the key in the label pair.
     */
    @ViewChild('keyFieldTemplateRef')
    public keyFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for input field of the value in the label pair.
     */
    @ViewChild('valueFieldTemplateRef')
    public valueFieldTemplateRef: TemplateRef<HTMLElement>;

    @ViewChild('form')
    public form: NgForm;

    /**
     * DataGrid config for the object list.
     */
    public listDataGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * State to indicate if there is key duplications across the list.
     */
    public keyDuplicated = false;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: IKeyValues[];

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for the modelValue.
     */
    public get rbacEntryList(): IKeyValues[] {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue. Meantime register model value change.
     */
    public set rbacEntryList(val: IKeyValues[]) {
        this.modelValue = val;
        this.onChange(this.modelValue);
        this.onTouched();
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { form } = this;

        // Read keyDuplicated errors from input controls by watching FormGroup status changes since
        // FormGroup instance of the component holds the valid/status state inferred from children
        // FormControls but it doesn't have merged errors propagated from them.
        // (There's a hanging issue filed to the Angular team addressing the above from 2016:
        // https://github.com/angular/angular/issues/10530).
        // Watching on status change can avoid applying expensive iterations checking duplications
        // on every digest loop.
        form.statusChanges.subscribe((): void => {
            const keyControls = pick(form.controls, (_, key) => {
                return key.startsWith('rbacKey_');
            });

            this.keyDuplicated = Object.keys(keyControls).some(
                key => keyControls[key].errors?.keyDuplicated || false,
            );
        });

        const { l10nService } = this;

        this.listDataGridConfig = {
            getRowId: (index: number): number => index,
            fields: [
                {
                    label: l10nService.getMessage(this.l10nKeys.keyColumnLabel),
                    id: 'key',
                    templateRef: this.keyFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(this.l10nKeys.valuesColumnLabel),
                    id: 'values',
                    templateRef: this.valueFieldTemplateRef,
                },
            ],
        };
    }

    /**
     * Add a new RBAC entry.
     */
    public addEntry(): void {
        this.rbacEntryList = this.rbacEntryList.concat({
            key: '',
            valuesString: '',
        });
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
     */

    /**
     * Write the modelValue when programmatic changes come.
     */
    public writeValue(rbacEntryList: IKeyValues[]): void {
        this.modelValue = rbacEntryList;
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: IKeyValues[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: IKeyValues[]): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /**
     * Delete a group of RBAC entries from the list.
     */
    private deleteEntries(entries: IKeyValues[]): void {
        entries.forEach(entry => this.deleteEntry(entry));
    }

    /**
     * Delete a single RBAC entry from the list.
     */
    private deleteEntry(entry: IKeyValues): void {
        const copiedEntryList = this.rbacEntryList.concat();
        const index = copiedEntryList.indexOf(entry);

        if (index !== -1) {
            copiedEntryList.splice(index, 1);
        }

        this.rbacEntryList = copiedEntryList;
    }
}
