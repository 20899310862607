/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * @ngdoc factory
 * @name  NetworkSecurityPolicy
 * @description  NetworkSecurityPolicy item.
 */
function NetworkSecurityPolicyFactory(Item, policyRuleSort) {
    class NetworkSecurityPolicy extends Item {
        constructor(args = {}) {
            super(args);

            this.matches = args && args.matches || [
                'client_ip',
                'vs_port',
                'microservice',
                'ip_reputation_type',
            ];

            this.actions = args && args.actions || ['action'];

            this.data.config = angular.extend(
                { rules: [] },
                this.getDefaultConfig_(),
                args.data && args.data.config || null,
            );

            const { data } = args;

            if (angular.isObject(data)) {
                this.updateItemData(data);
            } else {
                this.transformAfterLoad_();
            }
        }

        /**
         * Returns true if the NetworkSecurityPolicy contain any rules.
         * @public
         */
        hasRules() {
            const rules = this.getRules();

            return angular.isArray(rules) && rules.length > 0;
        }

        /**
         * Returns a list of policy rules.
         * @public
         */
        getRules() {
            return this.getConfig().rules;
        }

        /**
         * Getter function used in ordered-grid component. Return a reference to config#rules.
         * @return {Object[]} rows of data..
         */
        get rows() {
            return this.getRules();
        }

        /**
         * Enables a set of rules.
         * @param {PolicyRule[]} rules
         */
        enable(rules) {
            rules.forEach(rule => rule.enable = true);
        }

        /**
         * Disables a set of rules.
         * @param {PolicyRule[]} rules
         */
        disable(rules) {
            rules.forEach(rule => rule.enable = false);
        }

        /**
         * Deletes a set of rules.
         * @param {PolicyRule[]|PolicyRule} rules
         */
        delete(rules) {
            rules = angular.isArray(rules) ? rules : [rules];
            this.getConfig().rules = this.rows.filter(rule => rules.indexOf(rule) === -1);
        }

        /**
         * Returns true if the indicies of the rules are valid for re-ordering.
         * @param {number} index1 - Index of rule within rules, not the property.
         * @param {number} index2 - Index of rule within rules, not the property.
         */
        indiciesAreValid(index1, index2) {
            const max = this.rows.length - 1;
            const min = 0;

            return index1 !== index2 &&
                    Math.max(index1, index2) <= max && Math.min(index1, index2) >= min;
        }

        /**
         * Moves rule to a new index.
         * @param {PolicyRule} rule
         * @param {Object} data - Contains position and index properties.
         * @param {string} data.position - New position relative to the new index, 'above' or
         *     'below'.
         * @param {number} data.index - New index to be moved to.
         */
        moveRule(rule, data) {
            const oldIndex = this.rows.indexOf(rule);
            let newIndex = _.findIndex(this.rows, row => row.index === data.index);

            if (data.position === 'below') {
                newIndex++;
            }

            if (oldIndex < newIndex) {
                newIndex--;
            }

            this.moveToIndex(oldIndex, newIndex);
        }

        /**
         * Moves rule to a new index. All rules in-between need to have their indices shifted.
         * @param {number} oldIndex - Index of the original position of the rule.
         * @param {number} newIndex - Index of the new position.
         */
        moveToIndex(oldIndex, newIndex) {
            if (!this.indiciesAreValid(oldIndex, newIndex)) {
                return;
            }

            // newIndex moves towards the direction of oldIndex
            const increment = oldIndex < newIndex ? -1 : 1;

            while (oldIndex !== newIndex) {
                this.swapRule(oldIndex, newIndex);
                newIndex += increment;
            }
        }

        /**
         * Handler for drag-and-drop event.
         * @param {number} oldIndex - Index of the original position of the rule.
         * @param {number} newIndex - Index of the new position.
         */
        handleDragAndDropChange(oldIndex, newIndex) {
            this.moveToIndex(oldIndex, newIndex);
        }

        /**
         * Given two indices of rules, swaps positions in the rules array along with the index
         * property in the rule.
         * @param {number} oldIndex
         * @param {number} newIndex
         */
        swapRule(oldIndex, newIndex) {
            const oldRule = this.rows[oldIndex];
            const newRule = this.rows[newIndex];

            this.rows[oldIndex] = this.rows[newIndex];
            this.rows[newIndex] = oldRule;
            [oldRule.index, newRule.index] = [newRule.index, oldRule.index];
        }

        /**
         * Checks whether network security rule is set accordingly to Secure App rules.
         * @param {Object} rule
         * @param {string} type - Allow or deny.
         * @returns {boolean} - True when it has the expected configuration.
         * @protected
         */
        static _checkSecureAppRule(rule, type) {
            let res = false;

            switch (type) {
                case 'allow':
                    res = 'match' in rule && 'microservice' in rule.match &&
                            rule.match.microservice.match_criteria === 'IS_IN';
                    break;

                case 'deny':
                    res = 'match' in rule && 'client_ip' in rule.match &&
                            rule.match.client_ip.match_criteria === 'IS_IN' &&
                            'prefixes' in rule.match.client_ip &&
                            rule.match.client_ip.prefixes.length === 1 &&
                            rule.match.client_ip.prefixes[0].mask === 0 &&
                            'ip_addr' in rule.match.client_ip.prefixes[0] &&
                            'addr' in rule.match.client_ip.prefixes[0].ip_addr &&
                            rule.match.client_ip.prefixes[0].ip_addr.addr === '0.0.0.0';
                    break;
            }

            return res;
        }

        /**
         * Checks if this Network Security Policy has rules called 'secure application'.
         * @param {boolean=} isEnabled - When true we should check verify rules to be enabled.
         * @param {boolean=} canBePartial - When true we will return true even if only one of
         *     two rules has been found.
         * @returns {boolean} - True if rules (or rule when canBePartial is true) with certain
         *     {@link NetworkSecurityPolicy.secAppRuleIndexes index values and properties}
         *     are present for this policy.
         */
        hasSecureAppRule(isEnabled, canBePartial) {
            let res = false;

            if (this.hasRules()) {
                isEnabled = !!isEnabled;
                canBePartial = !!canBePartial;

                const allowFingerprint = {
                    index: NetworkSecurityPolicy.secAppRuleIndexes[0],
                    action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_ALLOW',
                };

                const denyFingerprint = {
                    index: NetworkSecurityPolicy.secAppRuleIndexes[1],
                    action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_DENY',
                };

                const rules = this.getRules();
                let denyRule;
                let allowRule;

                if (isEnabled) {
                    allowFingerprint.enabled = true;
                    denyFingerprint.enabled = true;
                }

                allowRule = _.findWhere(rules, allowFingerprint);

                if (allowRule) {
                    allowRule = NetworkSecurityPolicy._checkSecureAppRule(allowRule, 'allow');
                }

                denyRule = _.findWhere(rules, denyFingerprint);

                if (denyRule) {
                    denyRule = NetworkSecurityPolicy._checkSecureAppRule(denyRule, 'deny');
                }

                res = !canBePartial && denyRule && allowRule ||
                        canBePartial && (denyRule || allowRule);
            }

            return res;
        }

        /**
         * Checks whether we have network security rules other then SecureApp created through UI
         * Modal window with certain indexes and property values.
         * @returns {boolean} True when we have other rules.
         */
        hasRuleBesidesSecureApp() {
            let res = false;//false when no rules at all

            if (this.hasRules()) {
                const rules = this.getRules();

                if (rules.length !== 2) {
                    res = true;
                } else { //these two must be secureApp ones to return false as result
                    res = !this.hasSecureAppRule();
                }
            }

            return res;
        }

        /**
         * Removes specific Network Security Policy rules called 'secure app'. Before removal
         * will check if at least one of them is present for this Network Security Policy and
         * return its uuid.
         * @returns {string|undefined} - Undefined when no security rules are defined.
         */
        removeSecureAppRule() {
            let msGroupUuid;

            if (this.hasSecureAppRule(false, true)) {
                const rules = this.getRules();

                const allowIndex = _.findIndex(rules, function(r) {
                    return r.index === NetworkSecurityPolicy.secAppRuleIndexes[0];
                }, this);

                if (allowIndex > -1 &&
                        NetworkSecurityPolicy._checkSecureAppRule(rules[allowIndex], 'allow')) {
                    msGroupUuid = 'match' in rules[allowIndex] &&
                            'microservice' in rules[allowIndex].match &&
                            rules[allowIndex].match.microservice.group_ref;

                    rules.splice(allowIndex, 1);
                }

                const denyIndex = _.findIndex(rules, function(r) {
                    return r.index === NetworkSecurityPolicy.secAppRuleIndexes[1];
                }, this);

                if (denyIndex > -1 &&
                        NetworkSecurityPolicy._checkSecureAppRule(rules[denyIndex], 'deny')) {
                    rules.splice(denyIndex, 1);
                }
            }

            return msGroupUuid;
        }

        /** @override */
        updateItemData(newData) {
            const gotUpdated = super.updateItemData(newData);

            this.transformAfterLoad_();

            return gotUpdated;
        }

        /**
         * Sorting rules by index.
         * @protected
         */
        transformAfterLoad_() {
            const config = this.getConfig();

            if (!('rules' in config)) {
                config['rules'] = [];
            }

            this.getRules().sort(policyRuleSort);
        }
    }

    angular.extend(NetworkSecurityPolicy.prototype, {
        objectName: 'networksecuritypolicy',
    });

    /**
     * Two indexes for the Secure App Network Security Policy rules.
     * Two numeric values for allow and deny rules.
     * @type {Array<number>}
     * @private
     * @static
     */
    NetworkSecurityPolicy.secAppRuleIndexes = [9990, 9999];//[allow, deny]

    return NetworkSecurityPolicy;
}

NetworkSecurityPolicyFactory.$inject = [
    'Item',
    'policyRuleSort',
];

angular.module('aviApp').factory('NetworkSecurityPolicy', NetworkSecurityPolicyFactory);
