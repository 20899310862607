/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
 * Module for AVI Pool related factories & components.
 * @module avi/pool
 */

const poolFactory = ($q, UpdatableItem, Server, ConfiguredNetwork, poolFailActionService) => {
    /**
     * @constructor
     * @memberOf module:avi/pool
     * @extends module:avi/dataModel.UpdatableItem
     * @param {Object=} oArgs
     * @desc JS instance for api/pool.
     **/
    class Pool extends UpdatableItem {
        constructor(args = {}) {
            super(args);

            this.apiResponseCache = {
                autoscaleGroupServers: {},
            };

            const {
                vs,
                vsId,
            } = args;

            if (vs) {
                this.vs_ = vs;
                this.vsId_ = vs.id;
            } else if (vsId) {
                this.vsId_ = vsId;
            }
        }

        /** @override */
        destroy() {
            const gotDestroyed = super.destroy();

            if (gotDestroyed) {
                this.vs_ = null;
                this.apiResponseCache = null;
            }

            return gotDestroyed;
        }

        /**
         * Returns the cloud_ref configured on the PoolGroup.
         * @return {string}
         */
        getCloudRef() {
            return this.getConfig()?.cloud_ref;
        }

        /**
         * Returns the VRF context ref configured for this pool.
         * @returns {string}
         */
        getVRFContextRef() {
            return this.getConfig()['vrf_ref'] || '';
        }

        /** @override */
        getMetricsTuple() {
            return {
                entity_uuid: '*',
                aggregate_entity: true,
                pool_uuid: this.id,
            };
        }

        /**
         * Returns Pool's config default_server_port property.
         * @returns {Number|undefined} Undefined when not ready.
         * @public
         */
        getDefaultServerPort() {
            return this.data && this.data.config && this.data.config['default_server_port'] ||
                undefined;
        }

        removeServerDuplicates(data) {
            data.servers = _.uniq(data.servers, false, Server.getServerUuid);
        }

        /** @override */
        transformAfterLoad() {
            this.transformAfterLoad_(this.getConfig());

            const { markers } = this.getConfig();

            // work-around to make RBAC pairs of key-values to key-valuesString for read only
            // purpose
            if (markers && markers.length) {
                this.rbacLabels = markers.map(({ key, values }) => {
                    return {
                        key,
                        valuesString: values.join(', '),
                    };
                });
            } else {
                this.rbacLabels = [];
            }
        }

        /**
         * Does actual config transformation.
         * @param {PoolConfig} config
         * @returns {PoolConfig}
         * @protected
         */
        transformAfterLoad_(config) {
            if ('servers' in config) {
                const {
                    servers,
                    default_server_port: defaultServerPort,
                } = config;

                servers.forEach(server => {
                    server['default_server_port'] = defaultServerPort;
                    server.uuid = Server.getServerUuid(server);
                });
            }

            return config;
        }

        /** @override */
        transformDataAfterSave({ data: config }) {
            return this.transformAfterLoad_(config);
        }

        beforeEdit() {
            const promises = [];
            const config = this.getConfig();

            config.fail_action = poolFailActionService.beforeEdit(config.fail_action);

            if (config.servers && (this.autoPopulatedServers() || this.hasNSXSecurityGroup())) {
                config.servers.forEach(server => {
                    server.hostname = server.ip.addr;
                    server.ratio = 1;
                });
            }

            // If server network_ref is present but name is not (ex. OpenStack or AWS network),
            //  we need to make a request to retrieve the name.
            if (Array.isArray(config.servers)) {
                config.servers.forEach(server => {
                    if (!angular.isUndefined(server.nw_ref) && !server.nw_ref.name()) {
                        const network = new ConfiguredNetwork({
                            id: server.nw_ref.slug(),
                            params: {
                                cloud_uuid: this.getCloudRef().slug(),
                            },
                        });

                        const loadNetwork = network.load()
                            .then(() => server.nw_ref = network.getRef())
                            .finally(() => {
                                network.destroy();
                            });

                        promises.push(loadNetwork);
                    }
                });
            } else {
                config.servers = [];
            }

            // initiate the RBAC label list
            if (!Array.isArray(config.markers)) {
                // TODO: add empty marker list and delete this.rbacLabels
                // config.markers = [];
                this.rbacLabels = [];
            }

            if (!config.health_monitor_refs) {
                config.health_monitor_refs = [];
            }

            config.nsx_securitygroup = config.nsx_securitygroup || [];

            // initiate the RBAC label list
            if (!Array.isArray(config.markers)) {
                config.markers = [];
            }

            $q.all(promises).finally(this.setPristine.bind(this));
        }

        dataToSave() {
            const config = angular.copy(this.getConfig());

            this.removeServerDuplicates(config);

            const
                networksHash = {},
                networks = [];

            config.servers.forEach(server => {
                if (server.port === null) {
                    delete server.port;
                }

                const netId = server.nw_ref;

                if (netId && !(netId in networksHash)) {
                    networks.push(netId);
                    networksHash[netId] = true;
                }

                //for servers provided by ServerCollection
                delete server.uuid;
                delete server.default_server_port;
            });

            //vCenter only when servers added by network
            if (networks.length) {
                config.networks = networks.map(netId => ({ network_ref: netId }));
            } else {
                delete config.networks;
            }

            if (!config.servers.length) {
                delete config.servers;
            }

            // Remove falsy values (null/'') from health_monitor_refs
            const { health_monitor_refs: monitorList } = config;

            config.health_monitor_refs = _.compact(monitorList);

            config.fail_action = poolFailActionService.dataToSave(config.fail_action);

            if (config.max_concurrent_connections_per_server === null) {
                delete config.max_concurrent_connections_per_server;
            }

            if (config.ab_pool && !config.ab_pool.pool_ref) {
                delete config.ab_pool;
            }

            const { markers } = config;

            if (markers) {
                // filter out RBAC entries with an empty key
                config.markers = markers.filter(({ key }) => key);

                // delete empty RABC label list
                if (markers.length === 0) {
                    delete config.markers;
                }
            }

            return config;
        }

        /**
         * This function emulates a call to the server and giving the promise
         * It is making multiple calls periodically to continuously update the object
         * that was delivered into resolve
         *
         * @param fields - Array of fields that has to be loaded
         * @return {ng.$q.promise} - Promise
         */
        loadRequest(fields) {
            const requests = [
                this.loadConfig(fields),
                this.loadEventsAndAlerts(fields),
                this.loadMetrics(fields),
            ];

            return $q.all(requests);
        }

        /**
         * Is used by JS DeepDiff.diff function as a prefilter for editable objects comparison at
         * the modal windows. By default filters out AngularJS $$hashKey property only.
         * @param {Array<string>} path - Path (array of properties names) to the current properties
         *     being compared. Is partially broken in deepDiff v1.7.
         * @param {string} key - Property name of a compared value.
         * @return {boolean} - DeepDiff.diff won't go deeper or mark it as a difference when true is
         *     returned.
         * @override
         */
        modifiedDiffFilter(path, key) {
            const fullPath = path.join('/');

            let res;

            //default check for $$hashKey
            res = super.modifiedDiffFilter(path, key);

            //specific for the Pool modals: servers:[{index:}]
            if (!res) {
                res = key === 'index' && !fullPath;
            }

            return res;
        }

        /**
         * Returns true if pool is enabled.
         * @return {boolean}
         */
        isEnabled() {
            return this.getConfig().enabled;
        }

        /**
         * If the Pool state doesn't match the passed in state, toggle it and save the Pool.
         * @param {boolean} enable - True to enable Pool, false to disable.
         * @return {ng.$q.promise}
         */
        setEnabledState(enabled = true) {
            let promise = $q.when();

            if (this.isEnabled() !== enabled) {
                promise = this.patch({ replace: { enabled } });
            }

            return promise;
        }

        /**
         * Makes a request to get addresses for a security group.
         * @return {ng.$q.promise}
         */
        getSecurityGroupAddresses() {
            const { nsx_securitygroup: nsxSecurityGroup } = this.getConfig();

            if (!angular.isArray(nsxSecurityGroup) || !nsxSecurityGroup.length) {
                return $q.when([]);
            }

            const cloudId = this.getCloudRef().slug();
            const sg = nsxSecurityGroup[0];
            const api = `/api/nsx/securitygroup/ips/?cloud_uuid=${cloudId}&securitygroup=${sg}`;

            this.errors = null;

            return this.request('GET', api)
                .then(({ data: ipList }) => {
                    return Array.isArray(ipList) ? ipList.map(({ sg_ip: ip }) => ({ ip })) : [];
                })
                .catch(({ data }) => {
                    this.errors = data;

                    return [];
                });
        }

        /**
         * Returns a list of server AutoScale groups.
         * @return {ng.$q.promise}
         */
        getAutoscaleGroups() {
            const { cloud_ref: cloudRef } = this.getConfig();
            const cloudId = cloudRef.slug();

            this.busy = true;
            this.errors = null;

            return this.request('GET', `/api/cloud/${cloudId}/autoscalegroup`)
                .then(({ data }) => data.results)
                .catch(() => [])
                .finally(() => this.busy = false);
        }

        /**
         * Returns a list of servers belonging to an AutoScale group.
         * @param {string} name - Name of the AutoScale group.
         */
        getAutoscaleGroupServers() {
            const {
                cloud_ref: cloudRef,
                external_autoscale_groups: externalAutoscaleGroups,
            } = this.getConfig();
            const cloudId = cloudRef.slug();
            const names = externalAutoscaleGroups || [];

            const promises = names.map(name => {
                if (name in this.apiResponseCache.autoscaleGroupServers) {
                    return $q.when(this.apiResponseCache.autoscaleGroupServers[name]);
                } else {
                    return this.request(
                        'GET',
                        `/api/cloud/${cloudId}/autoscalegroup/${name}/servers`,
                    ).then(({ data }) => {
                        const servers = data && data.results || [];

                        this.apiResponseCache.autoscaleGroupServers[name] = servers;

                        return servers;
                    }).catch(({ data }) => {
                        this.errors = data;

                        return [];
                    });
                }
            });

            this.busy = true;
            this.errors = null;

            return $q.all(promises)
                .then(responses => {
                    return responses.reduce((acc, servers) => {
                        acc.push(...servers);

                        return acc;
                    }, []);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Returns a list of APIC EPGs.
         * @return {ng.$q.promise}
         * @public
         */
        getApicEpgs() {
            this.errors = null;

            return this.request('GET', '/api/apicepgs/all')
                .then(({ data }) => data)
                .catch(({ data }) => {
                    this.errors = data;

                    return [];
                });
        }

        /**
         * Back-end can populate servers list by referenced object. Here we check whether this is a
         * case.
         * @returns {boolean}
         * @public
         */
        autoPopulatedServers() {
            const {
                ipaddrgroup_ref: ipAddrGroupRef,
                apic_epg_name: apicEpgName,
                external_autoscale_groups: externalAutoscaleGroups,
            } = this.getConfig();

            return !!(ipAddrGroupRef || apicEpgName || !_.isEmpty(externalAutoscaleGroups));
        }

        /**
         * Checks whether Pool is using NSX security group for servers placement.
         * @returns {boolean}
         * @public
         */
        hasNSXSecurityGroup() {
            const { nsx_securitygroup: nsxSecurityGroup } = this.getConfig();

            return !!(nsxSecurityGroup && nsxSecurityGroup[0]);
        }

        /**
         * Returns true if Pool has external AutoScale groups configured.
         * @return {boolean}
         */
        hasAutoscaleGroups() {
            const { external_autoscale_groups: externalAutoscaleGroups } = this.getConfig();

            return !_.isEmpty(externalAutoscaleGroups);
        }

        /**
         * @override
         */
        submit() {
            return super.submit()
                .then(() => this.apiResponseCache = { autoscaleGroupServers: {} });
        }

        /**
         * @override
         */
        dismiss(args) {
            super.dismiss(args);
            this.apiResponseCache = { autoscaleGroupServers: {} };
        }

        /**
         * Returns vs id this pool is used by. For pool details pages it is vsId from $stateParams,
         * otherwise first from the list provided by inventory API.
         * @returns {string}
         * @public
         */
        getVSId() {
            const { vsId_: vsId, vs_: vs } = this;

            if (vsId) {
                return vsId;
            }

            if (vs) {
                return vs.id;
            }

            const vsRefs = this.getVSRefs();

            return vsRefs.length ? vsRefs[0].slug() : '';
        }

        /** @override */
        getDetailsPageStateParams_() {
            return {
                poolId: this.id,
                vsId: this.getVSId(),
            };
        }

        /**
         * Checks whether there is at least one Server having VM id within this Pool.
         * @returns {boolean}
         * @public
         */
        hasServerWithVMId() {
            const { servers } = this.getConfig();

            return _.some(servers, Server.getServerVMId);
        }

        /**
         * Returns the list of VS refs this pool is used by. Provided by inventory API, hence
         * present only on Pools belonging to collection.
         * @return {string[]}
         * @public
         */
        getVSRefs() {
            const { virtualservices: list } = this.data;

            return list ? list.concat() : [];
        }

        /** @override */
        isEditable() {
            const { gslb_sp_enabled: gslbSpEnabled } = this.getConfig();

            return !gslbSpEnabled && super.isEditable();
        }

        /** @override */
        isProtected() {
            const { gslb_sp_enabled: gslbSpEnabled } = this.getConfig();

            return !!gslbSpEnabled || super.isProtected();
        }

        /** @override */
        hasCustomTimeFrameSettings() {
            const { vs_: vs } = this;

            return !this.collection && vs && vs.hasCustomTimeFrameSettings() || false;
        }

        /** @override */
        getCustomTimeFrameSettings(tfLabel) {
            if (this.hasCustomTimeFrameSettings()) {
                return this.vs_.getCustomTimeFrameSettings(tfLabel);
            }

            return null;
        }

        /**
         * Returns nested server config by its id.
         * @param {Server.id} serverId
         * @returns {ServerConfig|null}
         * @public
         */
        getServerConfigById(serverId) {
            const { servers } = this.getConfig();

            const serverConfig = _.findWhere(servers, { uuid: serverId });

            if (serverConfig) {
                return angular.copy(serverConfig);
            }

            return null;
        }

        /**
         * Returns a list of pool group names.
         * @returns {string[]}
         * @public
         */
        getPoolGroupNames() {
            // pool_group_refs comes from inventory API
            const { pool_group_refs: refs } = this.data;

            return refs ? refs.map(ref => ref.name()) : [];
        }

        /**
         * Updates pool fail action config based on type provided.
         * @param {PoolFailActionConfig#type|undefined} type - FailActionEnum type.
         */
        onFailActionTypeChange(type) {
            const config = this.getConfig();

            poolFailActionService.onTypeChange(config.fail_action, type);
        }
    }

    Pool.prototype.objectName = 'pool';
    Pool.prototype.windowElement = 'app-pool-create';
    Pool.prototype.detailsStateName_ =
        'authenticated.application.pool-detail';

    /**
     * Reference to vs this pool is used by. Set by PoolDetailController for pool-detail pages.
     * @type {Object|null}
     * @protected
     */
    Pool.prototype.vs_ = null;

    /**
     * Id of vs this pool is used by.
     * @type {string}
     * @protected
     */
    Pool.prototype.vsId_ = '';

    Pool.prototype.params = {
        include_name: true,
        join: ['application_persistence_profile_ref'].join(','),
    };

    return Pool;
};

poolFactory.$inject = [
    '$q',
    'UpdatableItem',
    'Server',
    'ConfiguredNetwork',
    'poolFailActionService',
];

angular.module('avi/pool').factory('Pool', poolFactory);
