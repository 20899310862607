/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

/**
* @typedef {(
*     'PROTOCOL_TYPE_TCP_PROXY'|
*     'PROTOCOL_TYPE_TCP_FAST_PATH'|
*     'PROTOCOL_TYPE_UDP_FAST_PATH'|
*     'PROTOCOL_TYPE_UDP_PROXY'
* )} NetProfileType
*/
function NetworkProfile(Item, schemaService, defaultValues) {
    class NetworkProfile extends Item {
        /** @override */
        beforeEdit() {
            const profile = this.getProfileConfig();
            const type = this.getType();
            const {
                PROTOCOL_TYPE_TCP_PROXY,
            } = NetworkProfile.typesHash;

            if (this.supportsDsrProfile()) {
                profile.useDSR = 'dsr_profile' in profile;
            }

            if (type === PROTOCOL_TYPE_TCP_PROXY) {
                profile.ip_dscp_pass = profile.ip_dscp === 2147483647;
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());
            const { useDSR } = this.getProfileConfig();
            const { profile } = config;
            const {
                PROTOCOL_TYPE_TCP_FAST_PATH,
                PROTOCOL_TYPE_TCP_PROXY,
                PROTOCOL_TYPE_UDP_FAST_PATH,
                PROTOCOL_TYPE_UDP_PROXY,
            } = NetworkProfile.typesHash;

            switch (this.getType()) {
                case PROTOCOL_TYPE_TCP_FAST_PATH:
                    delete profile.tcp_proxy_profile;
                    delete profile.udp_fast_path_profile;
                    delete profile.udp_proxy_profile;

                    if (!useDSR) {
                        delete profile.tcp_fast_path_profile.dsr_profile;
                    }

                    delete profile.tcp_fast_path_profile.useDSR;

                    break;

                case PROTOCOL_TYPE_TCP_PROXY:
                    delete profile.tcp_fast_path_profile;
                    delete profile.udp_fast_path_profile;
                    delete profile.udp_proxy_profile;
                    break;

                case PROTOCOL_TYPE_UDP_FAST_PATH:
                    delete profile.tcp_proxy_profile;
                    delete profile.tcp_fast_path_profile;
                    delete profile.udp_proxy_profile;

                    if (!useDSR) {
                        delete profile.udp_fast_path_profile.dsr_profile;
                    }

                    delete profile.udp_fast_path_profile.useDSR;

                    break;

                case PROTOCOL_TYPE_UDP_PROXY:
                    delete profile.tcp_proxy_profile;
                    delete profile.tcp_fast_path_profile;
                    delete profile.udp_fast_path_profile;
                    break;
            }

            return config;
        }

        /**
         * Returns network profile type.
         * @returns {string} - ProtocolType enum.
         * @public
         */
        getType() {
            const { profile } = this.getConfig();

            return profile['type'];
        }

        /**
         * Remove or assign default dsr config on Dsr checkbox select.
         */
        onDsrToggle() {
            const profileConfig = this.getProfileConfig();
            const type = this.getType();

            if (this.supportsDsrProfile()) {
                if (!profileConfig.useDSR) {
                    profileConfig.dsr_profile = undefined;
                } else {
                    const dsrDefaultConfig = defaultValues.getDefaultItemConfigByType('dsrprofile');

                    profileConfig.dsr_profile = dsrDefaultConfig;
                }
            } else {
                console.warn(`${type} profile do not support Dsr profile`);
            }
        }

        /**
         * Remove Dsr profile on type change.
         */
        removeDsrProfile() {
            const { profile } = this.getConfig();

            if (profile.udp_fast_path_profile) {
                delete profile.udp_fast_path_profile.dsr_profile;
            }

            if (profile.tcp_fast_path_profile) {
                delete profile.tcp_fast_path_profile.dsr_profile;
            }
        }

        /**
         * Check if Dsr profiles is supported or not.
         * @return {boolean}
         */
        supportsDsrProfile() {
            const networkProfileType = this.getType();
            const {
                PROTOCOL_TYPE_TCP_FAST_PATH,
                PROTOCOL_TYPE_UDP_FAST_PATH,
            } = NetworkProfile.typesHash;

            return networkProfileType === PROTOCOL_TYPE_TCP_FAST_PATH ||
                networkProfileType === PROTOCOL_TYPE_UDP_FAST_PATH;
        }

        /**
         * Get Profile config of selected type.
         * @returns {Object} - Selected profile config.
         */
        getProfileConfig() {
            const { profile } = this.getConfig();
            const type = this.getType();

            return profile[NetworkProfile.typeConfigHash[type]];
        }
    }

    angular.extend(NetworkProfile.prototype, {
        objectName: 'networkprofile',
        windowElement: 'prof-net-profile-create',
    });

    /**
     * List of all available network profile types. ProtocolType enum.
     * @type {Array<string>}
     * @static
     */
    NetworkProfile.types = schemaService.getEnumKeys('ProtocolType');

    /**
     * Hash of all available network profile types.
     * @type {Object.<NetProfileType, NetProfileType>}
     * @static
     */
    NetworkProfile.typesHash =
        Object.values(NetworkProfile.types)
            .reduce((acc, type) => {
                acc[type] = type;

                return acc;
            },
            {});

    /**
     * Hash of all available network profile type config.
     * @type {Object.<NetProfileType, string>}
     * @static
     */
    NetworkProfile.typeConfigHash = {
        PROTOCOL_TYPE_TCP_FAST_PATH: 'tcp_fast_path_profile',
        PROTOCOL_TYPE_TCP_PROXY: 'tcp_proxy_profile',
        PROTOCOL_TYPE_UDP_FAST_PATH: 'udp_fast_path_profile',
        PROTOCOL_TYPE_UDP_PROXY: 'udp_proxy_profile',
    };

    return NetworkProfile;
}

NetworkProfile.$inject = [
    'Item',
    'schemaService',
    'defaultValues',
];

/**
 * @ngdoc service
 * @name NetworkProfile
 */
angular.module('aviApp').factory('NetworkProfile', NetworkProfile);
