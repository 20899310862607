/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { sha1 } from 'object-hash';

const wafRuleConfigItemFactory = MessageItem => {
    /**
     * @alias module:config-item/WafRuleConfigItem
     * @private
     */
    class WafRuleConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                ...args,
                objectType: 'WafRule',
            };

            super(extendedArgs);

            /**
             * 'rule_id' property is not a real id, may have duplicates, hence we have to
             * introduce another id for easier handling in UI. We can't use 'index' as id
             * because user may re-oder rules and this would change ids which are supposed
             * to be immutable.
             * @type {string}
             * @protected
             */
            this.ruleId_ = sha1(Math.random());
        }

        /**
         * Returns true if this rule is enabled.
         * @return {boolean}
         */
        isEnabled() {
            return this.config.enable;
        }

        /**
         * Sets the enable flag of this rule.
         * @param {boolean=} enabled
         */
        setEnabledState(enabled = false) {
            this.config.enable = enabled;
        }

        /**
         * Returns the name of this rule. Name is either configured as a field or parsed from
         * the rule.
         * @param {boolean=} full - Usually in UI we want to show rule id and name next to
         *     each other.
         * @return {string}
         */
        getName(full) {
            const { name = this._getField('msg') } = this.config;

            if (!full) {
                return name;
            }

            return name ? `${this.getId()} | ${name}` : this.getId();
        }

        /**
         * Returns the ID of this rule. ID is either configured as a field or parsed from the
         * rule. Might have duplicates within a group, not populated by UI for the new rules.
         * @return {string}
         */
        getId() {
            const { rule_id: ruleId } = this.config;

            return ruleId || this._getField('id');
        }

        /**
         * Returns a unique identifier for each rule within a group. This id is randomly
         * generated by constructor mostly for [ng-repeat] use.
         * @return {string}
         * @public
         */
        getUniqueIdentifier() {
            return this.ruleId_;
        }

        /**
         * Adds a WafExcludeListEntryConfigItem MessageItem to config.exclude_list.
         * @param {Object|WafExcludeListEntryConfigItem}
         */
        addExcludeListEntry(exception) {
            this.config.exclude_list.add(exception);
        }

        /**
         * Removes an entry from config.exclude_list.
         * @param {number} index
         */
        removeExcludeListEntry(index) {
            this.config.exclude_list.remove(index);
        }

        /**
         * Returns true if entries exist on config.exclude_list.
         * @return {boolean}
         */
        hasExcludeListEntries() {
            return !this.config.exclude_list.isEmpty();
        }

        /**
         * Returns true if rule defines its own mode, overriding policy mode.
         * Ignores "allow override" setting of the policy.
         * @return {boolean}
         */
        hasCustomMode() {
            return Boolean(this.config.mode);
        }

        /**
         * Returns true if any entry has an invalid configuration.
         * @return {boolean}
         */
        hasInvalidExcludeListEntries() {
            return _.any(this.config.exclude_list.config, entry => !entry.isValid());
        }

        /**
         * Returns true if the rule contains the exception.
         * @param {Object} exception - Exception containing subnet, path, and match element.
         * @return {boolean}
         */
        hasMatchingException(exception) {
            const { exclude_list: excludeList } = this.config;

            return _.any(excludeList.config, entry => entry.hasMatchingException(exception));
        }

        /**
         * Parses the rule string for a particular field.
         * @param {string} field - Name of the field to parse for.
         * @return {string}
         */
        _getField(field) {
            const { rule } = this.getConfig();

            if (angular.isUndefined(rule)) {
                return;
            }

            // The field of the rule is contained within a huge string, ie:
            // "....ection',    id:981261,    rev:'1'....". We have to parse this string to get
            // the specified field.
            const regExp = new RegExp(`${field}:('[^,]+?')`);
            const matches = regExp.exec(rule);

            return Array.isArray(matches) && matches[1].replace(/'/g, '') || '';
        }

        /**
         * Sets the mode.
         * @param {string|undefined} wafMode - Mode enum to set. Undefined or not set means that the
         *     rule follows the policy's mode.
         */
        set mode(wafMode) {
            this.config.mode = wafMode;
        }
    }

    return WafRuleConfigItem;
};

wafRuleConfigItemFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  WafRuleConfigItem
 * @description  WafRuleConfig MessageItem class.
 * @module config-item/WafRuleConfigItem
 * @author alextsg
 */
angular.module('aviApp').factory('WafRuleConfigItem', wafRuleConfigItemFactory);
