/**
 * @module SystemModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Item,
    ObjectTypeItem,
    TWindowElement,
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model';
import {
    compact,
    isString,
    isUndefined,
    pick,
} from 'underscore';

import { Component, Type } from '@angular/core';

import {
    IDNSConfiguration,
    IEmailConfiguration,
    IIpAddrMatch,
    INTPConfiguration,
    ISystemConfiguration,
    ITenantConfiguration,
    LicenseTierType,
    MatchOperation,
    SMTPType,
} from 'generated-types';

import { AviAlertService }
    from 'ajs/modules/core/services/avi-alert.service';

import { IFullModalLayout } from 'ng/modules/core';
import { LicenseTierSelectCardComponent } from 'ng/modules/licensing';

import { IItemParams, withFullModalMixin } from 'ajs/js/utilities/mixins';
import { EmailConfigurationConfigItem } from './email-configuration.config-item.factory';

type TSystemConfigurationPartial = Omit<ISystemConfiguration, 'email_configuration'>;

interface ISystemConfigurationConfig extends TSystemConfigurationPartial {
    email_configuration?: EmailConfigurationConfigItem;
}

interface ISystemConfigData {
    uuid: string;
    config: ISystemConfigurationConfig;
}

/**
 * @description
 *      Item Class for the systemconfiguration object.
 *
 *      Has verbose transformations on_load/before-save and few convenience/legacy save methods.
 *
 *      We have only one systemconfiguration which is provided by systemConfigService,
 *      hence this factory is rarely used directly.
 *
 * @author Alex Malitsky, Aravindh Nagarajan, Zhiqian Liu
 * @alias SystemConfig
 */
export class SystemConfig extends
    withFullModalMixin(withEditChildMessageItemMixin(ObjectTypeItem)) {
    public getConfig: () => ISystemConfigurationConfig;
    public data: ISystemConfigData;
    public backup: ISystemConfigurationConfig;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            id: 'default', // only one in a system
            objectName: 'systemconfiguration',
            objectType: 'SystemConfiguration',
            whitelistedFields: [
                'email_configuration',
            ],
        };

        super(extendedArgs);
    }

    /**
     * Getter for the default license tier.
     */
    public get defaultLicenseTier(): LicenseTierType {
        return this.getConfig().default_license_tier;
    }

    /**
     * Setter for the default license tier.
     */
    public set defaultLicenseTier(tier: LicenseTierType) {
        this.getConfig().default_license_tier = tier;
    }

    /**
     * Returns Item.id from Item's data object.
     */
    // eslint-disable-next-line no-underscore-dangle
    public getIdFromData_(data: ISystemConfigData): string {
        return data.uuid;
    }

    /**
     * Returns true if system config is loaded.
     */
    public isReady(): boolean {
        return !!this.getConfig();
    }

    /**
     * Loads system_configuration and puts the response into Item#data.config.
     * @override
     */
    public async loadConfig(): Promise<ng.IHttpResponse<ISystemConfiguration>> {
        const params = this.getLoadParams();

        this.cancelRequests('config');

        const url = `/api/${this.objectName}/?${params.join('&')}`;

        const response: ng.IHttpResponse<ISystemConfiguration> =
            await this.request('get', url, undefined, null, 'config');

        this.onConfigLoad_(response);

        return response;
    }

    /**
     * Returns an API URI to save systemConfig.
     * @override
     */
    public urlToSave(): string {
        return `/api/${this.objectName}/?include_name`;
    }

    /** Empty systemConfig data */
    public emptyData(): void {
        this.data.config = null;
    }

    /**
     * Convenience method over Item.patch.
     */
    public updateTenantSettings(
        tenantConfig: ITenantConfiguration,
    ): ng.IPromise<ng.IHttpResponse<ISystemConfiguration>> {
        return this.patch({
            replace: {
                global_tenant_config: tenantConfig,
            },
        });
    }

    /**
     * Convenience method over Item.patch.
     */
    public setWelcomeWorkflowComplete(): ng.IPromise<ng.IHttpResponse<ISystemConfiguration>> {
        return this.patch({
            replace: {
                welcome_workflow_complete: true,
            },
        });
    }

    /**
     * Close license tier switch modal.
     */
    public closeLicenseTierModal(): void {
        this.closeModal(LicenseTierSelectCardComponent as Type<Component>);
    }

    /**
     * Save default_license_field by doing a patch.
     */
    public saveDefaultLicenseTierType(): ng.IPromise<ng.IHttpResponse<ISystemConfiguration>> {
        const { default_license_tier: licenseTierToSave } = this.getConfig();

        return this.patch({
            replace: {
                default_license_tier: licenseTierToSave,
            },
        });
    }

    /**
     * Immediately after save checks whether portal port has been changed and throws an
     * alert in this case.
     */
    public checkPortalPortChange(): void {
        if (this.backup && this.isReady()) {
            const { portal_configuration: prevPortalConfig } = this.backup;
            const { portal_configuration: portalConfig } = this.getConfig();

            if (prevPortalConfig && portalConfig) {
                const {
                    https_port: httpsPort,
                    http_port: httpPort,
                } = portalConfig;

                const {
                    https_port: prevHTTPSPort,
                    http_port: prevHTTPPort,
                } = prevPortalConfig;

                // ignores different types (undefined, null, 0) of false values, also '80' == 80
                if (httpsPort !== prevHTTPSPort && (httpsPort || prevHTTPSPort) ||
                    httpPort !== prevHTTPPort && (httpPort || prevHTTPPort)) {
                    const aviAlertService: AviAlertService =
                        this.getAjsDependency_('aviAlertService');

                    aviAlertService.throw(
                        'HTTP or HTTPS port changed. Please re-login at the new port number',
                    );
                }
            }
        }
    }

    /** @override */
    public dataToSave(): ISystemConfiguration {
        /**
         * If IpAddrMatches have been added, set the match_criteria to 'IS_IN'.
         */
        function updateMatchCriteria(ipAddrMatch: IIpAddrMatch): IIpAddrMatch {
            if (ipAddrMatch &&
                (ipAddrMatch.addrs && ipAddrMatch.addrs.length > 0 ||
                ipAddrMatch.ranges && ipAddrMatch.ranges.length > 0 ||
                ipAddrMatch.prefixes && ipAddrMatch.prefixes.length > 0 ||
                ipAddrMatch.group_refs && ipAddrMatch.group_refs.length > 0)) {
                ipAddrMatch.match_criteria = MatchOperation.IS_IN;

                return ipAddrMatch;
            } else {
                return undefined;
            }
        }

        const config = super.dataToSave();

        const {
            portal_configuration: portalConfig,
            email_configuration: emailConfig,
            mgmt_ip_access_control: mgmtIPAccessControl,
        } = config;

        if (portalConfig) {
            // If http_port or https_port had been configured, but user is now trying to remove
            // them to reset back to default, we need to set them to 80 and 443 respectively
            // so that they are not simply undefined. We use _isUndefined for oldHttpPort
            // since it can be undefined, while newHttpPort can be undefined or null (null
            // when user deletes an existing value).
            if (this.backup) {
                const { portal_configuration: prevPortalConfig } = this.backup;

                ['http_port', 'https_port'].forEach((fieldName: string) => {
                    if (!portalConfig[fieldName] && prevPortalConfig[fieldName]) {
                        portalConfig[fieldName] = fieldName === 'https_port' ? 443 : 80;
                    }
                });
            }

            if (!portalConfig.enable_https) {
                portalConfig.redirect_to_https = false;
            }
        }

        if (mgmtIPAccessControl) {
            ['ssh_access', 'api_access', 'snmp_access', 'shell_server_access']
                .forEach(fieldName => {
                    if (fieldName in mgmtIPAccessControl) {
                        mgmtIPAccessControl[fieldName] =
                            updateMatchCriteria(mgmtIPAccessControl[fieldName]);
                    }
                });
        }

        // drop all properties not applicable for selected SMTP type
        if (emailConfig) {
            const mailServerConfigFields = [
                'mail_server_name',
                'mail_server_port',
                'from_email',
                'disable_tls',
            ];

            const fieldsPerType = {
                SMTP_NONE: [] as string[],
                SMTP_LOCAL_HOST: [
                    'from_email',
                ],
                SMTP_SERVER: [
                    'auth_username',
                    'auth_password',
                    ...mailServerConfigFields,
                ],
                SMTP_ANONYMOUS_SERVER: [
                    ...mailServerConfigFields,
                ],
            };

            if (!emailConfig.smtp_type) {
                emailConfig.smtp_type = SMTPType.SMTP_NONE;
            }

            const { smtp_type: smtpType } = emailConfig;

            config.email_configuration = pick(emailConfig, 'smtp_type', ...fieldsPerType[smtpType]);
        }

        if ('dns_virtualservice_refs' in config) {
            config.dns_virtualservice_refs = compact(config.dns_virtualservice_refs);

            if (!config.dns_virtualservice_refs.length) {
                config.dns_virtualservice_refs = undefined;
            }
        }

        const { snmp_configuration: snmp } = config;

        if (snmp && snmp.version) {
            switch (snmp.version) {
                case 'SNMP_VER2':
                    delete snmp.snmp_v3_config;
                    break;

                case 'SNMP_VER3':
                    delete snmp.community;
                    break;
            }
        } else {
            delete config.snmp_configuration;
        }

        return config;
    }

    /**
     * Dedicated method to execute same data transformations on load and on save as well.
     */
    // eslint-disable-next-line no-underscore-dangle
    public transformAfterLoad_(config: ISystemConfigurationConfig): ISystemConfigurationConfig {
        let { ntp_configuration: ntpConfig } = config;

        if (!ntpConfig) {
            config.ntp_configuration = {};
            ntpConfig = config.ntp_configuration;
        }

        [
            'ntp_servers',
            'ntp_server_list',
            'ntp_authentication_keys',
        ].forEach(fieldName => {
            if (!(fieldName in ntpConfig)) {
                ntpConfig[fieldName] = [];
            }
        });

        if (!('dns_virtualservice_refs' in config)) {
            config.dns_virtualservice_refs = [undefined];
        }

        return config;
    }

    /** @override */
    public transformAfterLoad(): void {
        this.transformAfterLoad_(this.getConfig());
    }

    /** @override */
    public transformDataAfterSave(
        { data }: ng.IHttpResponse<ISystemConfigurationConfig>,
    ): ISystemConfigurationConfig {
        this.data.config = this.transformAfterLoad_(data);

        return this.getConfig();
    }

    /**
     * Previously we operated a copy of SystemConfiguration config wo Item and when done
     * saved it directly with the custom API PUT request.
     */
    public legacySave(
        config: ISystemConfigurationConfig,
    ): Promise<ISystemConfiguration> {
        const { config: prevConfig } = this.data;

        this.data.config = config;

        return this.save()
            .catch((rsp: any) => {
                this.data.config = prevConfig;

                return Promise.reject(rsp);
            }) as Promise<ISystemConfiguration>;
    }

    /**
     * Returns NTP configuration.
     */
    public getNTPConfig(): INTPConfiguration | null {
        const config = this.getConfig();

        return config?.ntp_configuration || null;
    }

    /**
     * Returns DNS configuration.
     */
    public getDNSConfig(): IDNSConfiguration | null {
        const config = this.getConfig();

        return config?.dns_configuration || null;
    }

    /**
     * Returns Email/SMTP configuration.
     */
    public getEmailConfig(): IEmailConfiguration | null {
        const config = this.getConfig();

        return config?.email_configuration.config || null;
    }

    /**
     * Returns Tenant configuration.
     */
    public getTenantConfig(): ITenantConfiguration | null {
        const config = this.getConfig();

        return config?.global_tenant_config || null;
    }

    /**
     * @override
     * Overriden because SystemConfig can open both avi-modal and full-modal for partial configs.
     */
    public openModal(
        windowElement: TWindowElement = this.windowElement,
        params: IItemParams,
    ): Promise<void> {
        if (isUndefined(windowElement)) {
            windowElement = this.windowElement as string;
        }

        // open avi-modal
        if (isString(windowElement)) {
            return Item.prototype.openModal.call(this, windowElement, params);
        }

        // open full-modal
        return Promise.resolve(super.openModal(windowElement, params));
    }

    /**
     * @override
     */
    public closeModal(windowElement?: string | Type<Component>): Promise<void> {
        if (isUndefined(windowElement)) {
            windowElement = this.windowElement as string;
        }

        // close avi-modal
        if (isString(windowElement)) {
            return Item.prototype.closeModal.call(this, windowElement);
        }

        // close full-modal
        return Promise.resolve(super.closeModal(windowElement));
    }

    /**
     * Called to edit email configuration settings.
     */
    public editEmailSmtp(): void {
        this.editChildMessageItem({ field: 'email_configuration' }, true);
    }

    /**
     * Returns the email configuration SMTP type.
     */
    public get emailSmtpType(): SMTPType | undefined {
        return this.getEmailConfig().smtp_type;
    }

    /**
     * Override to suppress error alert window.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    protected patchErrorHandler_(errRsp: any): ng.IPromise<void> {
        this.errors = errRsp.data;
        this.emitOnSaveEvents_('save-fail', this.errors);

        return this.$q.reject(errRsp.data);
    }

    /**
     * @override
     * Overriden for editing default_licenese_tier by treating its modal as the only config modal
     * for SystemConfig.
     */
    protected getFullModalProps(
        params: IItemParams,
        windowElement?: Type<Component>,
    ): IFullModalLayout {
        const schemaService = this.getAjsDependency_('schemaService');

        return {
            component: windowElement,
            componentProps: {
                ...params,
            },
            getDescription: () => {
                const { defaultLicenseTier: selectedLicenseTier } = params.editable as SystemConfig;
                const { label: licenseTierLabel } = schemaService.getEnumValue(
                    'LicenseTierType',
                    selectedLicenseTier,
                );

                return licenseTierLabel;
            },
            getName: () => 'Licensing',
            icon: this.getModalBreadcrumbIcon(),
        };
    }
}

SystemConfig.ajsDependencies = [
    'aviAlertService',
    'schemaService',
];
